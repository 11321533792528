import React, { Component } from 'react'
import { Fragment } from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
//import GirinkaChallenges from '../components/GirinkaChallenges';
import GirinkaHowItWorks from '../components/GirinkaHowItWorks';
import GirinkaImpact from '../components/GirinkaImpact';
import GirinkaOverview from '../components/GirinkaOverview';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
import { girinkaSubmenus } from '../utils/menus';

export class Girinka extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Girinka"
							hgsSubTitle="Social welfare"
							hgsIntro={
								<p>
									As part of efforts to reconstruct Rwanda and nurture a shared
									national identity, the Government of Rwanda drew on
									traditional practices in Rwandan culture to adapt its
									development programmes to the country’s needs and context.
								</p>
							}
							submenus={girinkaSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Abunzi &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/abunzi/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={GirinkaOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={GirinkaOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={GirinkaHowItWorks}
							/>
							<Route
								exact
								path={`${match.path}/impact`}
								component={GirinkaImpact}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={GirinkaChallenges}
							/> */}
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Girinka
