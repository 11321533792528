import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics012.png";
import image008 from "../assets/images/cp-content-image008.jpeg";

export class Economy3 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col">
					<p className="font-semibold text-2xl">Traditional sectors</p>
					<p className="my-4">
						In the wake of the adoption of the Vision 2020 development strategy
						in 2000, to quickly revamp economic activity, a three-pronged
						growth-promotion strategy was adopted to improve the business
						climate, develop infrastructure, and reform and invest in
						traditional sectors that showed strong growth potential. In 2001,
						the government established the Rwandan National Innovation and
						Competitiveness programme to promote growth in the coffee, tea, and
						tourism sectors by setting comprehensive growth strategies. An
						additional goal was to increase value addition. The government
						encouraged the production of fully washed high-quality coffee by
						creating incentives for private entrepreneurs to invest and create
						coffee-washing stations.
					</p>
					<p className="my-4">
						Rwanda now sells its coffee to large international buyers such as
						Starbucks, Green Mountain, Sainsbury’s, CostCo, and Walmart. Income
						from coffee exports rose from less than USD 20 million in 2003 to
						around USD 68 million in 2018. The tea sector followed a similar
						path.
					</p>
				</div>
				<div className="flex flex-col">
					<div>
						<img src={image008} alt="" />
					</div>
					<div className="my-4" style={{ maxWidth: "25rem" }}>
						<img src={info1} alt="" className="w-full" />
					</div>
				</div>
			</div>
		);
	}
}

export default Economy3;
