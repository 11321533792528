import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import Abunzi from './Abunzi'
import Gacaca from './Gacaca'
import Girinka from './Girinka'
import HGSHow from './HGSHow'
import HGSWhatAre from './HGSWhatAre'
import HGSWhatMakes from './HGSWhatMakes'
import Imihigo from './Imihigo'
import Ingando from './Ingando'
import Itorero from './Itorero'
import Ubudehe from './Ubudehe'
import Umuganda from './Umuganda'
import Umushyikirano from './Umushyikirano'
import Umwiherero from './Umwiherero'

export class HomeGrownSolutions extends Component {
    render() {
        const {match} = this.props
        return (
            <Fragment>
                    <Switch>
                        <Route exact path={`${match.path}`} component={HGSWhatAre} />
                        <Route path={`${match.path}/what-are-hgs`} component={HGSWhatAre} />
                        <Route path={`${match.path}/how-did-they-emerge`} component={HGSHow} />
                        <Route path={`${match.path}/what-makes-them-work`} component={HGSWhatMakes} />
                        <div className="bg-white">
                            <Route path={`${match.path}/abunzi`} component={Abunzi} />
                            <Route path={`${match.path}/gacaca`} component={Gacaca} />
                            <Route path={`${match.path}/itorero`} component={Itorero} />
                            <Route path={`${match.path}/ingando`} component={Ingando} />
                            <Route path={`${match.path}/umushyikirano`} component={Umushyikirano} />
                            <Route path={`${match.path}/umwiherero`} component={Umwiherero} />
                            <Route path={`${match.path}/umuganda`} component={Umuganda} />
                            <Route path={`${match.path}/ubudehe`} component={Ubudehe} />
                            <Route path={`${match.path}/imihigo`} component={Imihigo} />
                            <Route path={`${match.path}/girinka`} component={Girinka} />
                        </div>
                    </Switch>
            </Fragment>
        )
    }
}

export default HomeGrownSolutions
