import React, { Component } from "react";
import logo from "../assets/images/RWandaPedia Logo_Edit-04.png";
import blackLogo from "../assets/images/rwandapedia black-orange_logo.svg";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";

export class HGSSideNav extends Component {
	render() {
		const { hgsTitle, hgsSubTitle, hgsIntro, submenus, cpTitle } = this.props;
		return (
			<div className={`h-screen flex flex-col relative md:pt-20 xl:pt-32 ${ cpTitle !== undefined ? '' : 'bg-lightOrange'}`}>
				{cpTitle !== undefined ? (
					<div className="lg:px-10 md:px-4 text-fifth">
						<Link
							to="/"
							className="flex flex-row items-center font-semibold text-xs"
						>
							<HiOutlineArrowNarrowLeft /> &nbsp; Home
						</Link>
						<p className="lg:text-4xl md:text-2xl font-semibold md:my-2 ">
							{cpTitle}
						</p>
					</div>
				) : (
					<div className="lg:px-10 md:px-4">
						<Link
							to="/hgs/what-are-hgs"
							className="flex flex-row items-center font-semibold text-xs text-secondary"
						>
							<HiOutlineArrowNarrowLeft /> &nbsp; See all Homegrown
							Initiatives
						</Link>
						<p className="lg:text-4xl md:text-2xl font-semibold text-secondary">
							{hgsTitle}
						</p>
						<p className="uppercase font-semibold">{hgsSubTitle}</p>
						<div className="my-4 xl:text-base lg:text-sm md:text-xs">
							{hgsIntro}
						</div>
					</div>
				)}
				<div className="flex flex-col hgs-submenus md:text-sm">
					{submenus.map(({ id, name, route }) => (
						<NavLink
							key={id}
							to={route}
							className={`p-4 px-10 md:pr-6 font-semibold ${
								cpTitle !== undefined
									? "hover:bg-cpGray"
									: "hover:text-secondary hover:bg-lighterOrange"
							} text-black`}
							activeClassName={`text-black border-l-8 ${
								cpTitle !== undefined ? "border-fifth text-fifth bg-cpGray" : "bg-lighterOrange border-secondary text-secondary" 
							}`}
						>
							{name}
						</NavLink>
					))}
				</div>
				<div className="absolute top-0 right-0 mt-4	mr-8">
					<img
						src={cpTitle !== undefined ? blackLogo : logo}
						alt=""
						className=""
						style={{ width: "10rem" }}
					/>
				</div>
			</div>
		);
	}
}

export default HGSSideNav;
