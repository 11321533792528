import React, { Component } from "react";
import Post1 from "../assets/images/Post-Genocid2.jpg"

export class History4 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
					<div className="">
						<p>
							In July 1994, the RPF established a Government of National Unity 
							based on the Arusha Peace Agreement, and excluding parties that 
							were implicated in the genocide. After four years of post-conflict 
							rehabilitation, in 1998 the government organized the Urugwiro Village 
							Discussions to prepare the long-lasting transformation of the country. 
							The discussions took place every Saturday from May 1998 to March 1999. 
							They brought together 164 participants from all political parties and 
							sections of society including notables from the monarchy era and from 
							the first and second Republics.
						</p>
						<p className="my-4">
							Two main political directions emerged from the Urugwiro Village Discussions: 
							strengthening national unity as the ultimate goal towards which all policies 
							must converge and putting citizens at the centre of development strategies. 
							The major institutions and programmes of the new Rwanda were conceived at 
							that time: the 2003 Constitution enshrining principles of consensual democracy, 
							decentralisation, the National Unity and Reconciliation Commission, the Gacaca 
							Courts, and the Vision 2020 strategy for economic transformation.
						</p>
						<div className="">
							<img src={Post1} className="w-full" alt="" />
						</div>
						<p className="my-4 font-semibold">
							Rwanda’s political and administrative structure
						</p>
						<p className="my-4">
							After nine years of post-genocide transition, in 2003, the country adopted a 
							new constitution and other institutions and programmes inspired by the Urugwiro 
							Village Discussions geared toward strengthening national unity, establishing an 
							inclusive and fair state and transforming the economy. 
						</p>
					</div>
			</div>
		);
	}
}

export default History4;
