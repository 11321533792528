import React, { Component } from "react";

export class Today extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="">
					<div className="">
						<div className="">
							<p>
								Today, Rwanda’s constitutional order enshrines the principles of consensual 
								democracy based on a system of power sharing and a quest for consensus, 
								with the following features:
							</p>
							<div className="border my-4 p-8 bg-yellow-100">
								<ul className="overview-list mx-8">
									<li>
										A political organisation holding the majority in the Chamber 
										of Deputies should not occupy more than 50% of positions in 
										the Cabinet
									</li>
									<li>
										The President of the Republic and the President of the Chamber 
										of Deputies should come from different political organisations
									</li>
									<li>
										At least 30% of seats in the Chamber and the Senate are to be 
										reserved for women and the Senate is to reflect the national 
										diversity
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Today;
