import React, { Component } from "react";

export class NavigationDots extends Component {
	render() {
		let { active } = this.props;
		return (
			<div className="flex flex-col items-center absolute right-0 md:mx-10 lg:mx-20 z-20">
				<a href="#home-1"><div className={`navigation-dot h-3 w-3 my-1 hover:bg-secondary ${active === 1 ? 'active' : ''}`}></div></a>
				<a href="#home-2"><div className={`navigation-dot h-3 w-3 my-1 hover:bg-secondary ${active === 2 ? 'active' : ''}`}></div></a>
				<a href="#home-3"><div className={`navigation-dot h-3 w-3 my-1 hover:bg-secondary ${active === 3 ? 'active' : ''}`}></div></a>
				<a href="#home-4"><div className={`navigation-dot h-3 w-3 my-1 hover:bg-secondary ${active === 4 ? 'active' : ''}`}></div></a>
				<a href="#home-5"><div className={`navigation-dot h-3 w-3 my-1 hover:bg-secondary ${active === 5 ? 'active' : ''}`}></div></a>
			</div>
		);
	}
}

export default NavigationDots;
