import React, { Component } from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { AiFillCloseSquare } from "react-icons/ai";

export class Subscribe extends Component {
	state = { open: false };
	handleOpen = (e) => {
		e.preventDefault();
		const { open } = this.state;
		if (!open) {
			document.querySelector(".subscribe-container").style.marginLeft = "24rem";
			this.setState({ open: true });
		}
		if (open) {
			document.querySelector(".subscribe-container").style.marginLeft = "2rem";
			this.setState({ open: false });
		}
	};
	handleClose = (e) => {
		e.preventDefault();
		const { open } = this.state;
		if (open) {
			document.querySelector(".subscribe-container").style.marginLeft = "2rem";
			this.setState({ open: false });
		}
	};
	render() {
		return (
			<div className="sm:none subscribe-container fixed z-20">
				<div id="mySidenav" className="sidenav relative overflow-hidden">
					<div className="absolute top-0 right-0 mr-12 -mt-1 text-3xl text-rciGreen hover:text-secondary cursor-pointer">
						<AiFillCloseSquare onClick={this.handleClose} />
					</div>
					<div
						className="sm:none clickOpen text-white bg-rciGreen font-semibold hover:bg-secondary cursor-pointer"
						id="openSub"
						onClick={this.handleOpen}
					>
						<p className="uppercase">Join our newsletter</p>
					</div>
					<div className="flex flex-row mr-4">
						<div className="p-8 pr-12 bg-primaryLight w-full flex flex-col">
							<div>
								<p className="">
									Be the first to receive new updates on Rwandapedia.
									Subscribe to our newsletter!
								</p>
							</div>
							<div>
								<form action="" className="subscribe-form my-4">
									<div className="flex flex-row items-center border border-black px-2 py-1 w-full">
										<input
											type="email"
											placeholder="Email"
											className="bg-transparent w-full"
										/>
										<HiOutlineArrowRight />
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Subscribe;
