import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import PageName from "./PageName";
import image016 from "../assets/images/hgs-content-image016.jpeg";
import { Link } from "react-router-dom";

export class WhatMakes4 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
						subTitle={
							<>
								Back <HiOutlineArrowNarrowLeft />
							</>
						}
						to="/"
					/>
				</div>
				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full md:text-sm">
						<p className="">
							Presidential visits to the districts, during which the population
							can speak directly to the head of state and express their concerns,
							have similar effects to Umushyikirano. These assemblies are
							broadcast live on national radio and television.
						</p>
						<p className="my-4">
							<span className="italic">Imihigo</span>, which has become known in
							English as “performance contracts”, trace their origins to an ancient
							tradition of oathing associated with the military. Such oathing took
							place prior to going to war, and entailed senior commanders pledging
							to the king what they would do on the battlefield. When war ended,
							during a debriefing session, pledges made were meticulously assessed.
							Modern Imihigo were adopted in 2006 as a mechanism for monitoring and
							evaluating the work of public institutions and that of its public
							servants. Imihigo detail specific targets government officials must
							meet in the coming year, and are intended to combine national priorities
							and community objectives. Imihigo have increasingly bureaucratised into a
							performance management and Public Financial Management mechanism to
							systematically avoid false claims of achievements by local leaders.
						</p>
					</div>
					<div className="flex items-center h-full xl:px-20 md:pl-4">
						<div className="">
							<img src={image016} alt="" />
						</div>
					</div>
				</div>
				<div className="flex flex-col md:hidden">
					<Link
						to="/hgs/how-did-they-emerge"
						className="flex flex-row items-center justify-end w-full py-2 pt-8 font-semibold hover:text-secondary text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; How did they emerge?
					</Link>
				</div>
			</div>
		);
	}
}

export default WhatMakes4;
