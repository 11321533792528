import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import History4 from "../components/History4";
import PageName from "../components/PageName";
import Post1 from "../assets/images/Post-Genocide1.jpg"
import Today from "../components/Today";
import Today2 from "../components/Today2";
// import image005 from "../assets/images/cp-content-image005.jpeg";

export class CPRwToday extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Country Profile"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Institutionalisation of good governance &nbsp;{" "}
							<HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/institutionalisation"
				/>
				<p className="text-3xl font-semibold">Post-Genocide Rwanda</p>
				<div
					className="w-full my-8 bg-top bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${Post1})`,
						height: "30rem",
					}}
				></div>
				<div>
					<History4 />
				</div>
				<div>
					<Today />
				</div>
				<div>
					<Today2 />
				</div>
				<div>
					<Link
						to="/country/institutionalisation"
						className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-fifth text-primary"
					>
						Institutionalisation of good governance &nbsp;{" "}
						<HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/country/history"
						className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; History
					</Link>
				</div>
			</div>
		);
	}
}

export default CPRwToday;
