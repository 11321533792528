import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics005.png";
import info2 from "../assets/images/cp-infographics/cp-infographics006.png";
import image004 from "../assets/images/cp-content-image004.jpeg";

export class Economy5 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-row flex-wrap">
					<div className="m-1">
						<img
							src={info2}
							alt=""
							className=""
							style={{
								maxHeight: "25rem",
							}}
						/>
					</div>
					<div className="m-1">
						<img
							src={info1}
							alt=""
							className=""
							style={{
								maxHeight: "25rem",
							}}
						/>
					</div>
				</div>
				<div className="my-4">
					<img src={image004} alt="" />
				</div>
			</div>
		);
	}
}

export default Economy5;
