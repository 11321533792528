import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import GacacaAchievements from '../components/GacacaAchievements';
//import GacacaChallenges from '../components/GacacaChallenges';
import GacacaHowItWorked from '../components/GacacaHowItWorked';
import GacacaOverview from '../components/GacacaOverview';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
import { gacacaSubmenus } from '../utils/menus';

export class Gacaca extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Gacaca"
							hgsSubTitle="governance and justice"
							hgsIntro={
								<p>
									Contemporary Gacaca draws inspiration from the traditional
									model, by replicating a local community-based justice system
									with the aim of restoring the social fabric of society. The
									courts are credited with laying the foundation for peace,
									reconciliation and unity in Rwanda. The Gacaca courts
									officially finished their work in June 2012.
								</p>
							}
							submenus={gacacaSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative justify-center">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Itorero &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/itorero/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={GacacaOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={GacacaOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-did-it-work`}
								component={GacacaHowItWorked}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={GacacaChallenges}
							/> */}
							<Route
								exact
								path={`${match.path}/achievements`}
								component={GacacaAchievements}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Gacaca
