import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics002.png";
import info2 from "../assets/images/cp-infographics/cp-infographics003.png";
import info3 from "../assets/images/cp-infographics/cp-infographics004.png";
import info4 from "../assets/images/cp-infographics/cp-infographics026.png";

export class Today2 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col">
					<div className="my-4">
						<p>
							A Forum of Political Organisations was also established for 
							permanent consultation between political parties.
						</p>
					</div>
					<div>
						<p className="mb-4">As of 2020, women form:</p>
					</div>
					<div className="flex flex-row flex-wrap gap-4 mb-4">
						<div
							className="info-border pr-4"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info1} alt="" className="h-full" />
						</div>
						<div
							className="info-border pr-4"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info2} alt="" className="h-full" />
						</div>
						<div
							className="info-border pr-4"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info3} alt="" className="h-full" />
						</div>
					</div>
				</div>
				<div className="flex flex-col">
					<p className="font-semibold text-xl">Administrative structure</p>
					<p className="my-4">
						Rwanda is composed of two main layers of government (central and
						local) and of six administrative sub-entities, including Kigali.
					</p>
					<p>
						The local government contains the following levels, from the highest
						to the lowest:
					</p>
					<div className="my-4">
						<img src={info4} alt="" className="" />
					</div>
					<p>
						The local government plays a key role in the country’s social
						development efforts. Social and administrative services are
						delivered at the sector level. These entities supervise the health,
						education, and water distribution infrastructure and can receive
						investment funds. They manage around 25% of the national budget.
					</p>
				</div>
			</div>
		);
	}
}

export default Today2;
