import React, { Component } from "react";
import History02 from "../assets/images/History2.png";

export class History2 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<div className="flex flex-col my-4">
					<div className="font-bold">
						<p>
							<span className="text-4xl text-fifth">2. </span>Colonial Period
						</p>
					</div>
					<div className="">
						<p>
							Imbued with the ideology of race, Belgian colonisers reorganised 
							the Rwandan colonial state and society along racialist lines, 
							inculcating that ideology into the new educated elites of Rwanda, 
							who started to see themselves as divided into racialised ethnic groups. 
						</p>
						<p className="my-4">
							In the 1950s, when the Tutsi elite broke the pact that linked it 
							to the Belgian colonial administration by advocating for independence, 
							the colonial administration allied itself with a Hutu counter-elite 
							that it had previously been discriminating against. On the eve of 
							independence, the departing colonial administration transferred 
							power to its new allies through an engineered bloody uprising, the 
							so-called 1959 Hutu revolution, that saw thousands of Tutsis killed 
							and uprooted, hundreds of thousands regrouped into internal 
							settlements and others becoming refugees in neighboring countries.
						</p>
					</div>
				</div>
				<div className="">
					<img src={History02} className="w-full" alt="" />
				</div>
			</div>
		);
	}
}

export default History2;
