import React, { Component } from "react";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image006 from "../assets/images/hgs-content-image026.jpeg";
import image038 from "../assets/images/hgs-content-image038.jpeg";

export class WhatAre3 extends Component {
	render() {
		return (
			<div className="px-4 md:p-40 relative">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>
				<div className="flex flex-col md:flex-row justify-center lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/itorero/overview"
							image={image038}
							title="Itorero/Ingando"
							subtitle="Governance and Justice"
							text={
								<p>
									The Civic Education Program, also known as Itorero is one of
									Rwanda’s Home Grown Initiatives. Itorero was reintroduced in
									2009 as a way to rebuild the nation’s social fabric and
									mobilize Rwandans to uphold important cultural values.
								</p>
							}
						/>
					</div>
					<div>
						<HGSAnimatedContainer
							to="/hgs/girinka/overview"
							image={image006}
							title="Girinka"
							subtitle="Social Welfare"
							text={
								<p>
									Girinka can be translated as ‘may you have a cow’ and describes 
									a centuries-old cultural practice in Rwanda where a cow was given 
									by one person to another, either as a sign of respect and gratitude 
									or as a marriage dowry. Girinka has contributed to an increase in 
									agricultural production in Rwanda - especially dairy products 
									which have helped reduce malnutrition and increase incomes.
								</p>
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre3;
