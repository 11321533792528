import React, { Component } from "react";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
// import Ubudehe1 from "../assets/images/Ubudehe1.jpg";
import Ubudehe from "../assets/images/Image-Ubudehe1.JPG"
import image037 from "../assets/images/hgs-content-image037.jpeg";

export class WhatAre4 extends Component {
	render() {
		return (
			<div className="relative px-4 md:p-40">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>
				<div className="flex flex-col justify-center md:flex-row lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/ubudehe/overview"
							image={Ubudehe}
							title="Ubudehe"
							subtitle="Social Welfare"
							text={
								<p>
									Ubudehe refers to the long-standing Rwandan practice and
									culture of collective action and mutual support to solve
									problems within a community. Today, Ubudehe is Rwanda's 
									flagship Social Protection initiavive .
								</p>
							}
						/>
					</div>
					<div>
						<HGSAnimatedContainer
							to="/hgs/umuganda/overview"
							image={image037}
							title="Umuganda"
							subtitle="Economy"
							text={
								<p>
									The word Umuganda can be translated as ‘coming together in
									common purpose to achieve an outcome’. In traditional Rwandan
									culture, members of the community would call upon their
									family, friends and neighbors to help them complete a
									difficult task.
								</p>
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre4;
