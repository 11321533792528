import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";

export class UbudehePovertyLevels extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Poverty level categories</p>
				<div className="">
					<p className="my-4">
						The Ubudehe process involves categorising all households into one
						of a range of appropriate categories of poverty level; however,
						these categories changed over time as described in this section.
					</p>
					<p className="my-4 text-xl font-semibold">
						Ubudehe categories up to 2015
					</p>
					<p className="my-4">
						There were six Ubudehe poverty categories from inception until
						February 2015 which were as follows:
					</p>
					<div className="p-4 bg-hgsBgLight">
						<ul className="flex flex-col gap-4 ml-8 hgs-list">
							<li>
								<span className="italic font-semibold">Abatindi nyakujya </span>
								(Those living in abject poverty): This category of the population
								owned no property, lived by begging and was wholly dependent on others.
							</li>
							<li>
								<span className="italic font-semibold">Abatindi </span>
								(Very poor): This category of the population had poor housing, lived on
								a poor diet, depended on others and did not own land or livestock.
							</li>
							<li>
								<span className="italic font-semibold">Abakene </span>
								(Poor): This category of the population was malnourished, owned a small
								portion of land, had low production capacity and could not afford secondary
								school education for their children.
							</li>
							<li>
								<span className="italic font-semibold">Abakene bifashije </span>
								(Resourceful poor): This category of the population owned some land, cattle
								and a bicycle, and had average production capacity. They could afford
								secondary school education for their children and had fewer difficulties accessing
								health care.
							</li>
							<li>
								<span className="italic font-semibold">Abakungu - jumba </span>
								(Food rich): This category of the population owned a large portion of land,
								could afford a balanced diet and lived in decent houses. They employed others,
								owned cattle, and could afford university education for their children.
							</li>
							<li>
								<span className="italic font-semibold">Abakire </span>
								(Money rich): This category of the population had money in bank account(s),
								could receive bank loans, owned an above-average house, a car, cattle and fertile
								land, and had access to sufficient food and permanent employment.
							</li>
						</ul>
					</div>
					<p className="my-4 text-xl font-semibold">
						2015 Ubudehe categorization
					</p>
					<p className="my-4">
						In February 2015, the Minister for Local Government and Social Affairs launched
						a new categorisation for Ubudehe. One reason was that since the previous categorisation,
						many Rwandan households’ economic situations had risen dramatically, requiring a
						recategorisation. Another reason was that under the previous categorisation system,
						many households resisted being properly classified due to the derogatory names
						attached to the different levels, distorting the categorisation process.
					</p>
					<p className="my-4">
						There were now four Ubudehe poverty categories which started in February 2015;
						these were as follows:
					</p>
					<div className="p-4 bg-hgsBgLight">
						<ul className="flex flex-col gap-4 ml-8 hgs-list">
							<li>
								<span className="font-semibold">Category 1: </span>
								Very poor and vulnerable citizens who were homeless and unable to
								feed themselves without assistance.
							</li>
							<li>
								<span className="font-semibold">Category 2: </span>
								Citizens who were able to afford some form of rented or low-class
								owned accommodation, but who were not gainfully employed and could
								only afford to eat once or twice a day.
							</li>
							<li>
								<span className="font-semibold">Category 3: </span>
								Citizens who were gainfully employed or were even employers of labour.
								This category included small farmers who had moved beyond subsistence
								farming, or owners of small and medium-scale enterprises.
							</li>
							<li>
								<span className="font-semibold">Category 4: </span>
								Citizens classified under this category were Chief Executive Officers
								of big businesses, employees who had full-time employment with organisations,
								industries or companies, government employees, owners of shops or markets
								and owners of commercial transport vehicles or trucks.
							</li>
						</ul>
					</div>
					<p className="my-4">
						These categories were subject to constant appeals and complaints from people who
						wanted to be placed in lower categories, some using bribery and nepotism to attain
						their goal.
					</p>
					<p className="my-4">
						The 2015 categorisation provided weak implementation frameworks for users of
						social programmes. It was marred by over-dependency on the government, and slow
						graduation of communities from poverty.
					</p>
					<p className="my-4 text-xl font-semibold">
						Ubudehe categories from 2020 onwards
					</p>
					<p className="my-4">
						The new 2020 categorisation attempts to fairly re-classify households according to
						their socioeconomic status. A common complaint was that households with different
						welfare levels were classified in the same category; another was that households
						in category 1 and 2 had almost the same standards of living.
					</p>
					<p className="my-4">
						The new Ubudehe approach puts emphasis on the graduation principle, fostering upward
						social mobility. The new model seeks to be cost-effective, evenly spreads resources,
						and assigns roles and tasks across all categories. Category names have also been
						changed from numerical numbers to letters.
					</p>
					<p className="my-4">The 2020 Ubudehe Categories</p>
					<div className="p-4 bg-hgsBgLight">
						<ul className="flex flex-col gap-4 ml-8 hgs-list">
							<li>
								<span className="font-semibold">A & B: </span>
								These categories of households have diverse life choices and are self-reliant
								in ways that can also spur community empowerment and help other households
								graduate from poverty. Households in categories A and B do not benefit from
								social protection schemes but are expected to be partners in community
								development through their own investments and skills, which can create jobs
								through application of corporate social responsibility principles.
							</li>
							<li>
								<span className="font-semibold">C & D: </span>
								these two categories are self-reliant households but that also benefit from
								social protection interventions and multi-sectoral interventions; but to
								do so they must also sign performance contracts (Imihigo) containing a
								plan for graduation from this assistance within a period of 2 years.
								These categories will have graduation as the main focus.
							</li>
							<li>
								<span className="font-semibold">Special category (E): </span>
								This category of households is expected to benefit from full state
								social protection; individuals in this category are not expected
								to graduate and will thus not be obliged to sign performance contracts.
								This is a new category; those in it may be aged, vulnerable or may
								have no skills to pursue any job. These households will benefit from
								full state social protection, accessing everything from the Vision
								2020 Umurenge programme, subsidies for solar-based domestic electrical
								systems, Community-Based Health Insurance (CBHI), fortified blended foods,
								Girinka, and other schemes.
							</li>
						</ul>
					</div>
				</div>
				<Link
					to="/hgs/ubudehe/organization"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Organization and coordination &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/ubudehe/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; How does Ubudehe work?
				</Link>
			</div>
		);
	}
}

export default UbudehePovertyLevels;
