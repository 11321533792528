import React, { Component } from "react";
import History6 from "../assets/images/History6.jpg";
import History5 from "../assets/images/History5.jpg";
import History03 from "../assets/images/History3.jpeg";
import History04 from "../assets/images/History4.jpg";

export class History3 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<div className="flex flex-col my-4">
					<div className="font-bold">
						<p>
							<span className="text-4xl text-fifth">3. </span>Independent Rwanda
						</p>
					</div>
					<div className="">
						<p>
							Independent Rwanda became a sectarian state divided along ethnic
							and regional lines. The first Republic (1962-1973) totally neglected
							the development of the country, focusing almost exclusively on
							erecting a violently anti-Tutsi state while marginalising people from
							outside the central region of the country. The second republic
							(1973-1994) adopted a development strategy geared toward creating a
							nation of self-sufficient peasants, meeting their needs for food and
							shelter through their labour alone, and centered around the local community,
							thus not beholden to the world. This strategy was underpinned by an official
							system of ethnic and regional exclusion. Structural socioeconomic issues
							exacerbated by the exclusionary politics of then President Habyarimana’s
							regime led to the social and economic collapse of the country during the
							second half of the 1980s, a time of widespread hunger and mounting non-ethnic
							community violence.
						</p>						
						<div className="">
							<img src={History03} className="w-full" alt="" />
						</div>
						<p className="my-4 font-semibold">Crisis in the early 1990s</p>
						<p className="my-4">
							In 1990, Rwandan refugees in neighboring countries organised under the
							banner of the Rwanda Patriotic Front (RPF) and launched an armed struggle
							to profoundly transform the discriminatory and economically failing Rwandan
							state. At the same time an internal opposition led by marginalised elites
							from the centre and the south, also challenged the government and advocated
							for the democratisation of the country. To fend off the opposition, the
							Habyarimana regime tried to redefine the terms of the political conflict
							against the RPF, turning it into an ethnic confrontation. To do so, it
							created a grand Hutu-power coalition, bringing in a large part of the former
							internal opposition.
						</p>
						<div className="">
							<img src={History04} className="w-full" alt="" />
						</div>
						<p className="my-4 font-semibold">
							The 1994 Genocide against the Tutsi
						</p>
						<p>
							After failing to dislodge the RPF’s army from the north, the
							Government of Rwanda agreed to peace talks with the RPF and signed
							the Arusha Peace Agreement on 4 August 1993. This stipulated an end
							to the war and the establishment a broad-based Government of National
							Unity that would include the RPF and other opposition parties. The
							contending armies were also to be integrated.
						</p>
						<p className="my-4">
							The Hutu Power coalition virulently opposed the peace process. To
							abort the peace, retain power and preserve the sectarian identity of
							the state, hardliners of the regime shot down President Habyarimana’s
							plane, and with the support of the Hutu Power coalition, used the death
							of President Habyarimana as a pretext to unleash the Genocide against
							the Tutsi. In one hundred days, more than a million Tutsi were killed
							indiscriminately all over the country while the international community
							looked on. After three months of combat, the RPF put an end to the genocide
							by defeating the genocidal forces and liberated the country.
						</p>
					</div>
				</div>
				<div className="grid gap-8 md:grid-cols-2">
					<div className="">
						<img src={History5} alt="" />
					</div>
					<div className="h-full ">
						<img src={History6} alt="" className="" />
					</div>
				</div>
			</div>
		);
	}
}

export default History3;
