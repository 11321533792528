import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import deisgn from "../assets/images/hgs-infographics/Designs_Eng-49.png";

export class UbudeheImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Impact</p>
				<div className="">
					<p className="my-4">
						Ubudehe has resulted in significant achievements for poor households.
						These include the following:
					</p>
					<div className="my-4">
						<ul className="flex flex-col gap-4 ml-8 hgs-list">
							<li>Improved incomes</li>
							<li>Greater capacity to pay for food expenses </li>
							<li>Greater capacity to pay for school expenses</li>
							<li>Greater capacity to pay for health expenses</li>
							<li>Increased capacity to renovate houses</li>
							<li>
								Strengthened intra-community bonds of friendship and solidarity
							</li>
						</ul>
					</div>
					<p className="my-4">Community-level benefits of Ubudehe include the following:</p>
					<div className="my-4">
						<ul className="flex flex-col gap-4 ml-8 hgs-list">
							<li>
								The promotion of livestock rearing (and associated dietary benefits
								and income growth)
							</li>
							<li>
								The construction and repair of rural roads and other infrastructure
							</li>
							<li>
								Together, households have increased economic demand for local businesses
							</li>
							<li>
								Improved agricultural development due to increased investment by poor
								households in agricultural inputs such as fertilisers and improved seeds
							</li>
							<li>
								The construction of administrative infrastructure such as office buildings
								to alleviate shortages of such spaces
							</li>
						</ul>
					</div>
					<p className="my-4">
						Overall, Ubudehe has caused a sharp increase in participation in economic activities
						by the poor, as shown in the figure below, leading to substantial and sustained improvements
						in welfare.
					</p>
					{/* <div className="my-6">
						<div className="border-b-4 border-secondary">
							<img src={Infographic_17} alt="" className="w-full" />
						</div>
						<p className="font-semibold text-right text-secondary">
							Source: Impact of Ubudehe Credit Scheme Projects (Nkurayija et al, 2017)
						</p>
					</div> */}
					<p className="my-4">
						One of the most significant impacts of Ubudehe is the way in which
						it has transformed citizens' engagement with their own development.
						Ubudehe has achieved almost nationwide coverage and communities
						across Rwanda are now actively involved in developing their own
						social maps, visual representations, and collection of data on the
						extent of poverty in their village. This information is used to
						determine national development objectives against which the national
						government and its ministries are held accountable.
					</p>
					<p className="my-4">
						The way in which Ubudehe has brought communities together for
						collective action based on their own priorities is also considered a
						major achievement of the programme.
					</p>
					<p className="my-4">
						The provision of a bank account to each community has facilitated
						thousands of community-led actions such as purchasing livestock,
						undertaking agricultural activities, building clean water facilities,
						classrooms, terraces, health centers as well as silos for storing
						produce.
					</p>
					<div>
						<div className="bg-hgsBgLight">
							<p className="p-2 font-semibold text-center uppercase">
								Projects financed by the Ubudehe programme from 2001 to date
							</p>
							<hr />
							<p className="p-4 font-semibold">
								The projects are divided into two categories: Community Projects
								and household Projects;
							</p>
							<p className="p-4">
								Each village is required to implement one Household Project and one
								Community Project
							</p>
							<p className="p-4">
								Merging the projects is allowed and villages can merge together
								and implement one project at cell or sector level
							</p>
						</div>
						<div className="overflow-x-auto">
							<img src={deisgn} alt="" className="w-full" />
						</div>
						<div className="p-4 bg-hgsBgLight">
							Though the Ubudehe Programme, 89,937 Community projects were
							financed and 94,322 Household projects were financed with funds totaling 
							Rwf 44,408,541,122.
						</div>
					</div>
					<p className="my-4">
						The Ubudehe programme has been, and continues to be, a major
						contributor to overall poverty reduction in Rwanda, in which
						extreme poverty reduced from 24% to 16% between 2010/2011 and
						2016/2017, and the general poverty rate reduced from 45% in 2010/2011
						to 38% in 2016/2017.
					</p>
					<p className="my-4">
						However, Ubudehe has had more than just an economic impact, it has
						also had an impact on access to health, education and shelter, as well
						as improved nutrition, social cohesion and self-governance.
					</p>
				</div>
				<Link
					to="/hgs/ubudehe/organization"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Organization and coordination
				</Link>
			</div>
		);
	}
}

export default UbudeheImpact;
