import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import vision from "../assets/images/hgs-infographics/imihigo_vision.png";
import district_scoring from "../assets/images/hgs-infographics/Imihigo_district_scoring.png";
import ministry_scoring from "../assets/images/hgs-infographics/Designs_Eng-09.png";
import Imihigo1 from "../assets/images/Imihigo1.jpg"

export class ImihigoHowItWorks extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">
					How the Imihigo performance contracts work
				</p>
				<div className="my-4 ">
					<img src={Imihigo1} className="w-full" alt="" />
				</div>
				<div className="">
					<p className="my-4 font-semibold">
						Principles and objectives of Imihigo
					</p>
					<p className="my-4 font-semibold">Principles</p>
					<p className="my-4">
						Imihigo is governed by the following principles:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li>
							Local: Each district decides what goes into its Imihigo. However,
							alignment with national priorities is required.
						</li>
						<li>
							Ambition: Pledges are made to achieve only what has not already
							been achieved or gained.
						</li>
						<li>
							Excellence: Imihigo is about driving outstanding performance that
							is worthy of praise.
						</li>
					</ul>
					<p className="my-4 font-semibold">Objectives of Imihigo</p>
					<p className="my-4">Imihigo has the following objectives:</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li>
							To speed up implementation of the local and national development
							agenda
						</li>
						<li>To ensure stakeholder ownership of the development agenda</li>
						<li>To promote accountability and transparency</li>
						<li>To promote results-oriented performance</li>
						<li>To instill innovation and encourage competitiveness</li>
						<li>
							To engage stakeholders (citizens, civil society, donors, private
							sector, etc.) in policy formulation and evaluation
						</li>
						<li> To promote zeal and determination to achieve set goals</li>
						<li> To instill a culture of regular performance evaluation</li>
					</ul>
					<p className="my-4 font-semibold">The Imihigo preparation process</p>
					<p className="my-4">
						Both Imihigo and action plans are used by the Government of Rwanda
						to define goals, targets and objectives. While different in their
						purpose, the two tools are interlinked. Action plans are sets of
						activities to be achieved within a set time period, usually a year.
						Imihigo are subsets of action plans showing priority activities to
						be used as a performance measure.
					</p>
					<p className="my-4">
						An action plan may contain any number of activities of a routine
						nature such as payment of salaries, whereas Imihigo define targets
						that have a significant impact on economic development, poverty
						reduction, good governance and social welfare.
					</p>
					<p className="my-4">
						When Imihigo are developed, government leaders are advised to ask
						some key questions before including activities in them. An activity
						is prioritised according to the following criteria:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li> Will the activity have a positive effect on the welfare of the
							local population (for example by improving water access,
							transport, energy access, schools, and so on)?
						</li>
						<li> Will the activity create jobs for the local population?</li>
						<li> Will the activity create opportunities for income generation for
							the population/local government?
						</li>
						<li> Will the activity have an impact on poverty reduction?</li>
						<li> Will the activity be a priority for the residents in the area?
						</li>
						<li> Will the activity have synergy with development of other areas (an
							activity may have potential to impact development in neighbouring
							areas)?
						</li>
						<li> Will the activity be sustainable or are the results sustainable?
						</li>
						<li> Will there be ownership from the local population for the
							activity?
						</li>
						<li> Will it help to achieve the national targets and is it linked to
							national and international priorities, programmes or policies
							(SDGs, NST1, Vision 2050)?
						</li>
						<li> Can the activity produce quality results or outputs with minimum
							resources?
						</li>
						<li> Can the activity improve the way services are delivered or reduce
							costs?
						</li>
						<li> Will the activity promote social cohesion (unity and
							reconciliation)?
						</li>
						<li> Will the activity reduce social disorder or unrest (for example,
							insecurity, drug abuse, prostitution, environmental degradation,
							conflicts, corruption, and so on)?
						</li>
						<li> Will the activity address key cross-cutting issues (gender,
							HIV/AIDS, environment, social inclusion and youth)?
						</li>
						<li> Has the source of funds for implementation been determined?</li>
						<li> Is the activity realistic and can it be achieved?</li>
					</ul>
					<p className="my-4 font-semibold">
						Step 1: Identification of national priorities by the central
						government
					</p>
					<p className="my-4">
						In the first step of the Imihigo preparation process, each ministry
						identifies national priorities to be implemented at the local level,
						for which they have earmarked resources to be transferred to local
						governments. Consultation takes place on the following policies and
						programs:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>Vision 2020/2050</li>
						<li>NST1</li>
						<li>Government of Rwanda policies and programmes</li>
						<li>
							National Leadership Retreat and National Dialogue resolutions
						</li>
						<li>Cabinet resolutions</li>
						<li>Three Year Medium Term Expenditure Framework (MTEF)</li>
						<li>DDSs</li>
						<li>SDGs</li>
					</ul>
					<p className="my-4">
						Where they do not have earmarked resources, line ministries identify
						how the resources, whether financial or non-financial, can be
						mobilised (at both national and local levels).
					</p>
					<p className="my-4">
						The central government consolidates the priorities, paying special
						attention to areas with potential for quick wins and for synergy,
						whilst avoiding duplication.
					</p>
					<p className="my-4 font-semibold">
						Step 2: Communication of national priorities to local government
					</p>
					<p className="my-4">
						In the second step, the list of central government priorities is
						communicated and discussed with local government leaders at a forum
						of central and local government leaders.
					</p>
					<p className="my-4 font-semibold">
						Step 3: Identification of local priorities
					</p>
					<p className="my-4">
						In the third step, district leaders consult their DDS, and
						consultative meetings are held with different stakeholders at
						province level, as well as district, sector, cell and village
						levels, to discuss and consolidate the emerging priorities.
					</p>
					<p className="my-4 font-semibold">Step 4: Preparation and approval</p>
					<p className="my-4">
						The fourth step involves the preparation and approval of Imihigo
						contracts and proceeds as follows:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>
							Districts consult their respective DDS and national priorities as
							communicated in the forum or meeting between central and local
							government
						</li>
						<li>
							Local and national priorities are consolidated at the district
							level
						</li>
						<li>
							For districts and provinces, the draft is discussed with a Quality
							Assurance Technical Team from the Ministry of Local Government and
							Ministry of Finance and Economic Planning
						</li>
						<li>Priorities are presented to stakeholders</li>
						<li>Priorities are then approved</li>
					</ul>
					<p className="my-4">
						The Quality Assurance Technical Team was set up to assist the
						districts and provinces, including Kigali City, in preparing
						tangible Imihigo that respond to national targets. The Quality
						Assurance Technical Team is composed of members of the Imihigo
						evaluation team, the Office of the Prime Minister (PMO), the
						Ministry of Local Government (MINALOC) and Ministry of Finance and
						Economic Planning (MINECOFIN) as well as all sector ministries that
						are part of decentralisation.
					</p>
					<p className="my-4">
						The team gives regular feedback to district planners during the
						process of preparing Imihigo. District leaders are
						asked to prepare plans that are realistic, that take into account
						the cost of delivering services, and that align with the available
						resources. To make sure that proper monitoring and evaluation can be
						conducted, indicators, targets and outputs must be clearly
						identified in the planning process.
					</p>
					<p className="my-4">
						When developing Imihigo, leaders are asked to take into account
						cross-cutting issues including gender, HIV/AIDS, environment, social
						inclusion and youth. Decision makers are encouraged to discuss these
						issues and involve relevant stakeholders to ensure that their
						Imihigo incorporate these aspects of development.
					</p>
					<p className="my-4 font-semibold">
						Linkage of district plans to national planning and budgeting
					</p>
					<p className="my-4">
						The key planning instruments used in Rwanda are the Vision 2050
						strategy and NST1 which set out the government’s national objectives
						and socioeconomic transformation strategies. NST1 builds on previous
						medium-term development strategies by taking into account lessons
						learned and successes so far, as well as challenges previously
						encountered. It is the planning instrument tasked with enabling the
						transformation journey towards achieving Vision 2050 aspirations.
					</p>
					<p className="my-4">
						The formulation of NST1 was guided by the following considerations:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>
							Laying the foundation for Vision 2050 implementation and removing
							binding constraints to achieving the longer-term Vision 2050
							targets
						</li>
						<li>
							Adopting and scaling up HGI based on Rwandan culture, values, and
							the country’s unique developmental context
						</li>
						<li>
							Further developing the private sector as the engine and driver of
							economic growth
						</li>
						<li>Ensuring sustainability for long-lasting results</li>
						<li>
							Ensuring inclusiveness for all to benefit without leaving anyone
							behind
						</li>
						<li>
							Completing unfinished objectives from EDPRS 2 and Vision 2020
						</li>
					</ul>
					<p className="my-4">
						The strategy will be implemented and coordinated through the
						framework presented in Figure below.
					</p>
					<div>
						<p className="font-semibold text-primary">Framework governing Imihigo</p>
						<img src={vision} alt="" style={{ width: "100%" }} />
					</div>
					<p className="my-4">
						It is the central government’s role to ensure that national
						priorities are reflected in local government budgets through the
						allocation and transfer of earmarked grants and through the
						preparation of policy guidelines to local government for service
						provision. In addition, government ministries have an important role
						in monitoring and supporting local governments in the implementation
						of programmes and services.
					</p>
					<p className="my-4 font-semibold">
						Monitoring and evaluation process
					</p>
					<p className="my-4">
						Since 2006, Imihigo has been used by local government authorities at
						the district level for both planning and implementation of national
						programmes based on district priorities.
					</p>
					<p className="my-4">
						After the success of the Imihigo programme at the district level, in
						2009, Rwanda expanded the Imihigo programme downward and upward to
						cover almost the entire public sector. Below the district level,
						public officials from administrative sectors, cells and villages
						were included in the Imihigo programme. Administratively, each
						district in Rwanda is divided into sectors, each sector is divided
						into cells, and each cell is divided into villages. Above the
						district level, 18 ministries, 3 Boards (Rwanda Development Board
						(RDB), Rwanda Governance Board (RGB), Rwanda Mines, Petroleum and
						Gas Board (RMPGB)) and the City of Kigali, signed up for Imihigo.
					</p>
					<p className="my-4">
						Between 2006 to 2018, the monitoring and evaluation process
						consisted of local governments documenting all activities and
						expenses related to their Imihigo so that the central government
						could track progress. In addition, mayors would submit reports to
						the government explaining their progress in detail annually halfway
						through the year.
					</p>
					<p className="my-4">
						At the end of every fiscal year, the national government would put
						together two sets of evaluation teams with members from:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>the Ministry of Local Government,</li>
						<li>the Prime Minister’s Office,</li>
						<li>the Ministry of Finance and Economic Planning,</li>
						<li>the Rwandan Association of Local Government Authorities,</li>
						<li>the implementation secretariat,</li>
						<li>civil society organizations.</li>
					</ul>
					<p className="my-4">
						These two teams would travel around the country and visit all 30
						districts of Rwanda to conduct two-day evaluations, whereby the
						districts' progress reports and audit supporting
						documentation would be collected. If there were discrepancies or
						delays in implementation, the mayors would be asked to justify the
						reasons by the evaluation teams.
					</p>
					<p className="my-4">
						Lastly, the two teams would select Imihigo targets and check them
						personally. For instance, if an Umihigo (singular form of Imuhigo) was to build a new school
						with 50 classrooms, the evaluation team would visit the school,
						count the classrooms, and check if it met other specifications
						defined in the Umihigo target.
					</p>
					<p className="my-4 font-semibold">
						The National Institute of Statistics of Rwanda (NISR) in charge of
						evaluation (2018)
					</p>
					<p className="my-4">
						Between 2014 and 2018, the Institute for Policy Analysis and
						Research (IPAR), a Rwandan think-tank, led an evaluation of the
						Imihigo initiative. The revised evaluation methodology introduced
						different weights assigned to items in economic development, social
						development and accountable governance and considered citizen
						participation and satisfaction in the overall scores.
					</p>
					<p className="my-4">
						As a result of the evaluation by IPAR, and to effectively deepen the
						links between Imihigo and the transformation of livelihoods of
						Rwandans, PMO requested that NISR evaluate Imihigo from 2018
						onwards. This has resulted in refinement to the evaluation
						methodology to account for newly introduced aspects. In planning,
						Imihigo focus on transformative programs and projects with SMART indicators.
					</p>
					<p className="my-4">
						It was expected that NISR would add value in terms of independence
						and objectivity, and would root the evaluation in a more rigorous
						evidence-based approach. The mixing of routine activities and more
						impactful outcomes in Imihigo had created a problem. Administrative
						processes such as writing official letters and reports, which were
						considered in the final evaluation, sometimes came at the expense of
						more impactful activities such as completing the building of
						classrooms. Assessing the utilisation of time and the quality of
						completion of activities was also a problematic exercise. Finally,
						the attachment of Imihigo to individual entities had resulted in a
						narrowing of institutions’ focus to results for which they are
						accountable under these performance contracts. This created a gap in
						attention and accountability for key strategic results at national
						level that may not be assigned to a single institution but which
						have greater impact on the transformational agenda of Rwanda.
					</p>
					<p className="my-4">
						To address these issues, under NIRS guidance, the planning of Imihigo
						focus on transformative programs and projects with SMART (Specific,
						Measurable, Achievable, Relevant and Timely) indicators.
					</p>
					<p className="my-4">
						<span className="font-semibold">SMART targets: </span>
						Under NISR’s evaluation approach, Imihigo are to focus on key
						national programmes and projects that are transformative and SMART. Other
						routine projects and programmes will be considered and evaluated
						under action plans. Moreover, activities in both Imihigo and action
						plans will be evaluated and their respective shares reflected in the
						overall score. With regard to implementation, the focus will be on
						1) timeliness of completion and 2) quality of implemented projects.
					</p>
					<p className="my-4">
						<span className="font-semibold">
							Joint and individual Imihigo:{" "}
						</span>
						Imihigo were divided into two categories: the joint Imihigo and
						individual Imihigo. Joint Imihigo are sector-specific and are set
						and implemented in the context of a partnership between ministries
						and districts to deliver certain projects. In some instances, that
						partnership may involve the private sector. Projects implemented by
						means of joint Imihigo are mainly in the agriculture, urbanisation
						and human settlement, social protection, service delivery, energy,
						job creation and export sectors. Meanwhile individual Imihigo are
						those that are set and implemented by a single government entity.
					</p>
					<p className="my-4">
						<span className="font-semibold">Output and outcome Imihigo: </span>
						The 2014 National Leadership Retreat decided to strengthen focus of
						individual and institutional Imihigo on outcomes rather than
						outputs. Output Imihigo focus on physical products or projects that
						can deliver outcome or impact only when completed. Most outputs can
						be physically verified and counted, and cannot be put to use unless
						the Imihigo have been completed. Examples of outputs include
						classrooms, health facilities, and markets. If such facilities are
						completed, they score 100 percentage points. If they are not
						completed on time, they score 0 percentage points during evaluation
						exercises.
					</p>
					<p className="my-4">
						On the other hand, outcome Imihigo pertain to activities the results
						of which can add value at the same time as they are being
						implemented. Examples include access to electricity and to health
						insurance. During evaluation exercises they are scored based on the
						results achieved up to that point.
					</p>
					<div className="pl-8 font-semibold text-primary">
						<img src={ministry_scoring} alt="" />
					</div>
					<div className="font-semibold text-primary">
						<img src={district_scoring} alt="" style={{ width: "100%" }} className="ml-4" />
					</div>
					<p className="my-4 font-semibold">The expansion the of Imihigo evaluation</p>
					<p className="my-4">
						Prior to 2009, the Imihigo was only applied at the district level,
						but today it has expanded to:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>
							All 30 districts, Kigali City, 19 ministries and 3 boards
							(parastatals) are evaluated every year by the National Institute
							of Statistics of Rwanda (NISR)
						</li>
						<li>
							At the local levels below the sector level, Imihigo are managed by
							the district, which sets up the planning, reporting, evaluation
							guidelines and timelines consistent with the higher-level
							framework.
						</li>
					</ul>
					<div className="overflow-x-auto">
						<p className="my-4 font-semibold">
							Entities preparing, monitoring and evaluating Imihigo
						</p>
						<div className="w-full overflow-x-auto">
							<table className="w-full cp-table">
								<tr>
									<th className="border">Level</th>
									<th className="border">Imihigo Preparation</th>
									<th className="border">Monitoring</th>
									<th className="border">Evaluation</th>
								</tr>
								<tr>
									<td>Village</td>
									<td>Village Executive Committee</td>
									<td>Village Executive Committee</td>
									<td>
										Executive Council of Village and Executive Secretary of the
										Cell
									</td>
								</tr>
								<tr>
									<td>Cell</td>
									<td>Cell Executive Secretariat</td>
									<td>Cell Executive Secretariat</td>
									<td>Cell Council and Sector Executive Secretary</td>
								</tr>
								<tr>
									<td>Sector</td>
									<td>
										Community Development Committee and Sector Executive
										Secretary agreed with the Sector Joint Action Development
										Forum{" "}
									</td>
									<td>
										Community Development Committee and Sector Executive
										Secretary agreed with the Sector Joint Action Development
										Forum{" "}
									</td>
									<td>Cell Council and Sector Executive Secretary</td>
								</tr>
								<tr>
									<td>District</td>
									<td>
										Community Development Committee and District Executive
										Administrator agreed with the Sector Joint Action Development
										Forum
									</td>
									<td>
										<p className="mb-4">
										Community Development Committee and District Executive
										Administrator agreed with the Sector Joint Action Development
												Forum
										</p>
										<p>
										Governor of Province or Mayor of Kigali City
										</p>
										
									</td>
									<td>National Institute of Statistics of Rwanda</td>
								</tr>
								<tr>
									<td>Province/Kigali City</td>
									<td>
										<p className="mb-4">Province Executive Secretariat</p>
										<p>Kigali City Council</p>
									</td>
									<td>Ministry of Local Government</td>
									<td>National Institute of Statistics of Rwanda</td>
								</tr>
								<tr>
									<td>19 Ministries</td>
									<td>
										<p>
											An audit of Joint Imihigo (for the 7 priority areas). All
											targets under Joint Imihigo were assessed against their
											targeted performance
										</p>
										<p className="my-2">
											An audit of all Ministry targets (for each outcome). All
											targets were evaluated.
										</p>
										<p>
											Key informant interviews with the PS and the Director
											General(s) mainly in charge of planning at the Ministry level.
										</p>

									</td>
									<td>National Institute of Statistics of Rwanda </td>
									<td>National Institute of Statistics of Rwanda</td>
								</tr>
								<tr>
									<td>3 Boards</td>
									<td>
										Rwanda Development Board/ Rwanda Governance Board/ Rwanda Mines,
										Petroleum & Gas Board
									</td>
									<td>National Institute of Statistics of Rwanda</td>
									<td>National Institute of Statistics of Rwanda</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<Link
					to="/hgs/imihigo/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default ImihigoHowItWorks;
