import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import PageName from "./PageName";
import ImageHow from "../assets/images/How-Image.jpg";
import { Link } from "react-router-dom";

export class How4 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full pt-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
						subTitle={
							<>
								What makes them work <HiOutlineArrowNarrowRight />
							</>
						}
						to="/hgs/what-makes-them-work"
					/>
				</div>
				<div className="grid px-4 md:grid-cols-2 md:pt-24 lg:pt-0">
					<div className="h-full text-sm lg:justify-center">
						<p className="">
							The Umuganda tradition of community work had been unpopular before
							1994 due to the way it had been organised. However, the strong
							benefits of gathering people around culturally-rooted practices
							led to the reinstatement of the practice in 1998
						</p>
						<p className="my-4">
							In 2000, Ubudehe, the community participatory socio-economic development
							mechanism, was also revitalised during the formulation of the first
							Poverty Reduction Strategy under the Participatory Poverty Assessment
							Initiative; this signaled the beginning of a more deliberate attempt to
							utilise hybrid mechanisms that mixed the traditional and the modern, as
							methods for social action.
						</p>
					</div>
					<div className="flex flex-col xl:pl-20 md:pl-4">
						<div className="">
							<img src={ImageHow} alt="" />
						</div>
					</div>
				</div>
				<div className="flex flex-col md:hidden">
					<Link
						to="/hgs/what-makes-them-work"
						className="flex flex-row items-center justify-end w-full py-2 pt-8 font-semibold hover:text-secondary text-primary"
					>
						What makes Rwanda HGI work? &nbsp; <HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/hgs/what-are-hgs"
						className="flex flex-row items-center justify-end w-full text-xs font-semibold hover:text-secondary text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; What are Rwanda's Home Grown
						Solutions?
					</Link>
				</div>
			</div>
		);
	}
}

export default How4;
