import React, { Component } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

export class UmwihereroOverview extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Overview</p>
				<div className="w-full my-8">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/iciHDB4Upbc"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						<span className="italic">Umwiherero</span>, best translated as “retreat”, 
						refers to a tradition in Rwandan culture in which leaders convene in a 
						secluded place to reflect on issues affecting their communities, with 
						the objective of identifying solutions. On a smaller scale, this term 
						also refers to the action of moving to a quiet place to discuss issues 
						with a small group of people.
					</p>
					<p className="my-4">
						In modern times, the Government of Rwanda has drawn on this tradition 
						to reflect on, and address, the challenges the country faces on an annual basis. 
						<span className="italic"> Umwiherero</span> is organized by the 
						Office of the President (OTP) in conjunction with the Office of the Prime 
						Minister (PMO). The President chairs <span className="italic">Umwiherero</span>, 
						during which presentations and discussions focus on a broad range of 
						development challenges, including economics, politics, justice, infrastructure, 
						health, education, and others. Contemporary <span className="italic">Umwiherero </span> 
						was originally intended exclusively for senior public officials but has 
						evolved to include leaders from the private sector and from civil society.
					</p>
				</div>
				<Link
					to="/hgs/umwiherero/improvement"
					className="flex flex-row items-center py-12 font-semibold hover:text-secondary text-primary"
				>
					Improvement over time &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UmwihereroOverview
