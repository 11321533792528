import React, { Component, Fragment } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Route, Switch } from "react-router-dom";
import HGSSideNav from "../components/HGSSideNav";
import IngandoImpact from "../components/IngandoImpact";
import IngandoOverview from "../components/IngandoOverview";
import PageName from "../components/PageName";
import { ingandoSubmenus } from "../utils/menus";

export class Ingando extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Ingando"
							hgsSubTitle="governance and justice"
							hgsIntro={
								<p>
									The Solidarity Camp, also known as Ingando, is one of Rwanda’s
									HGI. The word Ingando comes from the verb ‘kugandika’, which
									means going to stay in a place far from one’s home, often with
									a group, for a specific reason. Traditionally, Ingando was a
									retreat during which elders, leaders or young people left
									their homes and stayed in a place where they would meditate
									and share ideas on how to solve problems affecting their
									communities or the nation.
								</p>
							}
							submenus={ingandoSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Ubudehe &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/ubudehe/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={IngandoOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={IngandoOverview}
							/>
							<Route
								exact
								path={`${match.path}/impact`}
								component={IngandoImpact}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Ingando;
