import React, { Component } from "react";
import PageName from "./PageName";
import image052 from "../assets/images/hgs-content-image052.jpeg";

export class How2 extends Component {
	render() {
		return (
			<div className="flex flex-col md:h-screen w-full relative p-4 lg:py-24 xl:px-40 md:p-16 text-justify">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid md:grid-cols-2 gap-4">
					<div className="h-full relative text-sm lg:text-base">
						<p className="text-2xl font-semibold">Challenging circumstances</p>
						<p className="my-4">
							At the end of the 1994 Genocide against the Tutsis, despite the
							virulent propaganda which had made it possible, language and
							culture were essential as a shared social place where the different
							groups of Rwandans could meet. One episode in particular underscored
							their importance, and would spark a chain of events that led to
							the adoption of HGI.
						</p>
						<p >
							Towards the end of 1996, after the massive return of the 1994 refugees
							who had fled during the campaign to stop the genocide, different groups
							with potentially antagonistic life histories found themselves in the Kibungo
							region of eastern Rwanda. Genocide survivors, 1959 refugees who came from
							camps just across the border in Burundi and Tanzania where they had lived
							for thirty-five years, people who had never left the region, and recently
							repatriated 1994 refugees, all gathered in Kibungo. Heightened tensions
							revolving around access to land surfaced in which different families made
							conflicting claims to the same land. In the immediate post-genocide context,
							tensions became deadly. Often security forces and local authorities could
							not find the culprits or fully understand what was going on, with contentious
							groups preferring to settle scores in silence.
						</p>
					</div>
					<div className="h-full flex items-center xl:pl-20 md:pl-4">
						<div className="">
							<img src={image052} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default How2;
