import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Ubudehe4 from "../assets/images/Ubudehe4.JPG";

export class UbudeheOrganization extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Organization and coordination</p>
				<div className="">
					<p className="my-4">
						Ubudehe is a highly decentralised programme based at the village level,
						and as a result is implemented and monitored by the administrative
						entities at that level.
					</p>
					<p className="my-4">
						At the national level, the Ministry of Local Government (MINALOC) and
						the Local Administrative Entities Development Agency (LODA) are
						responsible for overseeing the implementation of Ubudehe. They work
						with leaders at all levels of government, especially the village level,
						to ensure the effective functioning of the programme.
					</p>
					<p className="my-4">
						To facilitate the programme, Ubudehe Committees at each administrative
						level are established.
					</p>
					<p className="my-4">
						Once a community has decided which project they will undertake at a
						general meeting, validation must take place at the district level.
					</p>
					<p className="my-4">
						Funds are then transferred to the sector level; the sector then
						distributes the money into the village’s Ubudehe bank account which
						is usually located at Savings and Credit Cooperative Organisations
						(SACCOs) or at the Banque Populaire du Rwanda (BPR). The village level
						Ubudehe Committee is then responsible for accessing and dispersing
						the funds to suppliers.
					</p>
					<div className="my-6">
						<div className="">
							<img src={Ubudehe4} alt="" />
						</div>
					</div>
				</div>
				<Link
					to="/hgs/ubudehe/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Impact &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/ubudehe/poverty-level-categories"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Poverty level categories
				</Link>
			</div>
		);
	}
}

export default UbudeheOrganization;
