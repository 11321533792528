import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Umushyikirano2 from "../assets/images/Umushyikirano2.jpg";

export class UmushyikiranoHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">How does Umushyikirano work?</p>
				<div className="">
					<p className="my-4 text-xl font-semibold">The gathering</p>
					<p className="my-4">
						Each year around a thousand people attend the event in person,
						while thousands more follow the proceedings through live radio
						and TV coverage or via online streams, and thanks to such technology,
						citizens from the Rwandan community abroad can also participate.
					</p>
					<p className="my-4">
						Each <span className="italic">Umushyikirano</span> has a theme and
						participants are asked to pose questions related to that theme or any
						other development issue. Once a participant has asked a question, whether
						in person or remotely, the leader responsible for that area responds.
						All questions are recorded. The following year, the <span className="italic">
						Umushyikirano</span> session starts with the reading of a report that
						details actions taken in response to queries and requests made by the
						public the year before; the report includes the dates on which the
						actions were completed.
					</p>
					<p className="my-4">
						<span className="italic">Umushyikirano</span> also hosts diaspora Rwandans
						who have come to visit the country under the Come and See, Go and Tell
						programme. This is an initiative that brings Rwandans living abroad to
						come and tour Rwanda, see for themselves the reconstruction and reconciliation
						efforts being made, and go back to mobilise their families and communities to
						return home.
					</p>
					<div className="my-6">
						<div className="my-4">
							<img src={Umushyikirano2} alt="" />
						</div>
						<p className="text-xl font-semibold">Planning</p>
						<p className="my-4">
							Preparatory meetings for <span className="italic">Umushyikirano </span>
							begin in October of each year. These meetings are chaired by the Director
							of Cabinet in PMO. The decisions taken on the budget, theme, presentations
							to be given and other matters, are also their responsibility. While PMO
							is responsible for overall coordination, representatives from the following
							institutions are also involved in organising the National Dialogue Council.
						</p>
						<ul className="flex flex-col gap-4 ml-8 hgs-list">
							<li>
								Office of the President (responsible for agenda, protocol, and communications)
							</li>
							<li>
								Ministry of Finance and Economic Planning (responsible for budgetary matters)
							</li>
							<li>
								Ministry of Health (responsible for first aid and emergency medical matters)
							</li>
							<li>
								Ministry of Local Government (responsible for inviting local
								government representatives)
							</li>
							<li>
								Ministry of Foreign Affairs and International Cooperation (responsible
								for inviting representatives from the Rwandan community abroad and
								international guests)
							</li>
							<li>Ministry of Infrastructure (responsible for transport)</li>
							<li>
								Office of the Government Spokesperson (responsible for
								communications, social media integration and AskGov)
							</li>
						</ul>
					</div>
					<div className="my-4">
						<p className="text-xl font-semibold">Implementation process</p>
						<p className="my-6">
							The successful implementation of the resolutions taken during the
							<span className="italic"> Umushyikirano</span> meetings is an important
							factor that justifies their relevance. The implementation of the
							<span className="italic"> Umushyikirano</span>’s resolutions have been
							analysed at the local level through citizen surveys, and at the national
							level through analysis of various <span className="italic">Umushyikirano </span>
							reports from PMO.
						</p>
					</div>
				</div>
				<Link
					to="/hgs/umushyikirano/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Impact of Umushyikirano &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/umushyikirano/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default UmushyikiranoHowItWorks;
