import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
// import HomeEleraningImage from "../assets/images/image8.jpeg";
import homeElearning from "../assets/images/homeElearning.jpg";

export class HomeElearning extends Component {
	render() {
		return (
			<section className="w-full h-screen text-white home-section bg-primary">
				<div
					className="bg-top bg-no-repeat bg-cover home-background"
					style={{ backgroundImage: `url(${homeElearning})` }}
				></div>
				<div className="absolute z-10 w-full h-full home-elearning-gradient"></div>
				<div className="flex flex-col h-full pb-8 sm:w-full sm:px-4 home-left md:px-8">
					<div className="flex flex-row items-center h-full">
						<div className="pr-20 home-intro-content">
							<div className="flex py-8 home-section-logo lg:w-64 md:w-48">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="font-bold home-title sm:text-3xl md:text-4xl lg:text-5xl">
								<p>E-learning</p>
								<p className="-mt-4">Premium Portal</p>
							</div>
							<div className="pr-8 home-intro-paragraph sm:text-sm xl:text-base">
								<p className="my-2">
									From exchanges with visitors coming every year from different parts of 
									Africa to familiarize themselves with Rwanda’s development practices, 
									we’ve created this platform where you will learn about Rwanda’s public 
									policy good practices.
								</p>
								<p className="my-2">
									The E-learning Premium Portal will give users access to a large variety 
									of courses detailing the home-grown as well as adapted innovative 
									policies and programmes that have contributed to Rwanda’s development.  
								</p>
								<p className="my-2">
									These courses provide learners with accurate, chronicled, and data 
									supported approaches to Rwanda’s innovative policies, detailing the 
									challenges encountered along the way and how they were overcome.
								</p>
							</div>
							<div className="flex flex-row home-buttons">
								<a
									href="https://elearning.rwandapedia.rw/"
									target="_blank"
									rel="noopener noreferrer"
									className="my-5 font-bold text-white border-2 border-white home-btn sm:btn lg:px-16 md:px-3 lg:py-6 md:py-2 hover:bg-secondary hover:border-secondary"
								>
									Learn more
								</a>
							</div>
						</div>
						<div className="flex flex-col items-center justify-center w-auto h-full home-scroll-controls">
							<NavigationDots active={4} />
						</div>
					</div>
					<NavigationScroll to="home-5" />
				</div>
			</section>
		);
	}
}

export default HomeElearning;
