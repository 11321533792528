import React, { Component } from "react";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image008 from "../assets/images/hgs-content-image008.jpeg";
import image043 from "../assets/images/hgs-content-image043.jpeg";

export class WhatAre2 extends Component {
	render() {
		return (
			<div className="px-4 md:p-40 relative">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="flex flex-col md:flex-row justify-center lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/abunzi/overview"
							image={image043}
							title="Abunzi"
							subtitle="Governance and Justice"
							text={
								<p>
									The Abunzi system is a hybrid form
									of justice combining traditional with modern methods of
									conflict resolution. It is also one of Rwanda’s Home Grown
									Initiatives but similar mechanisms exist elsewhere.
								</p>
							}
						/>
					</div>
					<div className="">
						<HGSAnimatedContainer
							to="/hgs/gacaca/overview"
							image={image008}
							title="Gacaca"
							subtitle="Governance and Justice"
							text={
								<p>
									Contemporary Gacaca draws inspiration from the traditional
									model by replicating a local community-based justice system
									with the aim of restoring the social fabric of society. The
									courts are credited with laying the foundation for peace,
									reconciliation and unity in Rwanda. The Gacaca courts
									officially finished their work in June 2012.
								</p>
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre2;
