import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import Itorero1 from "../assets/images/Itorero1.jpg";
import Itorero11 from "../assets/images/Iterore11.jpg";

export class ItoreroOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Overview</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/qXdfdUSDYbM"
					/>
				</div>
				<p className="">
					The Civic Education Program, also known as Itorero, is one of Rwanda’s Home Grown Initiatives (HGI).
				</p>
				<p className="my-4">
					Traditional Itorero was a leadership and cultural school in which
					Rwandans would learn language, patriotism, social relations, sports,
					dancing, songs, martial arts and leadership. Itorero was reintroduced
					in 2009 as a way to rebuild the nation’s social fabric and mobilise
					Rwandans to uphold important cultural values and to spur a sense of
					dedication to their country. The culture of an Intore (a person who
					has received the teachings of Itorero) is regarded highly. The modern
					Itorero programme creates opportunities for participants to enhance
					positive values, build a sense of responsibility through patriotism,
					build a culture of volunteerism and hone professional abilities. Through
					Itorero and similar initiatives, the Government of Rwanda sought to
					reintroduce the culture of serving the country at no financial reward to
					encourage selfless service - attributes that contribute to accelerating
					progress and promoting social cohesion, peaceful cohabitation and public
					integrity.
				</p>
				<p className="my-4">
					Itorero ry’Igihugu, the National Itorero Commission, is responsible
					for overseeing the implementation of the programme and of ensuring
					that Rwandans from all walks of life have the opportunity to take
					part. By June 2017, the National Itorero Commission trained more than
					1,700,000 Intore from various sectors including teachers, health
					workers, executive secretaries, farmers, community policing committees
					as well as students from Rwanda and the diaspora.
				</p>
				<div className="">
					<p className="my-4 text-2xl font-semibold">Traditional Itorero</p>
					<p className="">
						Traditional Itorero sought to form complete and distinguished leaders
						mastering the various aspects of the country’s social, cultural,
						political and military life. Young people were sent in the Itorero
						care from the early adolescence until their early twenties.
					</p>
					<p className="my-4">
						Itorero trainers planned daily activities according to different
						priorities and every newcomer in Itorero had to undergo initiation,
						known in Kinyarwanda as <q>gukuramo ubunyamusozi</q>.
						The common belief was that the people who had been trained in Itorero,
						stood out from the rest of the community, especially in matters of
						expression and behaviour, because they were expected to be quick
						thinkers, experts in social relations, and knowledgeable individuals.
						Each Itorero included 40 to 100 participants of various age groups and
						had its own unique name. The best graduates would receive cows or land
						as rewards.
					</p>
					<p className="my-4">
						The tradition of Itorero provided formative training for future leaders.
						These community leaders and fighters were selected from Intore (individuals
						who took part in Itorero) and were trained in military tactics, hand-to-hand
						combat, jumping, racing, javelin throwing, archery and endurance. They were
						also taught economics, ethical values, Rwandan cultural values, management
						of men, eloquence, hunting and loyalty to the army. 
						{/* To learn more about Rwandan
						Cultural Values, download a document covering the topic {" "}
						<a
							href="http://www.rwandapedia.rw/cmis/views/workspace%253A%252F%252FSpacesStore%252F9121eae5-dbfd-4873-b4cb-9352187a2bd9"
							target="_blank"
							rel="noopener noreferrer"
							className="italic underline"
						>
							here.
						</a> */}
					</p>
				</div>
				<div className="grid gap-8 my-6 md:grid-cols-2">
					<div
						className="bg-center bg-no-repeat bg-cover"
						style={{
							height: "20rem",
							backgroundImage: `url(${Itorero11})`,
						}}
					></div>
					<div
						className="bg-center bg-no-repeat bg-cover"
						style={{
							height: "20rem",
							backgroundImage: `url(${Itorero1})`,
						}}
					></div>
				</div>
				<div>
					<p className="my-4">
						Traditionally, Itorero was found at two levels of the traditional
						administration: at regional chief’s court and the king’s court.
						Training in Itorero was preceded by training within the family. At
						the family level, both girls and boys would be educated on how to fulfil
						their responsibilities as defined by the expectations of their communities.
						For example, the man was expected to protect his family and the country,
						while the woman was expected to provide a good home and environment for
						her family. Adults were also asked to treat every child as their own in
						order to promote good behaviour among children.
					</p>
					<p className="">
						At the chiefly level, a teenage boy was selected by either his
						father or head of the extended family to be introduced to the chief
						so he could join his Itorero group. Selection was based on good
						behaviour among the rest of his family and his community.
					</p>
					<p className="my-4">
						At the king’s court – the highest level of Itorero – the person
						selected to join could either be the son of a man who went through
						the king’s Itorero or a young man who distinguished himself while in
						the chief’s Itorero. The king could also select the young man who
						would join his Itorero based on his own observations of the
						candidate in action.
					</p>
					<p className="my-4">
						Both the chief-level and king’s court-level Itorero trainings lasted
						for long periods of time to test the perseverance of the
						participants. Those who performed well would be rewarded with cows,
						allowed to return home and get married, or were nominated to various
						national duties. Intore who distinguished themselves were called
						“Intore zo ku mukondo”, which translates as the “first rate Intore”.
					</p>
					<p className="my-4">
						During colonisation, traditional Itorero gradually disappeared because
						the values ​​taught there opposed the structures imposed on society by
						colonization. In 1924, the colonial administration prohibited classic
						Itorero and introduced western-style schools. During and after the
						colonial period, the Itorero became purely cultural institutions,
						focusing on song and dance.
					</p>
				</div>
				<Link
					to="/hgs/itorero/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Itorero ry'igihugu - Contemporary Itorero &nbsp;{" "}
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default ItoreroOverview;
