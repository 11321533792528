import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import image006 from "../assets/images/hgs-content-image006.jpeg";
import image002 from "../assets/images/hgs-content-image002.jpeg";
import participant from "../assets/images/hgs-infographics/Ingando_participant.png";

export class IngandoOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Overview</p>
				{/* <div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://www.youtube.com/watch?v=n6J6iiNDtws&ab_channel=TheGovernmentofRwanda"
					/>
				</div> */}
				<div className="">
					<p className="">
						The word Ingando comes from the verb ‘kugandika’, which means going
						to stay in a place far from one’s home, often with a group, for a
						specific reason. Traditionally, Ingando was a retreat during which
						elders, leaders or young people left their homes and stayed in a
						place where they would meditate and share ideas on how to solve
						problems affecting their communities or the nation. According to
						historical sources, Ingando was the military camp or assembly area
						where troops received briefings on their organisation and mission in
						preparing for battle. These men were reminded to put their
						differences behind them and focus on the goal of protecting their
						nation. Ingando could also be a place to stop and rest or a secluded
						place where followers of a religion performed their rites.
					</p>
					<p className="my-4">
						The term Ingando has since evolved to describe a place where a group
						of people gather to work towards a common goal. Ingando trainings
						have served as de facto think-tanks in which the sharing of ideas was
						encouraged. Ingando also included the community work Umuganda. These
						trainings created a framework for the re-evaluation of divisive
						ideologies present in Rwanda during the colonial and post-colonial
						periods. Ingando was designed to provide a space for young people to
						prepare for a better future in which negative ideologies of the past
						would no longer influence them.
					</p>
					<p className="my-4">
						One aim of Ingando is to reduce fear and suspicion and encourage
						reconciliation between genocide survivors and those with family
						members who perpetrated the genocide. Ingando trainings also serve
						to reduce the distance between some segments of the Rwandan
						population and the government. Through Ingando, participants learn
						about history as well as current development and reconciliation
						policies, and are encouraged to play an active role in the
						rebuilding of their nation.
					</p>
				</div>
				<div>
					<div className="my-4">
						<img src={image006} alt="" />
					</div>
					<p className="my-4 text-2xl font-semibold">
						Connection to Itorero
					</p>
					<p className="my-4">
						The activities of Ingando involved various groups of students until
						2007, when another HGI, Itorero (National Civic Education) was
						implemented. Students in Rwanda and abroad began participating in
						Itorero, which focused on the reintroduction of lost cultural values
						in order to strengthen different communities. The groups that
						continue to take part in Ingando are those striving to be
						reintegrated into mainstream society, such as former combatants who
						recently returned home, war veterans, and street kids.
					</p>
				</div>
				<p className="text-2xl font-semibold">The objectives of Ingando</p>
				<div className="">
					<p className="my-4">The aims of Ingando were as follows:</p>
					<div className="px-4 my-6 bg-hgsBgLight">
						<ul className="flex flex-col gap-4 py-4 ml-8 hgs-list">
							<li>
								To promote a platform for the exchange of ideas and experience
								among Rwandans
							</li>
							<li>
								To encourage Rwandans to improve management of their communities
							</li>
							<li>To encourage self-reliance within the community</li>
							<li>To cultivate a culture of volunteerism among Rwandans</li>
							<li>
								To lead every section of the population towards peace and
								reconciliation
							</li>
							<li>To promote social cohesion through civic education</li>
							<li>
								To help build shelters for disadvantaged genocide survivors and
								other vulnerable citizens
							</li>
						</ul>
					</div>

					<div className="my-6">
						<div className="my-4">
							<img src={image002} alt="" />
						</div>
						<p className="my-4">
							Ingando trainings also had a socioeconomic aspect as they included
							community service activities.
						</p>
						<p className="my-4">
							The trainees wore military uniforms to be more comfortable with the
							military institution. They were also experiencing life in the
							outdoors outside of their comfort zone and adapting physically and
							mentally to harsh environments.
						</p>
						<p className="my-4">
							The training aimed to change negative perceptions about different
							aspects of the government and reduce the distance some people
							perceived between themselves, the government and its policies.
							Ingando also aimed to teach participants how to face certain
							challenges and overcome them.
						</p>
						<p className="my-4">
							Ingando graduates learned new skills to help them find new ways to
							become more financially stable and organised themselves into
							cooperatives. In the case of students, those who went to Ingando
							showed their aptitude at resolving conflicts and fighting genocide
							ideologies in their schools and universities. The trainings
							conducted with genocide perpetrators also helped during the Gacaca
							trials, as participants opened up and talked about the roles they
							played during the genocide, confessing their crimes. Telling the
							truth helped to create an environment that allowed the
							perpetrators to return to their villages and do community service
							activities to help rebuild the lives of the victims.
						</p>
					</div>
					<div>
						<p className="my-6 text-2xl font-semibold">
							<span className="italic">Ingando</span> participants
						</p>
						<p>
							Between 1999 and 2010, before Ingando for civilians was taken over
							by Itorero, over 90,000 people took part in the trainings, which
							were organised by the National Unity and Reconciliation
							Commission.
						</p>
						<div>
							<p className="mt-4">Table 1: Ingando participants:</p>
							<img src={participant} alt="" style={{ width: "100%" }} />
						</div>
					</div>
				</div>
				<Link
					to="/hgs/ingando/impact"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Impact &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default IngandoOverview;
