import React, { Component } from "react";
import PageName from "./PageName";
import image050 from "../assets/images/hgs-content-image050.jpeg";

export class How3 extends Component {
	render() {
		return (
			<div className="flex flex-col md:h-screen w-full relative p-4 lg:py-24 lg:px-40 md:p-16 text-justify">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid md:grid-cols-2 gap-4">
					<div className="relative text-sm">
						<p className="">
							After sensitisation campaigns failed to bring back peace, a
							decision was made to try to iron out differences in community
							dialogues. The solution that eventually prevailed was the
							sharing of land according to the needs of different families,
							and distribution was negotiated in community assemblies with
							dynamics similar to traditional Gacaca conflict resolution
							mechanisms.
						</p>
						<p className="my-4">
							These community deliberations turned the tide, triggering unexpected
							collaboration. Noting this, local authorities then asked the population
							to go beyond the local problems and discuss the causes of the conflict
							in Rwanda and possible solutions. The grassroots consultations were
							rolled out countrywide. They generated rich insights and identified
							five main causes of conflict in Rwanda, which were: bad leadership,
							poverty, ignorance, impunity, and exclusion.
						</p>
						<p className="my-4">
							These community discussions,
							which took place during 1997 and 1998, played an important role in
							bringing people together at the local level. The government decided
							to emulate the seminal grassroots exercise by organising in-depth
							deliberations at the national level in what were named the Urugwiro
							Village Discussions.
						</p>
					</div>
					<div className="flex items-center xl:pl-20 md:pl-4">
						<div className="">
							<img src={image050} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default How3;
