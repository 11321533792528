import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Gacaca21 from "../assets/images/Gacaca21.JPG";
import Gacaca22 from "../assets/images/Gacaca22.jpg";

export class GacacaHowItWorked extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">
					How did the Gacaca courts work?
				</p>
				<p className="my-4">The aims of the Gacaca were:</p>
				<div className="">
					<ol className="flex flex-col gap-4 ml-4 hgs-ordered-list">
						<li>To expose the truth about the Genocide against the Tutsi</li>
						<li>To speed up genocide trials</li>
						<li>To eradicate impunity</li>
						<li>To strengthen unity and reconciliation among Rwandans</li>
						<li>
							To draw on the capacity of Rwandans to solve their own problems
						</li>
					</ol>
					<div className="my-4">
						<img src={Gacaca21} alt="" />
					</div>
					<p className="my-4">
						The following principles guided the Gacaca process:
					</p>
					<ol className="flex flex-col gap-4 ml-4 hgs-ordered-list">
						<li>
							Genocide suspects were classified into categories based on the
							gravity of the charges brought against them
						</li>
						<li>
							Opportunity was given to genocide suspects to admit and confess to
							their crimes and to ask for forgiveness; when their confessions
							were accepted, their sentences were reduced
						</li>
						<li>
							Special sentencing was given for those who committed genocide
							crimes as minors
						</li>
					</ol>
					<p className="my-4">
						As described above, suspects were placed into one of the following
						four categories as per the Gacaca law of 2001:
					</p>
					<p className="my-4 text-xl font-semibold">Category One:
						<span className="ml-2 text-base font-normal">
							Planners, organisers, instigators, supervisors and ringleaders of
							the genocide or crimes against humanity as well as those who
							committed rape or sexual torture. Suspects identified through
							Gacaca as belonging to category 1 were tried by ordinary courts.
						</span>
					</p>
					<p className="my-4 text-xl font-semibold">Category Two:
						<span className="ml-2 text-base font-normal">
							Perpetrators, co-perpetrators or accomplices of murder or serious
							violence against individuals, causing death together with
							accomplices. Those who injured others or committed other acts of
							serious violence with the intention to kill, but who did not
							attain their objective.
						</span>
					</p>
					<p className="my-4 text-xl font-semibold">Category Three:
						<span className="ml-2 text-base font-normal">
							Those who committed other acts of serious violence without the
							intention to kill.
						</span>
					</p>
					<p className="my-4 text-xl font-semibold">Category Four:
						<span className="ml-2 text-base font-normal">
							Those who committed offences only against property
						</span>
					</p>
					<p className="my-4">
						From 2004 to 2007, these four categories were revised down to three
						to streamline the process and from March 2007 onward they were as
						shown in table 1.
					</p>
					<div className="p-8 my-4 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							Category 1
							<span className="text-base"> (tried by ordinary courts)</span>
						</p>
						<ul className="flex flex-col gap-4 ml-8 hgs-list">
							<li className="">A person who occupied positions of leadership</li>
							<li>A person who committed acts of rape or sexual torture</li>
						</ul>
					</div>
					<div className="p-8 my-4 bg-hgsBgLight">
						<p className="text-xl font-semibold">Category 2</p>
						<ul className="flex flex-col gap-4 ml-8 hgs-list ">
							<li>A well-known and zealous murderer</li>
							<li>A person who committed acts of torture against others</li>
							<li>A person who committed dehumanising acts on the dead</li>
							<li>
								A person whose criminal acts or participation placed him/her among those
								of the killers or perpetrators of serious attacks against
								others, causing death
							</li>
							<li>
								A person who injured or committed other acts of serious attacks,
								with intention to kill, but who did not attain his or her
								objective
							</li>
							<li>
								A person who committed or participated in criminal acts against
								persons, without any intention of killing them
							</li>
						</ul>
					</div>
					<div className="p-8 my-4 bg-hgsBgLight">
						<p className="text-xl font-semibold">Category 3</p>
						<ul className="flex flex-col gap-4 ml-8 hgs-list ">
							<li>A person who committed offences only against property</li>
						</ul>
					</div>
					<div>
						<p className="text-2xl font-semibold">
							Organisation and functioning of Gacaca Courts
						</p>
						<p className="my-4">
							While Gacaca courts were given competence similar to other judicial systems, they
							also had the special competence of investigating the manner in which crimes were
							committed, a task normally carried out by the prosecution department.							</p>
						<p className="my-4">
							Gacaca Courts were created, in each Cell, Sector, District “or Town” and
							Province/Kigali City of the Republic of Rwanda. This court was responsible for
							handling crimes of Genocide and other crimes against humanity committed in
							Rwanda between October 1st, 1990 and December 31st, 1994 within the limits
							of the law.
						</p>
						<p className="mt-8 text-xl font-semibold">
							1. Organs of Gacaca Courts
						</p>
						<p className="my-4">
							Each Gacaca Court was comprised of a General Assembly, a Bench and a Coordination
							Committee.
						</p>
						<p className="text-xl font-semibold">
							The General Assembly
						</p>
						<p className="my-4">
							The General Assembly of each “Gacaca Court” held an ordinary meeting once a
							month and an extraordinary session wherever it was required for the good functioning
							of the “Gacaca Court”. Decisions of the General Assembly were taken by consensus
							or, otherwise at the absolute majority of its members. This monthly meeting was
							aimed at evaluating the activities of the Bench and the coordinating committee.
						</p>
						<p className="my-4">
							The General Assembly of the Cell Gacaca Court was called upon to give information
							regarding the perpetrators and victims of Genocide; where every inhabitant of
							the Cell had to indicate the place where he or she resided before and during
							Genocide, the individual also testified on what he or she knew about the crimes
							of Genocide committed in the Cell.
						</p>
						<p className="text-xl font-semibold">
							The Seat
						</p>
						<p className="my-4">
							The members of the bench of “Gacaca Courts” commonly known as Inyangamugayo
							judges were Rwandans of integrity elected by the General Assembly of the Cell
							in which they resided. Each bench of the Gacaca Court was composed of 19
							individuals of integrity.
						</p>
						<p className="my-4">
							The election of inyangamugayo was conducted countrywide from 4-7 October 2001;
							other elections were held to replace inyangamugayo who were no longer able to
							serve on the courts. Thirty-four percent of the inyangamugayo were women.
						</p>
						<p className="my-4">
							The National Service of Gacaca Courts organised general training sessions for
							all Gacaca inyangamugayo countrywide, and provided special training sessions
							for the courts that demonstrated the need for supplementary training.
						</p>
						<p className="my-4">
							These inyangamugayo mainly comprised school teachers, civil servants and
							business people.
						</p>
						<p className="text-xl font-semibold">
							The Coordination Committee
						</p>
						<p className="my-4">
							The Coordination Committee was composed of a President, first Vice-President,
							second Vice-President and two secretaries, all of whom had to be able to read
							and write Kinyarwanda.
						</p>
						<p className="my-4">
							Members of a “Gacaca Court” seat elected the Coordination Committee among
							themselves, with a simple majority.
						</p>
						<p className="my-4">
							The Coordination Committee of every “Gacaca Court” carried out the following
							functions:
						</p>

						<div className="p-8 my-4 bg-hgsBgLight">
							<ul className="flex flex-col gap-4 ml-8 hgs-list ">
								<li>
									to convene, preside over meetings and coordinate activities of the
									Bench for the Gacaca Court as well its General Assembly,
								</li>
								<li>to register complaints, testimonies and evidences given by the population;</li>
								<li>to receive and record files for suspects answerable to “Gacaca Court”; </li>
								<li>to register appeals filed against judgements passed by “Gacaca Courts”;</li>
								<li>to forward files of judgements appealed against to Gacaca Courts of Appeal;</li>
								<li>to register decisions made by organs of the “Gacaca Court”;</li>
								<li>to prepare reports of activities of “Gacaca Courts”;</li>
								<li>
									to implement decisions of the General Assembly and those of the “Gacaca Court” Bench;
								</li>
								<li>
									to immediately transfer the report of activities approved by the General
									Assembly of the “Gacaca Court” to superior Gacaca Courts.
								</li>
							</ul>
						</div>
						<p className="my-4">
							The Coordination Committee of the “Gacaca Court” met as many times as possible,
							convened by its President, of his own initiative or requested by at least two members
							of the committee. In order to sit validly, the Coordination Committee had to have at
							least three of its members present, of whom one was a secretary. Decisions were made
							by consensus. If there was no consensus, the matter was submitted to the “Gacaca Court”
							Bench.
						</p>
						<p className="mt-8 text-xl font-semibold text-center">
							2. Duties of Gacaca Courts
						</p>
						<p className="my-4">
							The law provided for specific duties for each Gacaca Court:
						</p>
						<p className="text-xl font-semibold">
							The Cell Gacaca Court
						</p>
						<p className="my-4">
							The Cell Gacaca Court exercised the following duties:
						</p>
						<div className="p-8 my-4 bg-hgsBgLight">
							<ul className="flex flex-col gap-4 ml-8 hgs-list ">
								<li>to make up a list of individuals:
									<ul className="ml-12">
										<li>Who resided in the cell before and after Genocide;</li>
										<li>Suspected of having participated in Genocide;</li>
										<li>Victims and their damaged properties;</li>
									</ul>
								</li>
								<li>to bring together the files forwarded by the Public Prosecution;</li>
								<li>to categorise suspects according to the provisions of this Organic Law;</li>
								<li>to try cases related to properties;</li>
								<li>
									to give a ruling on the disqualification of members of the Bench of
									Gacaca Court of the Cell;
								</li>
								<li>to receive confessions from individuals who participated in Genocide;</li>
								<li>to forward the files which are not in their jurisdiction to the competent Courts;</li>
								<li>to elect members of the Coordination Committee</li>
							</ul>
						</div>
						<p className="text-xl font-semibold">
							The Gacaca Court of the Sector, District or Town and that of the Province or Kigali City
						</p>
						<p className="my-4">
							The Gacaca Court Bench of the Sector, District or Town and that of the Province or
							Kigali City had the following duties among others:
						</p>
						<div className="p-8 my-4 bg-hgsBgLight">
							<ul className="flex flex-col gap-4 ml-8 hgs-list ">
								<li>Conducting investigations, if necessary, on testimonies given;</li>
								<li>to receive confessions from individuals who participated in genocide;</li>
								<li>to give a ruling on the disqualification of members of the Bench;</li>
								<li>
									to try cases falling under its jurisdiction, after making sure that
									suspects forwarded to it, have been categorised in conformity with
									the alleged offences;
								</li>
								<li>
									examining appeals against judgements passed by Gacaca Courts of the
									inferior level within its jurisdiction;
								</li>
								<li>electing members of the Coordination Committee;</li>
								<li>
									Examining reports of activities from the lower Gacaca Courts of its jurisdiction.
								</li>
							</ul>
						</div>
					</div>
					<p className="mt-8 text-xl font-semibold text-center">3. The jurisdictions of Gacaca</p>
					<div className="">
						<div className="my-4">
							<p className="font-semibold">The Cell Gacaca Court</p>
							<p className="my-4">
								The Cell Gacaca Court, operating at the second lowest local
								government level, the cell, dealt with crimes in category three.
								It dealt also with objections filed against the sentence
								pronounced in the absence of the accused. The Cell Gacaca Court
								categorised alleged perpetrators of the genocide.
							</p>
						</div>
						<div className="my-4">
							<p className="font-semibold">The Sector Gacaca Court</p>
							<p className="my-4">
								The Sector Gacaca Court, operating at the sector level which is
								the next local government level up from the cell, dealt with
								defendants whose offences fell into category three, as well as
								opposition made against sentences pronounced in the absence of the
								accused.
							</p>
						</div>
						<div className="my-4">
							<p className="font-semibold">
								The District or Town Gacaca Court
							</p>
							<p className="my-4">
								The District or Town Gacaca Court, operating at the district level
								which is the next local government level up from the sector, dealt
								with crimes in category two, appeals filed against judgments at
								the first level, or decisions by Sector Gacaca Courts that were
								rendered in the absence of the accused.
							</p>
						</div>
						<div className="my-4">
							<p className="font-semibold">
								The Gacaca Court of the Province or Kigali City
							</p>
							<p className="my-4">
								The Gacaca Court of the Province or Kigali City, operating at the
								province or Kigali City level which is the next local government
								level up from the district, dealt with appeals of judgments
								rendered at the first level, or on objection by Gacaca Courts of
								the District or Town.
							</p>
						</div>
						<div className="my-4">
							<img src={Gacaca22} alt="" />
						</div>
						<div className="grid gap-8 my-4 lg:grid-cols-2">
							<div className="">
								<p className="text-2xl font-semibold">Analysis of the cases</p>
								<p className="my-4">
									After information about a case was presented to the courts, it
									was analysed by the inyangamugayo of the Gacaca court at the
									cell level. These judges then prepared a file for those who were
									accused of committing crimes. Based on the gravity of the crimes
									allegedly committed, the suspect was put in one of the three
									categories described above.
								</p>
								<p className="my-4 ">
									After the inyangamugayo had prepared the file and categorised it
									appropriately, it was then submitted to the court with the
									competence to judge it. The files of the first and second
									categories were submitted to the Gacaca court of the sector,
									while those in the third category remained in the Gacaca court
									of the cell.
								</p>
								<p className="my-4 ">
									During this investigative phase, the number of suspects
									increased significantly which placed a strain on the courts’
									ability to deliver timely justice to victims.
								</p>
							</div>
							<div className="">
								<p className="text-2xl font-semibold list-outside">Hearings</p>
								<p className="my-4 ">
									Gacaca hearings were public, except for those recorded on camera
									as decided by the court, or upon the request of any interested
									party; hearings were decided with a pronounced judgment for
									reasons of public order. Deliberations of inyangamugayo were
									conducted in private. Every Gacaca court held a hearing at least
									once per week usually beginning at 8:30am and finishing at 4pm.
								</p>
								<p className="my-4 ">
									Whoever was summoned to appear before the court, whether
									accused, witness, victim or any other person, needed to be
									informed at least seven days before the trial. If the defendant
									had neither a known address nor residence in Rwanda, the summons
									period was one month.
								</p>
								<p className="my-4 ">
									At every hearing, the president of the court requested all those
									present to observe a minute of silence in memory of the victims
									of genocide. Before testifying, the witness had to take an oath
									and swear to tell the truth. The sessions were governed by a set
									of eight rules which ensured the proper and respectful
									functioning of the hearing. These rules stipulated that those
									present raise their hand to speak, prohibited insults or
									threats, and obliged participants to always tell the truth.
								</p>
							</div>
						</div>
					</div>

				</div>
				<Link
					to="/hgs/gacaca/achievements"
					className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Achievements &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/gacaca/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default GacacaHowItWorked;
