import React from "react";
import { Link } from "react-router-dom";

const PageName = ({ title, subTitle, color, accentColor, to }) => {
	return (
		<div className="absolute top-0 right-0 flex flex-col mx-24 my-2 sm:mt-20 sm:mx-4">
			<div className="flex flex-row justify-end">
				<p className={`text-${accentColor} cursor-pointer`} >ENG</p>
				<p>/</p>
				<a href={"https://fr.rwandapedia.rw" + window.location.pathname} className="cursor-pointer">FR</a>												
			</div>
			<div
				className={` text-2xl font-bold mt-2 hover:text-${accentColor} ${
					color ? `text-${color}` : ""
				}`}
			>
				{subTitle ? (
					<Link
						to={to}
						className={`text-${accentColor} font-semibold text-base flex flex-row items-center justify-end`}
					>
						{subTitle}
					</Link>
				) : (
					""
				)}
				<div className="text-right page-name">{title}</div>
			</div>
		</div>
	);
};

export default PageName;
