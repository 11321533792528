import React, { Component, Fragment } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Route, Switch } from "react-router-dom";
//import AbunziChallenges from "../components/AbunziChallenges";
import AbunziHowItWorks from "../components/AbunziHowItWorks";
import AbunziOverview from "../components/AbunziOverview";
import AbunziPerformance from "../components/AbunziPerformance";
import AbunziPractices from "../components/AbunziPractices";
import HGSSideNav from "../components/HGSSideNav";
import PageName from "../components/PageName";
import { abunziSubmenus } from "../utils/menus";

export class Abunzi extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Abunzi"
							hgsSubTitle="governance and justice"
							hgsIntro={
								<p>
									The system of community mediators, also known as Abunzi,
									combines traditional and modern methods of conflict
									resolution. It is also one of Rwanda's Homegrown Initiatives
									(HGI), although similar mechanisms exist elsewhere.
								</p>
							}
							submenus={abunziSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Gacaca &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/gacaca/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={AbunziOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={AbunziOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={AbunziHowItWorks}
							/>
							<Route
								exact
								path={`${match.path}/performance`}
								component={AbunziPerformance}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={AbunziChallenges}
							/> */}
							<Route
								exact
								path={`${match.path}/practices`}
								component={AbunziPractices}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Abunzi;
