import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import PageName from "../components/PageName";
import UmushyikiranoHowItWorks from '../components/UmushyikiranoHowItWorks';
import UmushyikiranoImpact from '../components/UmushyikiranoImpact';
import UmushyikiranoOverview from '../components/UmushyikiranoOverview';
import { umushyikiranoSubmenus } from '../utils/menus';

export class Umushyikirano extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Umushyikirano"
							hgsSubTitle="governance and justice"
							hgsIntro={
								<p>
									The National Dialogue Council known as Umushyikirano is one of
									Rwanda’s Homegrown Initiatives. The word Umushyikirano
									translates to a meeting where participants are able to
									exchange ideas, share experiences and question each other.
								</p>
							}
							submenus={umushyikiranoSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Imihigo &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/imihigo/overview"
							/>
						</div>
						<Switch>
							<Route
								exact
								path={`${match.path}`}
								component={UmushyikiranoOverview}
							/>
							<Route
								exact
								path={`${match.path}/overview`}
								component={UmushyikiranoOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={UmushyikiranoHowItWorks}
							/>
							<Route
								exact
								path={`${match.path}/impact`}
								component={UmushyikiranoImpact}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Umushyikirano
