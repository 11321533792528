import React, { Component } from 'react';
import SocialMediaLinks from "./SocialMediaLinks";

export class MobileFooter extends Component {
    render() {
        return (
            <div className="mobile-bottom sm:block bg-smokeWhite flex px-4">
                <SocialMediaLinks />
                <div className="home-buttons flex flex-row">
                    <button className="sm:btn w-full px-10 py-4 my-10 sm:my-0 text-white bg-grey-light hover:bg-primary hover:text-black font-bold">
                        Subscribe to updates
								</button>
                </div>
            </div>
        )
    }
}

export default MobileFooter
