import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Abanzi2 from "../assets/images/Abanzi2.jpg";
import hgsInfographics001 from "../assets/images/hgs-infographics/hgs-infographics001.png";

export class AbunziHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">How does it work?</p>
				<p className="my-4 text-xl font-semibold">Organisational structure</p>
				<div className="">
					<p className="">
						The Abunzi operate at the cell
						level for initial cases and at the sector level in the event of
						appeal cases. The Abunzi committee is composed of twelve people
						known for their integrity, who reside respectively in the concerned
						cell and sector and who are recognised for their ability to
						reconcile differences. These Abunzi are elected by the Cell
						Council and the Sector Council, respectively, for a renewable term
						of five years.
					</p>
					<p className="my-4">
						The Abunzi Committee, at the cell and sector level, is headed by
						a bureau composed of a President and a Vice-President elected by
						their peers. Claims made to the Abunzi are received by the Executive
						Secretary of the Cell, who in turn forwards them to the Abunzi
						Committee.
					</p>
					<div
						className="my-8"
					>
						<img src={Abanzi2} alt="" />
					</div>
					<p className="my-4 text-xl font-semibold">
						Functioning of the Abunzi
					</p>
					<p className="my-4">
						In order to initiate a case, one of the parties must first submit a
						complaint to the Executive Secretary of the cell verbally, or in
						writing, so that it can be registered by the Abunzi Committee.
						The applicant must provide a brief outline of the case to inform the
						proceedings, after which the Abunzi Committee can summon parties
						and decide on the venue, as well as the date and time for hearing
						the case.
					</p>
					<p className="my-4">
						The parties then agree on three Abunzi to whom they submit their
						case. When the parties cannot agree on Abunzi, each party chooses
						one Umwunzi(Singular form of Abunzi), and the two chosen Abunzi choose the third. Abunzi
						must settle the litigation within one month from the day the
						litigation is registered by the Abunzi Committee.
					</p>
					<p className="my-4">
						In most cases, the mediation hearing is public, unless decided
						otherwise by Abunzi. Other members of the Abunzi Committee not
						chosen to settle the matter may participate in the mediation
						session, but do not have the right to decide. When settling a case,
						Abunzi hear from each of the parties in conflict and from any
						available witnesses. During those hearings, advocates are allowed to
						assist the parties, but they cannot represent or plead for any
						party.
					</p>
					<p className="my-4">
						In each instance, Abunzi are obliged to first strive to
						reconcile both parties, but where this proves impossible, they
						render a decision in accordance with the laws and local customary
						practices. When the Abunzi are successful at reconciling the
						parties, prosecution does not occur.
					</p>
					<p className="my-4">
						After considering the case, Abunzi withdraw to make a
						decision. Abunzi's decision is taken by consensus or by the
						absolute majority of votes in the event that a consensus cannot be
						reached. Recorded minutes of the proposed settlement are signed by
						Abunzi and by the concerned parties when the mediation procedure
						is completed. In all cases, the decision is written, signed on each
						page and available within ten days from the day of the decision.
					</p>
					<div className="grid gap-8 my-4 xl:grid-cols-2">
						<div className="">
							<p className="text-2xl font-semibold">
								Appealing decisions of Abunzi
							</p>
							<p className="">
								Either party can appeal the Abunzi's decision at the sector or
								cell level within a period of one month from the day the written
								decision was handed down. Once received by the Abunzi
								Committee at the sector level, Abunzi will only examine
								aspects of the case deemed objectionable by the appealing party.
								There are no filing fees associated with the appeal process.
							</p>
						</div>
						<div className="">
							<p className="text-2xl font-semibold list-outside">
								Legal competence of Abunzi
							</p>
							<p className="my-4">
								The jurisdiction of the Abunzi system in civil matters is as
								follows:
							</p>
							<ol className="flex flex-col gap-4 ml-4 list-decimal hgs-ordered-list">
								<li>
									Disputes over land and other immovable assets the value of
									which does not exceed RWF 3,000,000 or USD 3068
								</li>
								<li>
									Dispute over cattle and other movable assets the value of
									which does not exceed RWF 1,000,000 or USD 1022
								</li>
								<li>
									Disputes relating to alleged breaches of contract in which the
									value of the relevant case does not exceed the value of
									RWF 1,000,000, or USD 1022, with the exception of central
									government, insurance and commercial contractual obligations
								</li>
								<li>
									Employment disputes between individuals in which the disputed
									value is less than RWF 100,000 or USD 102
								</li>
								<li>
									Family disputes other than those related to civil status
								</li>
								<li>
									Inheritance issues in which the disputed value does not exceed
									RWF 3,000,000 or USD 3068
								</li>
							</ol>
						</div>
					</div>
					<p className="my-4">
						The figure below shows that land inheritance issues, at 47%, constitute the most
						common claim type, while disputes relating to moving land boundaries constitute
						39% of cases. This highlights how widespread these types of conflicts are within
						communities, and confirms the necessity of a system that allows them to be settled
						outside of the formal court system where possible.
					</p>
					<p className="text-xl font-semibold">
						Categories of claims presented to Abunzi
					</p>
					<div>
						<div className="border-b-4 border-secondary">
							<img src={hgsInfographics001} alt="" className="" />
						</div>
						<p className="font-semibold text-right text-secondary">
							Survey on the Performance of Community Mediators
						</p>
					</div>
				</div>
				<Link
					to="/hgs/abunzi/performance"
					className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Performance and evaluation &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/abunzi/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default AbunziHowItWorks;
