import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import monitoring_value from "../assets/images/hgs-infographics/Umuganda_monitoring value.png";
import contribution_of_citizen from "../assets/images/hgs-infographics/umuganda_contribution_of_citizen.png";
import level_of_participant from "../assets/images/hgs-infographics/Umuganda_level_of_participant.png";
import Umuganda3 from "../assets/images/Umuganda3.jpg";

export class UmugandaImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Impact</p>
				<div className="">
					<p className="my-4">
						Umuganda is credited with contributing to Rwanda’s development,
						particularly in the areas of infrastructure development,
						environmental protection and good governance.
					</p>
					<p>
						Common infrastructure projects include roads (especially those
						connecting sectors), bridges, health centres, classroom construction
						(to support the 9 and 12 Years Basic Education programmes),
						housing construction for poor and vulnerable Rwandans (often to
						replace grass-thatched housing), and the construction of local government
						offices and savings and credit cooperative (SACCO) buildings.
					</p>
					<div className="my-4">
						<p className="mt-4 font-semibold text-primary">
							Contribution of citizens and government in the construction
							of 9 Years Basic Education classrooms
						</p>
						<div className="border-b-4 border-secondary">
							<img src={contribution_of_citizen} alt="" style={{ width: "100%" }} />
						</div>
						<p className="font-semibold text-right text-secondary">
							RGB, the Assessment of the impact of Home Grown initiatives,
							Kigali, 2014
						</p>
					</div>
					<p className="my-4">
						Environmental protection projects undertaken under Umuganda include tree
						planting and terracing to fight erosion, wetland rehabilitation, renewable
						energy construction and crop planting.
					</p>
					<p className="my-4">
						The governance impact of Umuganda is diverse and includes, according the a
						2014 survey conducted by Rwanda Governance Board, strengthened social cohesion
						between people from different backgrounds, increased opportunity for community
						members to articulate their needs and express their opinions on various issues,
						strengthened unity and reconciliation through the platform that Umuganda provides
						for conflict resolution between community members, increased opportunity for
						neighbourhood members to know each other and engage in friendships, increased
						mutual assistance in the community, and enhanced dialogue between national
						leaders and the population.
					</p>
					<p className="my-4">
						The President of the Republic of Rwanda, members of Parliament and Ministers
						participate in the monthly community works together with the population, which
						promotes dialogue between national leaders and the population at the grassroots
						level. Umuganda is also an avenue for information sharing, through which many
						public policies are communicated and explained to the general population.
					</p>
					<p className="my-4">
						The table below shows that participation in Umuganda rose form 78% in 2010/2011 to 91% in
						2014/2015 and remained above 90% until 2019. The next table shows that the monetary
						value of Umuganda activities began at 7.3 billion RWF in 2010/2011 and rose
						every year, eventually reaching 22.8 billion RWF in 2018/2019.
					</p>
					<div className="my-4 font-semibold text-primary">
						<div className="border-b-4 border-secondary">
							<img src={level_of_participant} alt="" style={{ width: "100%" }} />
						</div>
						<p className="font-semibold text-right text-secondary">
							Government of Rwanda, 2020
						</p>
					</div>
					<div className="my-4 font-semibold text-primary">
						<div className="">
							<img src={monitoring_value} alt="" style={{ width: "100%" }} />
						</div>
					</div>
					<div className="my-4">
						<img src={Umuganda3} alt="" />
					</div>
				</div>
				<Link
					to="/hgs/umuganda/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; How does Umuganda work?
				</Link>
			</div>
		);
	}
}

export default UmugandaImpact;
