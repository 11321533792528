import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
import { Link } from "react-router-dom";
// import homeCPImage from "../assets/images/cp-content-image009.jpeg";
import homeCP from "../assets/images/homeCP.jpg";

export class HomeCountryProfile extends Component {
	render() {
		return (
			<section className="home-section h-screen w-full bg-primary text-white">
				<div
					className="home-background bg-cover bg-no-repeat bg-center"
					style={{ backgroundImage: `url(${homeCP})` }}
				></div>
				<div className="home-cp-gradient absolute w-full h-full z-10"></div>
				<div className="sm:w-full sm:px-4 home-left flex flex-col md:px-8 pb-8 h-full">
					<div className="flex flex-row h-full items-center">
						<div className="home-intro-content pr-20">
							<div className="home-section-logo flex lg:w-64 md:w-48 py-8">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="home-title text-6xl font-bold">
								<h2 className="sm:text-3xl md:text-4xl lg:text-5xl">
									Country Profile
								</h2>
							</div>
							<div className="home-intro-paragraph sm:text-sm xl:text-base py-4 pr-8">
								<p className="my-2">
									It is its landscapes that have earned Rwanda the name of{" "}
									<span className="font-bold">
										The Land of a Thousand Hills.
									</span>
								</p>
								<p className="my-4">
									Over the past two decades, the country has experienced political
									and social stability supported by strong economic growth. Rwanda
									is recognized for the quality of its governance and the important
									role played by women in the life of the nation.
								</p>
							</div>
							<div className="flex flex-row w-full items-start">
								<div className="home-buttons flex flex-row">
									<Link
										to="/country/introduction"
										className="sm:btn lg:px-16 md:px-3 lg:py-6 md:py-2 my-5 text-white hover:bg-secondary font-bold border-2 border-white hover:border-secondary"
									>
										Learn more
									</Link>
								</div>
							</div>
						</div>
						<div className="home-scroll-controls flex flex-col h-full w-auto justify-center items-center">
							<NavigationDots active={2} />
						</div>
					</div>
					<NavigationScroll to="home-3" />
				</div>
			</section>
		);
	}
}

export default HomeCountryProfile;
