import React, { Component } from "react";
import { Link } from "react-router-dom";
import CPLeftImage from "./CPLeftImage";
import PageName from "./PageName";
import Homepage from "../assets/images/HGI-Homepage.jpg";
import { MdKeyboardArrowRight } from "react-icons/md";

export class WhatAre extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full text-justify md:h-screen">
				<div className="sm:hidden">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full">
						<div className="hidden md:flex">
							<CPLeftImage image={Homepage} />
						</div>
						<div className="flex flex-col mt-20 border-b-8 md:hidden border-secondary">
							<div className="flex flex-row items-center px-4 py-2 text-xs uppercase">
								<div>
									<Link to="/" className="font-semibold text-secondary">
										Home
									</Link>
								</div>
								&nbsp;
								<MdKeyboardArrowRight className="text-xl" />
								&nbsp;
								<div className="font-semibold">
									What are Rwanda's Homegrown Initiatives?
								</div>
							</div>
							<img src={Homepage} alt="" />
						</div>
						<div className="top-0 flex flex-col w-full p-4 md:absolute md:h-screen md:bg-primary md:bg-opacity-75 lg:px-10 md:px-4 md:justify-center">
							<div className="py-12 overflow-y-auto lg:font-semibold lg:text-sm md:text-xs">
								<div className="text-xl font-semibold md:hidden">
									What are Rwanda's Homegrown Initiatives?
								</div>
								<p className="my-4">
									Rwanda’s Home Grown Initiatives (HGI) comprise a range
									of institutional and organisational mechanisms developed
									by Rwandan citizens based on local opportunities, cultural values
									and the history of the country, to fast track its development
								</p>
								<p>
									Generally, HGI are community based and give a central role to
									the population in dealing with important social challenges like
									fostering rural development and social protection, or, in Rwanda’s
									recent past, handling genocide related trials. These mechanisms
									often take their inspiration from traditional culture, which
									enhances their social acceptance. In addition, a number of good
									practices have been designed to complement HGI.
								</p>
								<p className="my-4">
									HGI gradually emerged from the challenging post-genocide
									context. Rwandans were faced with seemingly insurmountable
									problems – the country had a deep social divide and faced a
									difficult international environment. However, they were
									determined to pursue self-reliance (kwigira) and were thus
									compelled to find innovative solutions. To do so, they used
									one of their most valuable social resources that was available:
									their cultural heritage.
								</p>
								<p>
									HGI have strongly contributed to the dynamism of the socioeconomic
									and political transformation of post-genocide Rwanda, as well as to
									the achievement of Rwanda’s Millennium Development Goals (MDGs).
									The World Bank in 2020 said of Rwanda: “a strong focus on homegrown
									policies and initiatives contributed to a significant improvement
									in access to services and in human development indicators”. Following
									their success, the search for home grown mechanisms to deal with
									matters that concern citizens has been constitutionally mandated.
								</p>
							</div>
						</div>
					</div>
					<div className="flex flex-col h-full px-4 overflow-x-auto md:pt-20 lg:px-8 lg:pr-24 md:pr-12">
						<p className="my-4 text-sm md:font-semibold">
							HGI intervene in processes of governance and justice, economy, and social
							welfare. The key HGI that spurred socio-economic development are listed in
							Table 1.
						</p>
						<div className="flex flex-col my-4 overflow-x-auto border">
							<p className="font-bold text-primary">
								Table 1: HGI according to three categories
							</p>
							<table className="border border-collapse hgs-list-table border-secondary">
								<thead>
									<tr className="w-full px-4 py-1 font-semibold text-white uppercase bg-primary">
										<th>Governance and Justice</th>
										<th>Economy</th>
										<th>Social Welfare</th>
									</tr>
								</thead>

								<tbody className="text-sm">
									<tr>
										<td className="">
											<Link
												to="/hgs/gacaca/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Gacaca <br />
											</Link>
											<span>(Traditional conflict resolution)</span>
										</td>
										<td>
											<Link
												to="/hgs/umuganda/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Umuganda <br />
											</Link>
											<span>(Community work)</span>
										</td>
										<td>
											<Link
												to="/hgs/ubudehe/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Ubudehe <br />
											</Link>
											<span>
												(Participatory socio-economic development program)
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/abunzi/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Abunzi <br />
											</Link>
											<span>(Mediation and reconciliation committees)</span>
										</td>
										<td>
											<Link
												to="/hgs/imihigo/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Imihigo <br />
											</Link>
											<span>(Performance contracts)</span>
										</td>
										<td>
											<Link
												to="/hgs/girinka/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Girinka <br />
											</Link>
											<span>(One Cow per Poor Family)</span>
										</td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/umushyikirano/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Inama y’Igihugu y’Umushyikirano <br />
											</Link>
											<span>(National Dialogue Council)</span>
										</td>
										<td colSpan="2"></td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/itorero/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Itorero/Ingando <br />
											</Link>
											<span>(National Civic Education Programme)</span>
										</td>
										<td colSpan="2"></td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/umwiherero/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Umwiherero <br />
											</Link>
											<span>(National Leadership Retreat)</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre;
