import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Umuganda2 from "../assets/images/Umuganda2.jpg";
import image037 from "../assets/images/hgs-content-image037.jpeg";
import image007 from "../assets/images/hgs-content-image007.jpeg";

export class UmugandaHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">How does Umuganda work?</p>
				<div className="">
					<p className="my-4 text-xl font-semibold">
						Organisation of Umuganda
					</p>
					<p className="my-4">
						The Ministry of Local Government (MINALOC) is the central government
						authority responsible for Umuganda activities, but different ministries
						are also involved.
					</p>
					<p className="my-4 text-xl font-semibold">
						Coordination and Stakeholders
					</p>
					<p className="my-4">
						The relevant stakeholders to Umuganda include: the Umuganda Supervising
						Committees, local leaders and the communities themselves.
					</p>
					<p className="text-xl font-semibold">Umuganda Supervising Committees</p>
					<p className="my-4">
						There are Umuganda committees at the village, cell, sector, district,
						provincial and national levels, and the structure is shown in the table below.
						The umudugudu (village) level is responsible for building cooperation,
						collaboration and community solidarity, and all activities start at this
						level.
					</p>
					<p className="my-4">
						The role of the Umuganda Supervising Committees is to plan the community
						work, organise and supervise the community work activity, and carry out
						the evaluation of community work as well as make reports indicating the
						monetary value of the work. These committees also work with communities
						and jointly decide which activities are to be undertaken, notifying the
						relevant community seven days before the next Umuganda is about to take
						place.
					</p>
					<p className="mt-4 font-bold text-primary"> Umuganda supervising committee structure</p>
					<div className="my-4">
						<table className="w-full text-sm text-right border border-collapse border-gray-400 hgs-table">
							<tr className="font-semibold text-center uppercase bg-hgsBgLight" rowSpan="2">
								<td className="w-1/2">Steering Committee</td>
								<td>Technical Committee</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight" >
								<td colSpan="2">At the national level</td>
							</tr>
							<tr className="text-left">
								<td>
									The role of the steering committee is to plan, assess
									and encourage members of the public to participate.
									This committee also publicises the results of Umuganda.
								</td>
								<td>
									The technical committee is responsible for coordinating,
									implementing, evaluating and developing umuganda programmes.
								</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight">
								<td colSpan="2">At the province and City of Kigali level</td>
							</tr>
							<tr className="text-left">
								<td>The Steering Committee at this level coordinates Umuganda
									activities that take place at the district level, evaluates
									and reports to the Supervising Committee at the national
									level, and encourages citizens to take part in community work.
								</td>
								<td>
									The Technical Committee analyses reports from the relevant
									districts and provides a summary to MINALOC.
								</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight">
								<td colSpan="2">At the district level</td>
							</tr>
							<tr className="text-left">
								<td></td>
								<td>
									Technical Committees prepare and supervise Umuganda, evaluate
									it and report to the Supervising Committee at the provincial
									or City of Kigali level.
								</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight">
								<td colSpan="2">
									Technical Committees also exist at the sector, cell and village level.
								</td>
							</tr>
						</table>
					</div>
					<p className="my-4">
						The Supervising Committee at the level at which the work was carried out must submit
						a report after the work has been completed. This report needs to indicate the monetary
						value of the work undertaken.
					</p>
					<p className="my-4">
						The Community Work Supervising Committee at the national level is required to submit a
						report to the Prime Minister every six months. Umuganda reports, regardless of the level at which
						they are produced, must include the number of people who participated in the work,
						the specific activities undertaken as well as their monetary value.
					</p>
					<div className="grid gap-4 my-4 mt-4 md:grid-cols-2">
						<div className="">
							<img src={Umuganda2} alt="" />
						</div>
						<div className="">
							<img src={image037} alt="" />
						</div>
					</div>
					<p className="my-4 text-xl font-semibold">
						Local Leaders
					</p>
					<p className="my-4">
						The role of local leaders is to promote participation in Umuganda, set up maintenance
						mechanisms for activities achieved, promote the active involvement of women and young
						people, and strengthen Umuganda committees.
					</p>
					<p className="my-4 text-xl font-semibold">
						The community
					</p>
					<p className="my-4">
						Finally, the role of the community is to take ownership of planning and organizing
						Umuganda, to conduct the works and take ownership of activities achieved so that
						they can be sustained.
					</p>
					<p className="my-4">
						Those who are not able to participate for professional or personal reasons are
						obliged to inform the committee, which then passes this information to the relevant
						community. If community members do not participate when they are able to, the
						Supervising Committee may fine them. Any revenue from fines must be used to support
						community work. However, before a fine can be given, the committee must first talk
						to the person and encourage him or her to participate.
					</p>
					<p className="my-4">
						In 2009, the National Umuganda Competition was introduced to encourage proper
						reporting and to promote participation in Umuganda at all levels of Rwandan society.
						At the district level the best activity is awarded a certificate and funding for
						future projects. The best three projects in each province are awarded prizes. The best
						three projects from across Rwanda are awarded a cash prize.
					</p>
					<div className="my-6">
						<div className="">
							<img src={image007} alt="" />
						</div>
					</div>

				</div>
				<Link
					to="/hgs/umuganda/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Impact &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/umuganda/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default UmugandaHowItWorks;
