import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import PageName from "../components/PageName";
import infographics039 from "../assets/images/cp-infographics/cp-infographics039.png";
import infographics037 from "../assets/images/cp-infographics/cp-infographics037.png";
import Gender1 from "../assets/images/Gender-Equality1.jpg"
export class CPGender extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Country Profile"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Economy &nbsp; <HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/economy"
				/>
				<p className="text-3xl font-semibold">
					Gender equality and women empowerment
				</p>
				<div className="my-4 ">
					<img src={Gender1} className="w-full" alt="" />
				</div>
				<div>
					<div>
						<p className="my-4">
							Since the first elections of female council members at all levels
							of local government culminating in the Chamber of Deputies in
							1996, Rwanda has put in place a dense ecosystem of laws, policies,
							institutions and programs to spur gender equality and empowerment
							of women.
						</p>
						<p className="my-4">
							The most important of these institutions include:
						</p>
						<ul className="ml-8 overview-list">
							<li>
								<span className="font-semibold">
									Ministry of Gender and Family Promotion:{" "}
								</span>
								This is the central government institution that is mandated to
								ensure the strategic coordination of policy implementation in
								issues pertaining to gender, family, women's empowerment and
								children. It plays a leading role in the implementation of the
								gender equality agenda.
							</li>
							<li>
								<span className="font-semibold">
									Rwanda Women Parliamentary Forum:{" "}
								</span>
								This was set up in 1996 by women of the National Transitional
								Assembly, irrespective of their political leanings. All women
								who are Parliamentarians, Senators and Deputies are members of
								the Forum.
							</li>
							<li>
								<span className="font-semibold">
									National Women’s Council:{" "}
								</span>
								The Mission of the National Women's Council is to build women's
								capacity and ensure their participation in national development
								through advocacy and social mobilisation. The council is
								composed of all women and girls above the age of 18 and its
								structures begin at the Village level. There is an executive
								committee of 7 people at each administrative level.
							</li>
							<li>
								<span className="font-semibold">
									The Gender Monitoring Office:{" "}
								</span>
								The role of this institution is to monitor progress towards
								gender equality by developing gender-specific performance
								indicators and a comprehensive monitoring and evaluation system.
							</li>
							<li>
								<span className="font-semibold">National Gender Cluster: </span>
								This forum brings together the Government of Rwanda, development
								partners, the private sector and civil society to discuss
								planning, coordination and prioritisation of gender equality
								interventions. It facilitates collaboration among the different
								actors and coordination of all interventions through the sharing
								of experiences, generation of new ideas, identification of gaps,
								lobbying and advocacy for gender equity and equality in all
								sectors.
							</li>
							<li>
								<span className="font-semibold">Gender Focal Points: </span>
								At the operational level, the main responsibility for ensuring
								the effective implementation of the National Gender Policy rests
								with individual government departments at national and district
								levels. All departments are required to appoint directors of
								planning as the new gender focal points.
							</li>
							<li>
								<span className="font-semibold">Isange One Stop Centre: </span>
								This institution is Rwanda’s most important response to the
								challenge of gender-based violence. It was first established in
								2008 in Kigali and there are now 44 operational centres across
								the country. The Centre is a multi-sectoral and
								interdisciplinary initiative bringing together the Ministry of
								Health, Rwanda National Police, the Ministry of Justice, under
								the coordination of the Ministry of Gender and Family Promotion.
								It provides psychosocial, medical, police and legal services to
								both adult and child survivors of gender-based violence and
								child abuse.
							</li>
						</ul>
						<p className="my-4">
							In a quarter of a century, Rwandan women have considerably
							strengthened their place in society while making tangible social
							progress.
						</p>
					</div>
					<div className="my-4">
						<p className="font-semibold">Land ownership</p>
						<div
							className="border-b-4 border-fifth"
							style={{ height: "24rem" }}
						>
							<img src={infographics037} alt="" className="h-full" />
						</div>
						<p className="font-semibold text-right text-fifth">
							RNRA, Administrative Data, 2019
						</p>
					</div>
					<div className="my-4">
						<p className="font-semibold">
							The social conditions of Rwandan women in the global and African
							context
						</p>
						<div className="border-b-4 border-fifth">
							<img src={infographics039} alt="" />
						</div>
						<p className="font-semibold text-right text-fifth">
							McKinsey Global Institute: The power of parity: Advancing women’s
							equality in Africa, 2019.
						</p>
					</div>
				</div>

				<div>
					<Link
						to="/country/economy"
						className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-fifth text-primary"
					>
						Economy &nbsp; <HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/country/institutionalisation"
						className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Institutionalisation of good
						governance
					</Link>
				</div>
			</div>
		);
	}
}

export default CPGender;
