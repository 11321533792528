import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import infographics002 from "../assets/images/hgs-infographics/hgs-infographics002.png";
import Infographic_16 from "../assets/images/hgs-infographics/Infographic_16.jpg";
import infographics004 from "../assets/images/hgs-infographics/hgs-infographics004.png";

export class AbunziPerformance extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">
					Evaluation of the impact of Abunzi
				</p>
				<div className="my-4">
					<p className="my-4">
						In 2018, the Rwanda Governance Board (RGB) conducted a survey of
						public perceptions of Abunzi. Respondents highlighted the following
						positive attributes of Abunzi in relation to ordinary courts:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>A reduction in time spent to settle cases (86.7%)</li>
						<li>A reduction in the cost of cases (84.2%)</li>
						<li>
							The ability of Abunzi to mitigate conflicts between litigants
							(80.1%)
						</li>
					</ul>
					<p className="my-4">
						The figures below reflect the overall level of citizen satisfaction
						with the performance of Abunzi committees, showing an increase of about
						19% between 2012 and 2018.
					</p>
					<p className="my-8 font-semibold text-primary">
						Overall citizen satisfaction level in the performance of Abunzi
					</p>
					<div className="my-4">
						<div className="w-full border-b-4 border-secondary" style={{}}>
							<img src={infographics002} alt="" className="w-full" />
						</div>
						<p className="font-semibold text-right text-secondary">
							Source: Rwanda Governance Board report, 2018
						</p>
					</div>
					<div className="my-4">
						<div className="w-full border-b-4 border-secondary" style={{}}>
							<img src={Infographic_16} alt="" className="w-full" />
						</div>
						<p className="font-semibold text-right text-secondary">
							Source: Rwanda Governance Board report, 2018
						</p>
					</div>
					<div>
						<p className="my-4">
							The figure above shows that citizens were highly satisfied with
							the impact of Abunzi on a range of issues, including their impact
							on enhancing social cohesion (96.9% in 2018), on solving
							communities’ own problems (96.9% in 2018), on reducing expenses
							compared to ordinary courts (96.6% in 2018), on reducing time
							spent in cases in ordinary courts (96.4% in 2018) and on
							reconciling people who were in conflict (95.2% in 2018). The
							numbers also show a small increase in satisfaction between 2017
							and 2018.
						</p>
					</div>

					<p>A participant in a group discussion in Karongi District stated:</p>
					<div className="flex flex-row items-start my-4">
						<div className="-mt-4 text-5xl text-secondary">&ldquo;</div>
						<div>
							<p className="font-semibold">
								Cases examined by Abunzi used to be taken to ordinary courts.
								A case could take two years or more and people were impoverished
								due to travelling to Rusizi [in former Cyangugu prefecture] to
								have our cases examined there. We would spend all the little
								money we had. Sometimes we used to sell our cattle or pieces of
								land to get money to spend in courts. We have no words to
								express our excitement about Abunzi. This is a justice of
								proximity and free of charge
								<strong className="text-secondary">&rdquo;</strong>.
							</p>
						</div>
					</div>
					<div className="w-full" style={{}}>
						<img src={infographics004} alt="" className="w-full" />
					</div>
					<p className="my-4">
						The cost relative to ordinary courts is widely considered a key advantage
						of the Abunzi system. For example, the average cost of each case settled
						by community Abunzi was RWF 20,700 or around USD 32 as of February
						2013. However, the cost per case in ordinary courts was RWF 280,738 or USD 446.
					</p>
				</div>
				<Link
					to="/hgs/abunzi/practices"
					className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Beneficial practices &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/abunzi/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; How does it work?
				</Link>
			</div>
		);
	}
}

export default AbunziPerformance;
