import React, { Component } from "react";
import CPLeftImage from "./CPLeftImage";
import PageName from "./PageName";
import image051 from "../assets/images/hgs-content-image051.jpeg";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

export class How extends Component {
	render() {
		return (
			<div className="flex flex-col md:h-screen w-full relative text-justify">
				<div className="sm:hidden">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid md:grid-cols-2">
					<div className="sm:h-auto h-full relative">
						<div className="hidden md:flex">
							<CPLeftImage image={image051} />
						</div>
						<div className="flex flex-col md:hidden border-b-8 border-secondary mt-20">
							<div className="flex flex-row items-center uppercase text-xs px-4 py-2">
								<div>
									<Link to="/" className="text-secondary font-semibold">
										Home
									</Link>
								</div>
								&nbsp;
								<MdKeyboardArrowRight className="text-xl" />
								&nbsp;
								<div className="font-semibold">How did HGI emerge?</div>
							</div>
							<img src={image051} alt="" />
						</div>
						<div className="md:absolute top-0 w-full md:h-screen md:bg-primary md:bg-opacity-75 flex flex-col p-4 lg:px-24 md:px-8 md:justify-center">
							<p className="my-4 font-semibold text-2xl text-left">
								How did HGI emerge?
							</p>
							<p className="md:font-bold text-sm lg:text-base">
								The foundation of Rwanda's HGI is the enduring attachment that
								Rwandans have for their traditional culture and the language
								they share, Kinyarwanda.
							</p>
						</div>
					</div>
					<div className="h-full flex flex-col md:pt-20 px-4 xl:px-24 md:px-8 lg:pr-24 md:pr-16 overflow-x-auto text-sm lg:text-base">
						<p className="md:my-4 font-semibold text-2xl text-left">Culture</p>
						<p>
							The foundation of Rwanda's HGI is the enduring attachment that
							Rwandans have for their traditional culture and the language
							they share, Kinyarwanda. Colonial and postcolonial modernisation
							have not weakened this attachment, not even among the elite. All
							Rwandans use Kinyarwanda as the main language of communication in
							their private life and the language has always played an important
							role in public administration. Moreover, young people, artists
							and singers continue to use Kinyarwanda as their first language
							of expression.
						</p>
						<p className="my-4">
							Outside Rwanda, during their thirty-five years of exile, the hundreds
							of thousands of refugees scattered throughout the region and the rest
							of the world maintained an attachment to their traditional culture.
							The mobilisation of the second generation of refugees who had been
							born or raised outside Rwanda, to return to their country, was also
							motivated by the desire to preserve their cultural identity threatened
							by a prolonged exile.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default How;
