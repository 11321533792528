import React, { Component } from "react";
import HomeIntro from "../components/HomeIntro";
import HomeCountryProfile from "../components/HomeCountryProfile";
import HomeHGS from "../components/HomeHGS";
import HomeElearning from "../components/HomeElearning";
import HomeContactUs from "../components/HomeContactUs";
import Subscribe from "../components/Subscribe";
import SocialMediaLinks2 from "../components/SocialMediaLinks2"
import HomeTopMenu from "../components/HomeTopMenu";
import SocialMediaLinks from "../components/SocialMediaLinks";

export class Home extends Component {
	render() {
		return (
			<div className="home-container">
				<div
					className="absolute right-0 z-50 text-white menu-left"
					style={{ padding: "1rem 2rem" }}
				>
					<HomeTopMenu />
				</div>
				<Subscribe className="z-50" />
				<section id="home-1" className="h-screen overflow-y-hidden">
					<HomeIntro />
				</section>
				<section id="home-2" className="h-screen overflow-y-hidden">
					<div
						className="absolute right-0 z-50 text-white menu-left"
						style={{ padding: "1rem 2rem" }}
					>
						<HomeTopMenu />
					</div>
					<HomeCountryProfile />
				</section>
				<section id="home-3" className="h-screen overflow-y-hidden">
					<div
						className="absolute right-0 z-50 text-white menu-left"
						style={{ padding: "1rem 2rem" }}
					>
						<HomeTopMenu />
					</div>
					<HomeHGS />
				</section>
				<section id="home-4" className="h-screen overflow-y-hidden">
					<div
						className="absolute right-0 z-50 text-white menu-left"
						style={{ padding: "1rem 2rem" }}
					>
						<HomeTopMenu />
					</div>
					<HomeElearning />
				</section>
				<section id="home-5" className="h-screen overflow-y-hidden">
					<div
						className="absolute right-0 z-50 text-white menu-left"
						style={{ padding: "1rem 2rem" }}
					>
						<HomeTopMenu />
					</div>
					<HomeContactUs />
				</section>
				<div
					className="fixed bottom-0 right-0 z-50 text-white menu-left social-media"
					style={{ padding: "3rem 2rem" }}
				>
					<SocialMediaLinks />
				</div>
				<section id="links">
					<SocialMediaLinks2 />
				</section>
			</div>
		);
	}
}

export default Home;
