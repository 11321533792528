import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Girinka2 from "../assets/images/Girinka2.jpg"

export class GirinkaHowItWorks extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">How Girinka works</p>
				<div className="my-4 ">
					<img src={Girinka2} className="w-full" alt="" />
				</div>
				<div className="my-6">
					<p className="my-4">The programme is structured in two phases:</p>
					<ol className="flex flex-col gap-4 m-4 ml-12 hgs-ordered-list">
						<li>
							A community member who would greatly benefit from owning a cow is
							identified, and is given a pregnant dairy cow. That person
							benefits from the cow’s milk and manure production.{" "}
						</li>
						<li>
							The beneficiary is then obliged to give the first born female calf
							to another worthy beneficiary in their community. This is known as
							the ‘pass on’ principle, or Kuziturirana/Kwitura.
						</li>
					</ol>
					<p className="my-4">
						Girinka has been described as a culturally-inspired form of social safety 
						net because of the way it introduces a productive asset (a dairy cow) 
						which can provide long-term benefits to the recipient. The Government of 
						Rwanda was initially the sole funder of the Girinka programme, but 
						development partners have since become involved.
					</p>
					<p className="my-4 font-semibold">
						Institutional arrangement of Girinka
					</p>
					<p className="my-4">
						Girinka has a focus on a decentralised approach which means that
						each of Rwanda’s 30 districts is responsible for implementing much
						of the programme. However, RAB, an agency of the Ministry of
						Agriculture, is responsible for coordinating the activities of
						Girinka at the national level.
					</p>
					<p className="my-4">
						Each of Rwanda’s districts has a RAB focal person who liaises with
						sector and district veterinary officers, selection officers, local
						administration and the Girinka programme coordinator. They are also
						involved in local level implementation, including distribution of
						cows, beneficiary training and informing farmers of the benefits of
						working with milk collection centres and milk processing factories.
						Monitoring and evaluation is done in partnership with local
						government units such as the umurenge (sector), akagali (cell) and
						umudugudu (village).
					</p>
					<p className="my-4">
						Before and after a beneficiary receives a cow either directly or via
						the ‘pass on’ principle, RAB district and sector veterinary officers
						provide support in primary animal care through training, veterinary
						services, artificial insemination, fodder seeds and plant materials
						for animal feeds as well as vaccination against diseases. The aim of
						this aspect of the programme is to equip families with the animal
						husbandry skills to care for the cow themselves.
					</p>
					<p className="my-4 font-semibold">
						How beneficiaries can receive cows
					</p>
					<p className="my-4">
						The two ways that a beneficiary can receive a cow are:
					</p>
					<p className="my-4">
						Girinka y’ingabirano (donation): A poor family receives a heifer by
						way of donation. When a cow gives birth, the first female calf is
						given to a neighbour or community member. Once that cow gives birth,
						its first female calf is given to the next selected beneficiary and
						so on. This ‘pass on’ practice is called kuziturirana. If a male
						calf is born, the beneficiary may sell it and use the funds to buy a
						female calf to pass on.
					</p>
					<p className="my-4">
						Girinka y’inguzanyo (loan): A bank loan is given to a family to buy
						a cow. The beneficiary must meet certain criteria including being
						able to care for the animal, construct a cow shed and own sufficient
						land to support the cow.
					</p>
					<p className="my-4 font-semibold">Funding</p>
					<p className="my-4">
						Funding is provided by the Government of Rwanda, non-governmental
						organizations and private citizens. For example, under the Dairy
						Cattle Development Support Project (PADEBL), the African Development
						Fund contributed 8,200 local breed cattle and 3,000 crossbred cattle
						to Girinka.{" "}
					</p>
					<p className="my-4">
						Once the programme budget has been allocated in the overall national
						budget, or donations have been received, RAB coordinates the process
						of purchasing heifers. Efforts have been made to decentralise the
						programme and allocate the bulk of the programme budget to local
						government units.
					</p>
					<p className="my-4">
						Private donations are processed through RAB and are governed by the
						Ministerial Decentralisation Guidelines. In these cases, donors can
						set their own criteria for receiving the heifer, but RAB must
						certify that cows are healthy before they can be given to
						beneficiaries.
					</p>
					<p className="my-4 font-semibold">Selection of cows</p>
					<p className="my-4">
						Cows are purchased through an open tender process managed by RAB at
						the national level and by local government at the district level.
						Before a cow can be purchased from a supplier or accepted as a
						donation, RAB first conducts a series of veterinary medical
						examinations to ensure the cow is:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li>
							Healthy and free from contagious bovine pleuropneumonia and
							brucellosis
						</li>
						<li>Physically sound</li>
						<li>Between 18-24 months old and weighs at least 250kgs</li>
						<li>Pregnant or at the age of insemination</li>
						<li>
							At least 50% of the cow’s genetic makeup must be from a non-local
							breed such as a Friesian or Jersey
						</li>
					</ul>
					<p className="my-4">
						Priority is given to purchasing cows from Girinka beneficiaries
						whose original cows have calved more than once and have thus
						implemented the ‘pass on’ principle.
					</p>
					<p className="my-4 font-semibold">Selection of beneficiaries</p>
					<p className="my-4">
						In accordance with the principles of the Ubudehe programme, the
						local community meets to decide which members should benefit from
						Girinka (and other social security programmes).
					</p>
					<p className="my-4">
						To qualify as a beneficiary of Girinka, individuals or households 
						must meet the following conditions:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li>The beneficiary must not already own a cow</li>
						<li>The beneficiary must have constructed a cow shed</li>
						<li>
							The beneficiary must have between 0.25 – 0.75 hectares of land,
							some of which must be planted with fodder
						</li>
						<li>
							The beneficiary must be an Inyangamugayo (person of integrity) in
							the community
						</li>
						<li>
							The beneficiary must be considered poor by the community and must
							have no other source of income
						</li>
					</ul>
					<p className="my-4">
						Beneficiaries who do not have enough land individually may join with
						others in the community to build a common cow shed (ibikumba) for
						their cows.
					</p>
					<p className="my-4 font-semibold">
						Preparation and training of beneficiaries
					</p>
					<p className="my-4">
						Before beneficiaries receive a heifer, they undergo a training
						programme run by RAB. The training includes a workshop covering the
						five pillars of involvement in the programme and beneficiaries are
						provided with supplies (including drugs, spray pumps and mineral
						blocks) to assist them in caring for their cow. Selection officers,
						sector and district veterinary officers and local-level focal people
						are also involved in the training programme. Regular training and
						support to a beneficiary continues until their cow calves.
					</p>
					<p className="my-4">
						The training given to the beneficiaries is based on the following
						five pillars:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li>Breeding</li>
						<li>Nutrition</li>
						<li>Housing</li>
						<li>Health</li>
						<li>Management and record keeping</li>
					</ul>
					<p className="my-4 font-semibold">
						Distribution of cows to beneficiaries
					</p>
					<p className="my-4">
						Leaders at the sector level are responsible for assessing how many
						cows can be distributed given the available budget and the number of
						Girinka cows likely to calve in that community. Once they have
						finalised the beneficiaries for the year, and beneficiaries have
						completed the training and constructed a cowshed, delivery is
						facilitated by RAB.{" "}
					</p>
				</div>
				<Link
					to="/hgs/girinka/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Impact of Girinka &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/girinka/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default GirinkaHowItWorks;
