import React, { Component } from "react";

export class CPLeftImage extends Component {
	render() {
		const { image, bgPosition } = this.props;
		return (
			<div
				className={`absolute flex-row h-screen w-full bg-cover bg-no-repeat ${bgPosition ? `bg-${bgPosition}` : 'bg-center'}`}
				style={{ backgroundImage: `url(${image})` }}
			>
				{/* <img src={image} alt="" className="h-full w-full" /> */}
			</div>
		);
	}
}

export default CPLeftImage;
