import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Umushyikirano3 from "../assets/images/Umushyikirano3.jpg";
import Infographic_15 from "../assets/images/hgs-infographics/Infographic_15.jpg"
import Infographic_16 from "../assets/images/hgs-infographics/infograph-19.png";
import Infographic_17 from "../assets/images/hgs-infographics/info-20.png";
import Infographic_18 from "../assets/images/hgs-infographics/info-21.png";
import Infographic_13 from "../assets/images/hgs-infographics/Infographic_13.jpg";
import Infographic_10 from "../assets/images/hgs-infographics/Infographic_10.jpg";
import Infographic_14 from "../assets/images/hgs-infographics/Infographic_14.jpg";

export class UmushyikiranoImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Impact</p>
				<div className="">
					<p className="my-4">
						The main achievements of <span className="italic">Umushyikirano</span> are
						the participation of Rwandans in national decision making and the fast-tracking
						of government programmes and citizen priorities based on the resolutions tabled
						each year. <span className="italic">Umushyikirano</span> also plays an important
						role in bonding Rwandans living abroad with their country of origin.
					</p>
					<div className="my-6">
						<div className="my-4">
							<img src={Umushyikirano3} alt="" />
						</div>
					</div>
					<div className="my-4">
						<p className="my-6 text-xl font-semibold">
							Results of 17<sup>th</sup> Umushyikirano (19– 20 December 2019)
						</p>
						<p className="my-4">
							The 17<sup>th</sup> National Umushyikirano Council (NUC) held from
							19<sup>th</sup> to 20<sup>th</sup> of December 2019 adopted 12 resolutions which
							were translated into 36 actionable milestones.
						</p>
						<p className="my-4">
							The report on the implementation of the 17<sup>th</sup> Umushyikirano
							resolution provides details of achievements but also dedicates
							a whole section on two milestones lagging behind.
						</p>
					</div>
					{/* <div className="my-4">
						<p>To designers: Change the title and data values of this graph:</p>
						<p>New title: 2019 National Umushyikirano Council Report</p>
						<p>New values: on track: 78%/ On-watch: 19%/ Off-track: 3%</p>
					</div> */}
					<div className="flex justify-center">
						<img src={Infographic_15} alt="" />
					</div>
					<div>
						<p className="text-lg font-bold">KEY ACHIEVEMENTS ON THE 12 RESOLUTIONS OF 17<sup>th</sup> NATIONAL UMUSHYIKIRANO COUNCIL</p>
						<div className="my-4">
							<p className="font-semibold">Relocation of people living in wetlands, high risk zones</p>
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>
									7,541 households across the country were relocated from High risks zones and wetlands. Their
									resettlement is ongoing.
								</li>
							</ul>
						</div>
						<div className="my-4">
							<p className="font-semibold">Promoting Local Industries</p>
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>
									116 Made in Rwanda products received the S-Mark certification from the
									Rwanda Standards Board.
								</li>
								<li>
									64 locally made products were certified with the Made in Rwanda logo.
								</li>
							</ul>
						</div>
						<div className="my-4">
							<p className="font-semibold">Strengthening agro-processing factories (availing raw materials)</p>
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>
									5,603 MT of cassava and 43,293 MT of maize were supplied to Agro
									processing industries.
								</li>
								<li>
									1,146.3MT of pyrethrum were supplied to SOPYRWA the Rwandan main
									pyrethrum producer.
								</li>
								<li>
									9 Milk Collection Centers were upgraded into SMEs.
								</li>
								<li>
									6,970 MT of improved seeds (Maize, Wheat and soybean) and 72,481 MT of
									fertilizers (Urea, NPK, DAP & KCL+ Blends) were distributed to farmers
									to increase agriculture production.
								</li>
							</ul>
						</div>
						<div className="my-4">
							<p className="font-semibold">Health Post Services</p>
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>
									The Health Services Packages review was finalized and disseminated for implementation.
								</li>
								<li>
									The number of health posts increased by 126% from 885 to 1,117 in 2020.
								</li>
								<li>
									Medical visits to health posts increased by 264% from 2019 to 2020.
								</li>
							</ul>
						</div>
						<div className="my-4">
							<p className="font-semibold">
								Channeling Diaspora involvement in Community
								Based Health Insurance & other Government programs
							</p>
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>
									Rwandan diaspora contributed USD 16,000 on Community Based Health Insurance account.
								</li>
								<li>
									In addition, USD 336,363 was mobilized from Diaspora community to
									support social protection interventions during Covid-19 period.
								</li>
								<li>
									Rwandan Diaspora contributed USD 76,790 towards Connect Rwanda used
									to purchase 775 Mara phones distributed to unconnected citizens.
								</li>
								<li>
									20 specialists in Rwandan diaspora, engaged to provide health care
									services and skills transfer in Rwandan Health Facilities.
								</li>
							</ul>
						</div>
						<div className="my-4">
							<p className="font-semibold">
								Construction of schools
							</p>
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>
									Schools funded by GoR: The construction works for 11,501 single
									classrooms completed at 73%, and at 31% for 61 blocks with 488 classrooms.
									17,252 latrines completed account for 62% of the total.
								</li>
								<li>
									Schools funded by World Bank Project Phase 1: The construction
									works for 2,576 single classrooms and 16 blocks of G+1 (128 Classrooms)
									are at 98% and 87.1% respectively; while works for 3,704 latrines are at 98.7%.
								</li>
								{/* <li>
									On school funded by World Bank Project Phase 1: The construction
									works for 2,576 single classrooms completed at 98% and for 16
									blocks with 128 classrooms completed at 87%. 3,704 latrines
									completed are at 98%.
								</li> */}
								<li>
									World Bank Project Phase 2: Works for 7,404 single classrooms completed at 83%
									and 112 blocks with 896 classrooms completed at 40.4%. 11,032 latrines completed at 80%.
								</li>
								<li>6 TVET School construction works reached 56.44% of completion.</li>
							</ul>
						</div>
						<div className="my-4">
							<p className="font-semibold">
								Upgrading the Mobile Network with 4G
							</p>
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>
									The expansion of 127 antenna sites was completed in CoK, Northern,
									Western and Southern provinces.
								</li>
								<li>
									The hardware upgrade 120 radio units for G4 was completed in
									different parts of the country.
								</li>
								<li>
									68 telecom towers were constructed and all are on air as planned and
									operationalized in 22 Districts across 4 provinces.
								</li>
							</ul>
						</div>
					</div>
					<p className="my-4">
						The following statistics reported against the 2017 Umushyikirano resolutions
						demonstrate the impact of Umushyikirano in incentivising and motivating policy
						implementation. On an education-themed resolution, the report stated that a
						total of 1,370 smart classrooms had been built, 922 classrooms had been
						constructed and rehabilitated, and 1,344 toilets had been constructed. The
						report gave a figure of 62,616 teachers trained in the Competency-Based
						Curriculum in 2018 compared with 54,718 in 2017, and stated a total of 4,417
						school-based mentors in 2018 up from 4,160 in 2017. It also reported that 727
						trainers had been trained for skills upgrading against a target of 500 in 2018,
						and 713 students had been trained in Early Childhood Teaching in Teacher Training
						Colleges compared to a target of 600 in the same year. Teachers in primary schools
						had worked two four-hour shifts, but the move to a single shift in the P6 school
						year meant that the school day could increase from four to six hours for P6
						students. The 2017 Umushyikirano report stated that the school dropout rate had
						decreased from 11% in 2012 to 5.6% in 2016 and that 55,533 students returned to
						school in 2017. Finally, on the education theme, 20 out of a target of 30 STEM
						programmes were accredited in 2018.
					</p>
					<div className="my-4">
						<img src={Infographic_16} alt="" className="w-2/3" />
					</div>
					<div className="flex justify-end my-8 mr-16">
						<img src={Infographic_17} alt="" className="w-2/3" />
					</div>
					<div className="my-4">
						<img src={Infographic_18} alt="" className="w-2/3" />
					</div>
					<p>
						On a <span className="font-bold">health</span>-themed resolution, 409 medical
						doctors were reported as “being supported in specialisation”, 260,856 people
						were screened for Hepatitis B and C, and 145,303 citizens were “treated
						during the outreach”. On another resolution on <span className="font-bold">
							fighting drug abuse</span> as well as illicit trafficking, the sentence period
						for these crimes was increased from 2-5 years to 7-25 years, and 4,123
						“delinquents” were rehabilitated, trained and equipped with hands-on skills.
						On the <span className="font-bold">Early Childhood Programme, nutrition and hygiene
						</span>  resolution, the report stated that 74,288 children and 13,111 women
						in category 1 received Fortified Blended Foods.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_13} alt="" />
					</div>
					<p>
						On the resolution to increase <span className="font-bold">access to affordable
							electricity</span>, the report found that 138,290 households were connected to
						the grid in 2018 compared to a target of 115,979; whereas 61,546 households were
						connected off-grid against a higher target of 91,142. Two hundred and eighteen
						megawatts of electricity-generating capacity were installed.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_10} alt="" />
					</div>
					{/* <p>
						On the <span className="font-bold">promotion of the Kinyarwanda language</span>, the
						report stated that 18 international schools included Kinyarwanda in their curriculum.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_12} alt="" />
					</div> */}
					<p>
						On the <span className="font-bold">National Service programme and the Itorero programme</span>, the
						report added that 55,270 Intore were trained and deployed for national service, 4,503
						schools now had Itorero and 10,526 villages had Itorero; and the Intore mu Biruhuko
						Programme was created.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_14} alt="" />
					</div>
					{/*<div className="px-8 py-4 my-6 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							DISCUSSION TOPIC 1: National Strategy for Transformation
						</p>
						<p className="my-4">
							This strategy reinforces Rwandan’s choices over the last decade,
							builds on lessons learnt and anticipates future challenges. The
							plan will broaden and deepen the process of transformation by
							elevating the quality of life for Rwandans. This session will
							present a snapshot of where Rwanda is headed over the next several
							years. There will be in-depth discussions on shared goals and
							ambitions including reflection of programs such as universal
							access to quality healthcare, improved nutrition, education,
							employment, sustained security, modern housing and settlements.
						</p>
					</div>
					<div className="px-8 py-4 my-6 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							DISCUSSION TOPIC 2: Values for prosperity
						</p>
						<p className="my-4">
							The National Strategy for Transformation hinges on values that
							promote economic and social progress, including self-reliance and
							self-determination, dignity, unity and Rwandan identity, community
							participation, transparency and openness, as well as innovation
							and national stability. Also needed is a common understanding of
							what is required to maximize early human development efforts as
							anchored in the Early Childhood Development Program. This includes
							values that promote issues such as hygiene and nutrition.
							Umushyikirano.
						</p>
						<p className="italic font-semibold text-secondary">
							#Umushyikirano2017
						</p>
					</div>
					<div className="px-8 py-4 my-6 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							DISCUSSION TOPIC 3: Youth at the center of transformation
						</p>
						<p className="my-4">
							Young Rwandans between 16-30 years old represent 27% of the
							population while those between 0-15 years represent 42%. Rwanda’s
							youth are tomorrow’s entrepreneurs, active citizens and leaders.
							Equipping and engaging young people effectively to accelerate
							growth and create prosperity is key to achieving transformation.
						</p>
						<p className="my-4">
							The economic contribution of young Rwandans will depend on the
							skills they possess to compete in the labor market and participate
							in Rwanda’s transformational agenda. For this to happen, young
							people must be able to access quality education and employment
							opportunities.
						</p>
						<p className="my-4">
							Patriotism and active involvement in nation building, including
							political awareness and participation, are critical elements in
							nurturing the mindset and shared vision for transformation, and
							safeguarding the gains Rwanda has registered in recent years.
						</p>
						<p className="my-4">
							Young people will lead this panel discussion. Expected outcomes
							include; common understanding of young people’s skills and
							capacities to actively participate in leadership roles, awareness
							of interventions required to create jobs and drive youth
							employment.
						</p>
					</div>*/}
				</div>
				<Link
					to="/hgs/umushyikirano/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; How does Umushyikirano work?
				</Link>
			</div>
		);
	}
}

export default UmushyikiranoImpact;
