import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Menu from "./components/Menu";
import Home from "./pages/Home";
import CountryProfile from "./pages/CountryProfile";
import HomeGrownSolutions from "./pages/HomeGrownSolutions";
import NotFound from "./components/NotFound";
import ScrollToTop from './components/ScrollToTop'

export class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<ScrollToTop>
					<Menu />
					<Switch>
						<Route exact path="/" component={Home} />
						<Route path="/country" component={CountryProfile} />
						<Route path="/hgs" component={HomeGrownSolutions} />
						<Route component={NotFound} />
					</Switch>
				</ScrollToTop>
			</BrowserRouter>
		);
	}
}

export default App;
