import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics009.png";
import infographics036 from "../assets/images/cp-infographics/cp-infographics036.png";

export class Economy2 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="my-4">
					<p>
						Since 2003 Rwanda’s GNI per capita has quadrupled, breaking with the
						extreme poverty that the country experienced historically, as shown
						in the figure below.
					</p>
					<div className="my-4">
						<img src={info1} alt="" className="w-full" />
					</div>
				</div>
				<div className="my-4">
					<p className="my-4 font-semibold">Sectoral development</p>
					<p>
						For the last two decades, the Rwandan economy diversified,
						significantly reducing the share of agriculture and increasing the
						share of the service sector. According to the World Bank, economic
						growth averaged 7.5% over the decade to 2018 and was the second
						fastest growth rate in Africa during this period; at the same time
						gross domestic product grew at 5% annually.
					</p>
					<div style={{ height: "" }} className="flex justify-center">
						<img src={infographics036} alt="" className="" style={{	height: "32rem"}}/>
					</div>
				</div>
			</div>
		);
	}
}

export default Economy2;
