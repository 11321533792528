import React, { Component } from "react";
import PageName from "./PageName";
import What3 from "../assets/images/What3.jpg";

export class WhatMakes3 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>
				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full md:text-sm">
						<p className="font-semibold lg:text-2xl md:text-2xl">
							Accountability
						</p>
						<p className="my-4">
							Accountability mechanisms have further enhanced the effectiveness
							of HGI. Whilst actions taken by local government are subject to
							oversight by classical accountability institutions such as the
							Office of the Auditor General, other accountability mechanisms
							have been established, notably Imihigo and
							<span className="italic"> Umushyikirano</span>, that allow for
							more active involvement of citizens in line with the philosophy
							of HGI.
						</p>
						<p className="my-4">
							<span className="italic">Umushyikirano</span>
							, the National Dialogue Council brings together the
							President of the Republic and citizens’ representatives to
							discuss issues relating to the state of the nation and to
							national unity. The National Dialogue is broadcast live on TV,
							radio and social media. Citizens are offered toll-free telephone
							lines to call in and express their grievances or use social media
							platforms. These demands are expected to be addressed quickly
							by the relevant institutions of the state. Umushyikirano is a
							very powerful mechanism incentivising local and central government
							officials to perform well and avoid being publicly exposed.
						</p>
					</div>
					<div className="flex items-center h-full xl:pl-20 md:pl-4 ">
						<div className="">
							<img src={What3} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatMakes3;
