import React, { Component } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

export class GirinkaOverview extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Overview</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/0yaUEcgzzmg"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						As part of efforts to reconstruct Rwanda and nurture a shared
						national identity, the Government of Rwanda drew on traditional
						practices in Rwandan culture to adapt its development programmes to
						the country’s needs and context. The result is a set of Home Grown
						Initiatives (HGI), which are culturally owned practices that translate
						into sustainable development programs. One of these is the One Cow
						per Poor Family program, known as Girinka in Kinyarwanda.
					</p>
					<p className="my-4">
						The 2005 Demographic and Health Survey report showed that 45% of
						children under five years of age suffered from moderate chronic
						malnutrition and 19% suffered from severe malnutrition. Almost no
						progress had been made since the previous survey in 2000. In
						addition, the 2005-6 Household Living Conditions Survey showed that
						poverty had actually increased since 2001. These results came as a
						shock, since the country had embarked on a sustained socioeconomic
						development drive launched by the adoption and the launch of Vision
						2020 in 2000 and Rwanda’s Poverty Reduction Strategy Paper in 2002,
						marking the beginning in earnest of its post-genocide socioeconomic
						reconstruction.
					</p>
					<p className="my-4">
						During the December 2006 Umushyikirano (National Dialogue Council)
						retreat, leaders discussed this grim socioeconomic situation,
						deciding to adopt a policy to distribute cows to poor households to
						combat child malnutrition. The scheme was later developed by the
						Rwanda Agriculture Board (RAB) and named Girinka, a term that can be
						loosely translated as “may you have a cow”. It is also a mode of
						greeting in Kinyarwanda in which someone wishes another person to
						prosper. The idea of distributing cows to the poor initially sought
						to respond specifically to the challenge of child malnutrition, but
						it also drew inspiration from the Rwandan traditional practice of
						giving a cow as a sign of friendship, appreciation or as a marriage
						dowry. Naturally, Girinka joined other existing HGI.
					</p>
					<p className="my-4">
						As of June 2020, more than 380,000 families had received a cow under
						the Girinka program, thus benefiting over 1.2 million Rwandans.
						Girinka has also contributed to an increase in agricultural
						production in Rwanda, especially milk products which have helped to
						reduce malnutrition and increase incomes.
					</p>
				</div>
				<Link
					to="/hgs/girinka/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					How does Girinka works? &nbsp;
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default GirinkaOverview
