import React, { Component } from "react";
import inhabitants from "../assets/images/cp-infographics/inhabitants.png";
import square from "../assets/images/cp-infographics/square.png";
import temperature from "../assets/images/cp-infographics/temperature.png";
import age from "../assets/images/cp-infographics/average age.png";
import altitude from "../assets/images/cp-infographics/altitude.png";
import language from "../assets/images/cp-infographics/language.png";

export class Overview2 extends Component {
	render() {
		return (
			<div className="px-4 lg:px-10 xl:px-24">
				<div className="flex flex-col">
					<div className="infographics flex flex-col md:flex-row my-8 h-auto">
						<div className="flex flex-col md:w-2/5">
							<div className="w-full">
								<img className="w-full h-full" src={square} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={inhabitants} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={temperature} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={language} alt="" />
							</div>
						</div>
						<div className="flex-flex-col md:w-2/5 mb-4">
							<div className="w-full min-w-full">
								<img className="w-full h-full" src={altitude} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={age} alt="" />
							</div>
						</div>
					</div>
					{/* <div className="">
						<p>
							Rwanda’s landscapes have earned it the name of the Land of a
							Thousand Hills.
						</p>
						<p className="my-4">
							Modest in size with 26,338 square kilometres, the country is
							densely populated with 12.3 million inhabitants. Seventy percent
							of Rwanda’s population is under 30 years old. Although Rwanda is
							located near the equator, the country enjoys a climate with
							moderate temperatures ranging between 24oC and 27oC, and its
							altitude varies between 1,000 and 4,507 meters above sea level.
						</p>
						<p className="my-4">
							Rwanda is located on the borders of East and Central Africa. It is
							part of both the East African Community and the Economic Community
							of Central African States. It is a landlocked country surrounded
							by Uganda, Tanzania, Democratic Republic of the Congo (DRC) and
							Burundi. It shares Lake Kivu with DRC.
						</p>
						<p className="my-4">
							Almost all Rwandans speak the national language, Kinyarwanda, and
							the country has three other official languages, English, French
							and Swahili. Over the past two decades, the country has
							experienced political and social stability supported by strong
							economic growth. Rwanda is recognized for the quality of its
							governance and the important role played by women in the life of
							the nation.
						</p>
					</div> */}
				</div>
			</div>
		);
	}
}

export default Overview2;
