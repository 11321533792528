import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import CPOverview from "./CPOverview";
import CPHistory from "./CPHistory";
import CPRwToday from "./CPRwToday";
import CPEconomy from "./CPEconomy";
import CPStrategies from "./CPStrategies";
import { cpMenus } from "../utils/menus";
import HGSSideNav from "../components/HGSSideNav";
import CPIntroduction from "./CPIntroduction";
import CPInstitutionalisation from "./CPInstitutionalisation";
import CPGender from "./CPGender";

export class CountryProfile extends Component {
	render() {
		const { match } = this.props;
		return (
			
			<Fragment>
				<div className="flex flex-row h-screen w-full">
					<div className="sm:hidden md:flex w-4/12 bg-cpMenu">
						<div className="w-full">
							<HGSSideNav cpTitle="Country Profile" submenus={cpMenus} />
						</div>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-cpBg relative">
						<Switch>
							<Route exact path={`${match.path}/`} component={CPIntroduction} />
							<Route
								exact
								path={`${match.path}/introduction`}
								component={CPIntroduction}
							/>
							<Route path={`${match.path}/overview`} component={CPOverview} />
							<Route path={`${match.path}/history`} component={CPHistory} />
							<Route path={`${match.path}/today`} component={CPRwToday} />
							<Route path={`${match.path}/institutionalisation`} component={CPInstitutionalisation} />
							<Route path={`${match.path}/gender`} component={CPGender} />
							<Route path={`${match.path}/economy`} component={CPEconomy} />
							<Route
								path={`${match.path}/strategies`}
								component={CPStrategies}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default CountryProfile;
