import React, { Component } from "react";
import { Link } from "react-router-dom";

export class HomeTopMenu extends Component {
	render() {
		return (
			<div className="w-full pr-24 font-semibold sm:none pt4">
				<div className="flex flex-row justify-end">
					<p className="cursor-pointer text-secondary" >ENG</p>
					<p>/</p>
					<a href={"https://fr.rwandapedia.rw" + window.location.pathname} className="cursor-pointer">FR</a>												
				</div>
				<ul className="flex flex-row justify-between text-center list-none md:gap-4">
					<li className="text-sm hover:text-secondary">
						<Link to="/country/introduction">Country Profile</Link>
					</li>
					<li className="text-sm hover:text-secondary">
						<Link to="/hgs/what-are-hgs">Rwanda's Homegrown Initiatives</Link>
					</li>
					<li className="text-sm hover:text-secondary">
						<a href="#home-4">E-learning Premium</a>
					</li>
					<li className="text-sm hover:text-secondary">
						<a href="#home-5">Contact Us</a>
					</li>
				</ul>
			</div>
		);
	}
}

export default HomeTopMenu;
