import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import image009 from "../assets/images/hgs-content-image009.jpeg";

export class UmushyikiranoOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Overview</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/ogHUdQdppd0"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						The word <span className="italic"><span className="italic">Umushyikirano</span></span> translates to a
						meeting in which participants are able to exchange ideas, share experiences
						and ask questions of one another. Today, <span className="italic">Umushyikirano</span> is known as the
						National Dialogue Council and is constitutionally mandated. <span className="italic">Umushyikirano </span>
						is a forum in which participants debate issues relating to the state of
						the nation, the state of local governance and national unity.
					</p>
					<p className="my-4">
						Every year, the President of the Republic of Rwanda chairs <span className="italic">Umushyikirano</span>,
						during which Rwandans from all walks of life have the opportunity to ask
						questions directly to their leaders. The event is attended by members of
						the Cabinet and Parliament, representatives of the Rwandan community
						abroad, local government, media entities, the diplomatic community and
						others invited by the President. Those unable to attend in person at
						Rwanda’s parliament building, can do so via telephone, SMS, Twitter
						and Facebook or can follow the debate live through television and
						radio coverage of the event.
					</p>
					<div className="px-8 py-4 bg-hgsBgLight">
						<p className="my-4">
							The first National Dialogue Council took place on 28 June 2003; the event
							has been held each year ever since. <span className="italic">Umushyikirano </span>
							aims to be a leading example of participatory and inclusive governance.
							It is hoped that by directly engaging with their leaders, Rwandans feel
							part of the decision making that affects their lives. 
							<span className="italic"> Umushyikirano</span> also serves as a forum for
							Rwandans to hold their leaders and government to account.
						</p>
					</div>
				</div>
				<div>
					<div className="">
						<img src={image009} alt="" />
					</div>
					<p className="my-4">
						Over the past 15 years, <span className="italic">Umushyikirano</span> has
						proposed transformational ideas, and made important contributions to
						nation-building; examples include: Umwalimu SACCO (the savings and credit
						cooperative for teachers), Abunzi (the mediation committees) and Girinka
						(the One cow per poor family programme).
					</p>
					<p>
						The Office of the Prime Minister (PMO) is responsible for the overall
						coordination of <span className="italic">Umushyikirano</span>.
					</p>
				</div>
				<Link
					to="/hgs/umushyikirano/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					How does Umushyikirano work? &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UmushyikiranoOverview;
