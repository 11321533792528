import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics014.png";
import info2 from "../assets/images/cp-infographics/cp-infographics015.png";
import info8 from "../assets/images/cp-infographics/cp-infographics016.png";
import info3 from "../assets/images/cp-infographics/cp-infographics007.png";
import info4 from "../assets/images/cp-infographics/cp-infographics017.png";
import info5 from "../assets/images/cp-infographics/cp-infographics018.png";
import info6 from "../assets/images/cp-infographics/cp-infographics019.png";
import info7 from "../assets/images/cp-infographics/cp-infographics020.png";

export class Economy6 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col">
					<p className="font-semibold text-2xl">ICT</p>
					<p className="my-4">
						One of the pillars of Vision 2020 was the use of information and
						communication technology (ICT) as an accelerator of development. In
						2005, under the National Information Communication Infrastructure
						Plan II, optical fibre cable was installed, joining underwater
						internet cables off the shores of Kenya and Tanzania. The cable
						network connects thirty district capitals, and a national data
						centre has been constructed which is intended to facilitate the
						commercial exploitation of ICT.
					</p>
					<p>
						ICT infrastructure development has greatly improved productivity in 
						the country. The ongoing initiative by the government to digitize all 
						G2C (Government to Citizens) and G2B (Government to Business) interactions 
						through a single window platform, Irembo, has already immensely improved 
						public service provision. Rwanda is ranked 1<sup>st</sup> in the East African 
						Community and 5<sup>th</sup> in Africa in Network Readiness Index 2020. The country 
						has 4G LTE network coverage of 95% and 7,000 kilometres of fibre optic 
						cable.
					</p>
					<p className="my-4 mb-4 text-2xl font-semibold">Drivers of growth</p>
					<p className="">
						Important drivers of Rwandan growth have been the robustness of its
						governance, significant infrastructure development and sustained
						improvement in the business climate. The development of the
						country’s infrastructure is guided both by government planning and
						demand from local people. Rwanda is ranked 2<sup>nd</sup> in Africa for the
						Ease of Doing Business index. 73% of national roads are in 
						good condition.
					</p>
					<p className="my-4">Going forward</p>
				</div>
				<div className="flex flex-row flex-wrap gap-4">
					<div className=" pr-8 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info1} alt="" />
					</div>
					<div className=" pr-4 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info2} alt="" />
					</div>
					<div className=" pr-4 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info8} alt="" />
					</div>
					<div className=" pr-4 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info3} alt="" />
					</div>
					<div className=" pr-4 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info4} alt="" />
					</div>
					<div className=" pr-4 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info5} alt="" />
					</div>
					<div className=" pr-4 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info6} alt="" />
					</div>
					<div className=" pr-4 info-border" style={{ height: "6rem" }}>
						<img className="h-full w-auto" src={info7} alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default Economy6;
