import React, { Component } from "react";
import africaBg from "../assets/images/cp-infographics/Designs_Eng-08.png";
// import image003 from "../assets/images/cp-content-image003.jpeg";
// import image011 from "../assets/images/cp-content-image011.jpeg";

export class Overview3 extends Component {
	render() {
		return (
			<div className="">
				{/* <div className="grid md:grid-cols-2 gap-8 my-4">
					<div
						className="w-full bg-no-repeat bg-cover bg-center"
						style={{
							backgroundImage: `url(${image003})`,
							height: "20rem",
						}}
					></div>
					<div
						className="w-full bg-no-repeat bg-cover bg-center"
						style={{
							backgroundImage: `url(${image011})`,
							height: "20rem",
						}}
					></div>
				</div> */}
				<div
					className="flex flex-row h-screen"
				>
					<div className="bg-center bg-contain bg-no-repeat w-4/5" style={{ backgroundImage: 'url(' + africaBg + ')' }}>
						<div className="flex flex-col px-4 lg:px-10 xl:px-24">
							<p className="text-2xl font-semibold">Location:</p>
							<p className="my-4">
								Rwanda is located on the borders of {" "}
								<span className="font-semibold">East and Central Africa. </span>
								It is part of:
							</p>
							<ul className="overview-list ml-8">
								<li>The East African Community</li>
								<li>The Economic Community of Central African States</li>
								<li>It is a landlocked country surrounded by Uganda, Tanzania, DRC and
									Burundi</li>
								<li>It shares Lake Kivu with the DRC</li>
							</ul>

							<p></p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Overview3;
