import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import ItoreroHowItWorks from '../components/ItoreroHowItWorks';
import ItoreroOverview from '../components/ItoreroOverview';
import PageName from '../components/PageName';
import { itoreroSubmenus } from '../utils/menus';

export class Itorero extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Itorero"
							hgsSubTitle="governance and justice"
							hgsIntro={
								<p>
									The Civic Education Program, also known as Itorero, is one of
									Rwanda's Homegrown Initiatives (HGI). Itorero was reintroduced
									in 2009 as a way to rebuild the nation’s social fabric and
									mobilise Rwandans to uphold important cultural values and to
									spur a sense of dedication to their country.
								</p>
							}
							submenus={itoreroSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Ingando &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/ingando/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={ItoreroOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={ItoreroOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={ItoreroHowItWorks}
							/>
							{/* <Route
								exact
								path={`${match.path}/outcomes`}
								component={ItoreroOutcomes}
							/>
							<Route
								exact
								path={`${match.path}/participants`}
								component={ItoreroParticipants}
							/> */}
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Itorero
