import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics013.png";

export class Economy4 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<p className="font-semibold text-2xl">Tourism, MICE and mining</p>
				<p className="my-4">
					Today, tourism and mining are the leading sources of foreign currency
					for Rwanda.
				</p>
				<p >
					Initial efforts focused on developing a niche tourism sector around the
					Volcanoes National Park and its mountain gorillas. Aggressive
					international marketing was deployed, including the innovative Kwita
					Izina ceremony, during which a name is given to infant gorillas born
					during the previous twelve months. Celebrities such as Bill Gates,
					Natalie Portman, Ted Turner, Ne-Yo, Naomi Campbell and Tony Adams have
					participated.
				</p>
				<p className="my-4">
					In 2007, the country started to broaden the scope of its tourism
					industry by expanding into the meetings, incentives, conference and
					exhibitions (MICE) sector. To this end, the government invested in
					strategic infrastructure, reestablishing the national airline RwandAir
					in 2009, completing an ambitious complex of conference rooms known as
					the Kigali Convention Centre in 2016, and beginning the construction
					of Bugesera International Airport. Development of this tourism and
					MICE sector infrastructure attracted additional investment in four
					and five-star hotels managed by major companies such as Radisson Blue
					and the Marriott. Kigali hosted the World Economic Forum in May 2016
					and hosted the African Union summit two months later. Rwanda was rated
					13<sup>th</sup> in Africa in the MICE sector in 2014, but this ranking rose to
					3<sup>rd</sup> in 2016, 2017 and 2018, and to 2<sup>nd</sup> in 2019.
				</p>
				<p>
					Tourism revenue grew from USD 27 million in 2000 to USD 498 million
					in 2019, becoming the country’s primary source of foreign currency. {" "}
				</p>
				<p className="my-4">
					The mining sector was the subject of similar modernizing efforts. In
					2003, mineral exports were USD 11 million, but by 2017 they were worth
					USD 373.4 million and are now the second largest export revenue earner
					in Rwanda.
				</p>
				<div
					style={{
						maxWidth: "30rem",
					}}
				>
					<img src={info1} alt="" className="w-full" />
				</div>
			</div>
		);
	}
}

export default Economy4;
