import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import MobileFooter from "../components/MobileFooter";
// import HomeContactImage from "../assets/images/image76.jpeg";
import homeContact from "../assets/images/homeContact.jpg";
import GoUp from "./GoUp";

export class HomeContactUs extends Component {
	render() {
		return (
			<div className="">
				<section className="w-full h-screen text-white home-section bg-primary sm:hv-8">
					<div
						className="bg-center bg-no-repeat bg-cover home-background"
						style={{
							backgroundImage: `url(${homeContact})`,
						}}
					></div>
					<div className="absolute z-10 w-full h-full home-contact-gradient"></div>
					<div className="flex flex-col h-full py-8 sm:w-full sm:px-4 home-left md:px-8">
						<div className="flex flex-row items-center h-full">
							<div className="pr-20 home-intro-content">
								<div className="flex py-8 home-section-logo lg:w-64 md:w-48">
									<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
								</div>
								<div className="flex flex-col contact-form text-md">
									<div className="max-w-md text-5xl font-bold home-title">
										<h2 className="sm:text-4xl lg:text-5xl">
											Let's get in touch
										</h2>
									</div>
									<form className="flex flex-col max-w-md">
										<input
											type="text"
											className="px-4 py-1 my-1 font-semibold bg-white border border-white bg-opacity-10"
											placeholder="Name"
										/>
										<input
											type="text"
											className="px-4 py-1 my-1 font-semibold bg-white border border-white bg-opacity-10"
											placeholder="Email"
										/>
										<textarea
											className="px-4 py-1 my-1 font-semibold bg-white border border-white bg-opacity-10"
											placeholder="Message"
										/>
										<div className="">
											<button className="w-full my-4 font-bold text-black border-2 border-none sm:btn lg:px-8 md:px-3 lg:py-4 md:py-2 hover:text-white bg-primary hover:bg-secondary">
												Send Message
											</button>
										</div>
									</form>
								</div>
							</div>
							<div className="flex flex-col items-center justify-center w-auto h-full home-scroll-controls">
								<NavigationDots active={5} />
							</div>
						</div>
						<GoUp to="home-5" />
					</div>
				</section>
				<MobileFooter />
			</div>
		);
	}
}

export default HomeContactUs;
