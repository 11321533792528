import React, { Component } from "react";
// import { HiChatAlt } from "react-icons/hi";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

export class SocialMediaLinks extends Component {
	render() {
		return (
			<div className="flex flex-row items-center">
				{/* <div className="rci text-xl font-bold">RCI</div>
                <div className="live-chat flex flex-row items-center mx-10"><HiChatAlt /> Live Chat</div> */}
				<div className="socials flex flex-row items-center font-semibold">
					<a
						href="https://twitter.com/rwandapedia"
						target="_blank"
						rel="noopener noreferrer"
						className="hover:text-secondary mx-2"
					>
						<FaTwitter className="" />
					</a>
					<a
						href="https://www.facebook.com/rwandapedia"
						target="_blank"
						rel="noopener noreferrer"
						className="hover:text-secondary mx-2"
					>
						<FaFacebook className="" />
					</a>
					<a
						href="https://www.instagram.com/rwandapedia"
						target="_blank"
						rel="noopener noreferrer"
						className="hover:text-secondary mx-2"
					>
						<FaInstagram className="" />
					</a>
				</div>
			</div>
		);
	}
}

export default SocialMediaLinks;
