import React, { Component } from "react";
import History1 from "../assets/images/History1.jpg";

export class History extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<div className="flex flex-col my-4">
					<div className="font-bold">
						<p>
							<span className="text-4xl text-fifth"> 1. </span>Before
							colonialism
						</p>
					</div>
					<div className="">
						<p>
							During more than two thousand years of cohabitation, the population 
							which lived on today’s Rwandan territory developed strong cultural 
							communities based on the complementarity of food production, 
							exchange and intermarriage. Their communities were closely held 
							together by a clan system. 
						</p>
						<p className="my-4">
							It is on this cultural and social landscape that, starting from a 
							small principality located to the east of the capital Kigali four 
							centuries ago, Rwanda gradually developed to form a centralised state 
							which reached its peak on the eve of the colonial eruption towards the 
							end of the 19th century.
						</p>
						<p>
							However, in spite of strong mores, at the turn of the 19th century, 
							the increased clearing of land coupled with the growth in population 
							and cattle, caused a hardening of social relations around access to land 
							that were later exploited by colonisers.
						</p>
					</div>
				</div>
				<div className="">
					<img src={History1} className="w-full" alt=""/>
				</div>
			</div>
		);
	}
}

export default History;
