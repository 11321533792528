import React, { Component } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export class HGSAnimatedContainer extends Component {
	render() {
		const { title, subtitle, text, image, to } = this.props;
		return (
			<Fragment>
				<div className="">
					<div className="md:hidden flex flex-col my-2">
						<div className="child-title text-4xl font-semibold text-secondary">
							{title}
						</div>
						<div className="child-subtitle font-semibold uppercase">
							{subtitle}
						</div>
						<div className="flex w-full my-1">
							<img src={image} alt="" className="w-full" />
						</div>
						<div className="child-text">{text}</div>
						<div className="flex my-2">
							<Link
								to={to}
								className="bg-secondary w-max text-white font-semibold py-3 px-8"
							>
								Learn more
							</Link>
						</div>
					</div>
					<Link to={to} className="hidden md:flex">
						<div className="parent flex flex-col relative w-full">
							<div
								className="flex w-full h-full bg-cover bg-no-repeat bg-center absolute"
								style={{ backgroundImage: `url(${image})` }}
							></div>
							<div className="child absolute top-0 left-0 h-full w-full lg:p-4 xl:p-8 md:p-2 z-10 overflow-hidden">
								<div className="child-title xl:text-4xl lg:text-3xl md:text-2xl font-semibold text-secondary">
									{title}
								</div>
								<div className="child-subtitle xl:text-xl md:text-base font-semibold uppercase">
									{subtitle}
								</div>
								<div className="child-text xl:text-base md:text-sm xl:my-2">
									{text}
								</div>
							</div>
						</div>
					</Link>
				</div>
			</Fragment>
		);
	}
}

export default HGSAnimatedContainer;
