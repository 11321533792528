import React, { Component } from "react";
import PageName from "./PageName";
import What2 from "../assets/images/What2.jpg";

export class WhatMakes2 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full md:text-sm">
						<p className="font-semibold lg:text-2xl md:text-2xl">
							Local government
						</p>
						<p className="my-4">
							Local government is one vehicle that allows HGI to fully express
							their potential. Local government underwent a profound reform in
							2006 seeking to improve the effectiveness of service delivery,
							primarily by making administrative and social services more accessible.
						</p>
						<p className="my-4">
							The point of supply for these services was moved from districts to
							sectors, which are lower on the administrative hierarchy; however,
							sectors were expanded from their previous size. A new, more
							accessible, administrative level was added closer to the
							population: the village or <span className="italic">Umudugudu</span>.
						</p>
						<p className="my-4">
							The capacity of sectors and districts was raised by raising the
							professional profiles and remuneration of their managers. Local
							leaders became an essential and indispensable link and intermediaries
							between the administration and the population, largely utilising HGI
							to spur local development.
						</p>
					</div>
					<div className="flex items-center xl:pl-20 md:pl-4">
						<div className="">
							<img src={What2} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatMakes2;
