import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
import { Link } from "react-router-dom";
// import HomeHGSImage from "../assets/images/image5.jpeg";
// import homeHGS from "../assets/images/homeHGS.jpg";
import ContactBg from "../assets/images/Contact-Bg.png"

export class HomeHGS extends Component {
	render() {
		return (
			<section className="w-full h-screen text-white home-section bg-primary">
				<div
					className="bg-top bg-no-repeat bg-cover home-background"
					style={{ backgroundImage: `url(${ContactBg})` }}
				></div>
				<div className="absolute z-10 w-full h-full home-hgs-gradient"></div>
				<div className="flex flex-col h-full pb-8 sm:w-full sm:px-4 home-left md:px-8">
					<div className="flex flex-row items-center h-full">
						<div className="pr-20 home-intro-content">
							<div className="flex py-8 home-section-logo lg:w-64 md:w-48">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="font-bold home-title sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl">
								<p>Home Grown Initiatives (HGI) </p>
							</div>
							<div className="py-4 pr-8 home-intro-paragraph sm:text-sm xl:text-base">
								<p className="my-2">
									Rwanda’s homegrown Initiatives (HGI) are institutional and organizational
									mechanisms developed by Rwandan citizens based on local opportunities,
									cultural values and the history of the country, to fast track their development.
								</p>
							</div>
							<div className="flex flex-row home-buttons">
								<Link
									to="/hgs/what-are-hgs"
									className="my-5 font-bold text-white border-2 border-white sm:btn lg:px-16 md:px-3 lg:py-6 md:py-2 hover:bg-secondary hover:border-secondary"
								>
									Learn more
								</Link>
							</div>
						</div>
						<div className="flex flex-col items-center justify-center w-auto h-full home-scroll-controls">
							<NavigationDots active={3} />
						</div>
					</div>
					<NavigationScroll to="home-4" />
				</div>
			</section>
		);
	}
}

export default HomeHGS;
