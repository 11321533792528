import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Ubudehe from "../assets/images/Image-Ubudehe1.JPG";

export class UbudeheHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">How does Ubudehe work?</p>
				<div className="">
					<p className="my-4 text-xl font-semibold">The objectives of Ubudehe:</p>

					<div>
						<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
							<li>
								To assist community members to classify the level and type of
								poverty that exists in their community and reach a common
								understanding of this classification
							</li>
							<li>To help communities to define their development priorities</li>
							<li>
								To bring communities together to discuss and decide upon the
								most effective and efficient ways to achieve poverty reduction
								and their development priorities
							</li>
							<li>
								To help communities to establish ways of funding their development
								plans, at both the individual and group levels
							</li>
						</ul>
					</div>

					<p className="my-4">
						To achieve these aims, participating villages across Rwanda come together
						over a period of four to seven days, at a convenient time such as after
						farming activities, to complete the Ubudehe process. This process takes
						place at the beginning of the financial year.
					</p>
					<p className="my-4">
						Meetings are chaired by the president of the local Ubudehe Committee
						and the village leader. They usually last for three hours each day.
						Ubudehe takes place at both the umudugudu (village), and household
						level through similar processes.
					</p>
					<div className="my-6">
						<div className="">
							<img src={Ubudehe} alt="" />
						</div>
					</div>
					<div>
						<p className="my-4 text-xl font-semibold">
							The following steps are carried out every two years:
						</p>
						<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
							<li>
								Determine the profile of a household in poverty, as perceived by the people themselves
							</li>
							<li>Determine the causes and consequences of poverty</li>
							<li>
								Draw up the social map of the cell showing the names of
								household heads, their social category (different categories are
								again decided by the people themselves) and development
								infrastructure
							</li>
						</ul>
					</div>
					<div>
						<p className="my-4 text-xl font-semibold">
							The following steps are carried out every year:
						</p>
						<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
							<li>
								Identify and analyse the problems facing the community and
								determine a priority problem to be addressed
							</li>
							<li>
								Plan the activities and resources needed for addressing the
								prioritized problem through a collective action plan (Ubudehe)
							</li>
							<li>
								Put in place a system to manage the identified collective action
							</li>
							<li>
								Submit the action plan to an applicability test for all stakeholders
								to see if the proposed strategies in the plan are the best to solve
								the problem that was identified
							</li>
							<li>Check whether Ubudehe principles are respected</li>
							<li>
								The management committee (which is elected by the community),
								local technicians, local authorities and other stakeholders,
								approve the execution of the collective action and engage to
								safeguard and respect the principles of collective action
							</li>
							<li>
								After this process, funds are made available to support the
								identified Ubudehe collective action
							</li>
						</ul>
					</div>
					<div>
						<p className="my-4 text-xl font-semibold">
							The model household to assist
						</p>
						<p className="my-4">
							One household is chosen to undergo the Ubudehe process to assist
							it in overcoming poverty. The purpose of singling out one
							household is to provide the community with a model that can be
							followed.
						</p>
						<p className="my-4">
							The household’s coping strategies are analyzed before the
							following process is undertaken with the assistance of trained
							Ubudehe facilitators:
						</p>
						<ol className="flex flex-col gap-4 ml-6 hgs-ordered-list">
							<li>
								Determine the household’s coping strategies throughout the year
							</li>
							<li>
								Analyse these strategies in order to come up with a plan to improve
								the household’s living conditions
							</li>
							<li>
								Plan activities and budget the necessary means to execute the strategy
							</li>
							<li>
								A compatibility test is then carried out by people of integrity in the
								community (inyangamugayo) to make sure that the chosen strategy is
								appropriate and useful to the household
							</li>
							<li>
								The household members finally accept and sign for the funds that are
								accorded to them.
							</li>
						</ol>
					</div>
					<p className="my-4">
						A key part of Ubudehe is that the residents of a community define the levels
						of poverty that exist in their village. This process takes place every two
						years and the information is used to decide development priorities as well
						as who should benefit from other social security programs and HGI such as
						Umuganda and Girinka.
					</p>
				</div>
				<Link
					to="/hgs/ubudehe/poverty-level-categories"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Poverty level categories &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/ubudehe/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default UbudeheHowItWorks;
