import React, { Fragment, useState } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import ItemsCarousel from 'react-items-carousel';

const moveLeft = (e) => {
  e.preventDefault();
  const indicator = document.getElementById("indicator")
  if(indicator !== null){
    const elementStyles = getComputedStyle(indicator);
    const mlValue = elementStyles.marginLeft.split("px")[0]
    indicator.style.marginLeft = `${parseInt(mlValue) - 100}px`;
  }
  else{
    console.log("no such item of id 'indicator' ")
  }
}

const moveRight = (e) => {
  e.preventDefault();
  const indicator = document.getElementById("indicator")
  if(indicator !== null){
    const elementStyles = getComputedStyle(indicator);
    const mlValue = elementStyles.marginLeft.split("px")[0]
    indicator.style.marginLeft = `${parseInt(mlValue) + 100}px`;
    console.log( `${parseInt(mlValue) + 100}px`)
  }
  else{
    console.log("no such item of id 'indicator' ")
  }
}
 
const  ComponentCarousel = ({components}) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    return (
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={1}
          gutter={0}
          leftChevron={<HiOutlineChevronLeft className="z-10 m-6 text-4xl bg-black opacity-75 hover:text-secondary text-primary" id="left-arrow" onClick={moveLeft} />}
          rightChevron={<HiOutlineChevronRight className="z-10 m-6 text-4xl bg-black opacity-75 hover:text-secondary text-primary" id="right-arrow" onClick={moveRight} />}
          outsideChevron={false}
        >
          {components.map((component) => (
              <Fragment key={component}>
                {component}
             </Fragment>
          ))}
        </ItemsCarousel>
    );
};

export default ComponentCarousel;
