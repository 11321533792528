import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";

export class UmugandaOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Overview</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/G3yEZr6d_N0"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						The word Umuganda can be translated as "coming together in common
						purpose to achieve an outcome". In Rwanda, traditionally
						members of the community would call upon their family, friends and
						neighbours to help them complete a difficult task.
					</p>
					<p className="my-4">
						Modern day Umuganda can be described as community work. On the last
						Saturday of each month, communities come together to do a variety of
						public works, which often include activities that promote infrastructure
						development and environmental protection. Rwandans between 18 and 65 are
						obliged to participate in these activities, and non-Rwandans living in
						Rwanda are encouraged to take part.
					</p>
					<p className="my-4">
						Today, according to the Ministry of local government, close to 90% of
						Rwandans take part in monthly community work. Projects undertaken during
						Umuganda may include the building of schools, medical centers and
						hydroelectric plants as well as rehabilitating wetlands and creating
						highly productive agricultural plots. The average annual value of Umuganda
						to the country’s development between 2014 and 2019 has been estimated at
						around 21 million USD.
					</p>
					<p className="my-4">
						Whilst the main purpose of Umuganda is to undertake community work,
						after this work finishes, community members then meet to discuss
						important matters. This meeting provides a platform for leaders at
						each level of government, from the village up to the national level,
						to inform citizens about important news and announcements. It also
						serves as a forum in which community members are able to discuss any
						problems they or the community are facing and propose solutions together.
						This time is used for evaluating what the community has achieved and for
						planning activities for the next Umuganda a month later.
					</p>
					<p className="my-4">
						Umuganda has contributed nearly 62% of the total cost of classrooms constructed
						during the implementation of the Nine and Twelve Years Basic Education (9 & 12 YBE)
						programme.
					</p>
				</div>
				<Link
					to="/hgs/umuganda/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					How does Umuganda work? &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UmugandaOverview;
