import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image024 from "../assets/images/hgs-content-image024.jpeg";

export class UmwihereroImprovement extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Improvement over time</p>
				<div className="">
					<p className="my-4">
						Umwiherero began in 2004. The first four years of Umwiherero saw 
						mixed results, and in this period it tended to be a grand yet 
						unstructured brainstorming discussion.
					</p>
					<p className="my-4">
						To streamline and strengthen government coordination, President Paul 
						Kagame established the Strategy and Policy Unit in OTP and the 
						Coordination Unit in PMO. At the same time, the Ministry of Cabinet 
						Affairs was set up to improve the functioning of the Cabinet. The 
						two newly formed units in OTP and PMO were tasked with working 
						together to implement Umwiherero. While the first retreat which was 
						organised by the two new teams, suffered from similar problems to 
						previous retreats, in recent years improvements have been noticeable.
					</p>
					<p className="my-4">
						Following <span className="italic">Umwiherero</span> in 2009, the 
						Minister of Cabinet Affairs served as head of the newly formed 
						steering committee tasked with overseeing the retreat. The steering 
						committee was comprised of fourteen members. Alongside the steering 
						committee, working groups were set up to define the priorities to 
						be included on the retreat agenda. This process was overseen by 
						the Strategy and Policy Unit which developed a concept paper with 
						eleven priority areas to be approved by the Prime Minister and the 
						President
					</p>
					<p className="my-4">
						As of 2011, district mayors from all over the country started to participate 
						in <span className="italic">Umwiherero</span>, a move which improved the 
						integration of local government in the process of knowledge exchange 
						and communication at the retreat.
					</p>
					<p className="my-4">
						Since 2011 the organisation, implementation, and outcomes of 
						<span className="italic"> Umwiherero</span> have vastly improved and significant 
						achievements have been recorded. A shift in focus towards a smaller number of 
						key priorities has made it easier for meaningful discussions to be had and 
						for effective implementation to take place. For example, the number of 
						national priorities agreed upon by participants fell from 174 in 2009 to 
						11 in 2010 and fell again to six in 2011. The retreats are also credited 
						with significantly improving coordination and cooperation between government 
						ministries and agencies.
					</p>
					<div
						className="my-8 bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image024})`,
							height: "25rem",
						}}
					></div>
				</div>
				<div className="px-4 ">
				<p className="my-6 text-2xl font-semibold">
					Resolutions of the 17th national leadership retreat: Umwiherero 2020
					</p>
					<p className="font-semibold">Governance and justice</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Implement the Auditor General’s recommendations to improve
								management of public funds.
							</li>
							<li>
								Investigate and prosecute cases of corruption and institute a
								recovery mechanism for embezzled public funds.
							</li>
							<li>
								Streamline Irembo services by gradually phasing out current
								unnecessary requirements in Government institutions.
							</li>
							<li>
								Reinforce Irembo services especially in the local administrative
								entities.
							</li>
						</ol>
					</div>
					<p className="font-semibold">Health</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Expedite transfer of the mandate for medical training from the
								Ministry of Education to the Ministry of Health.
							</li>
							<li>
								Fast-track review of the Human Resources for Health (HRH)
								strategy to include continuous learning assessment (after
								training and during practice) and enforcement of ethics.
							</li>
							<li>
								Automate processes including medical records and billing to
								improve patient experience and service delivery in health
								facilities.
							</li>
							<li>
								Regulate dual practice in public health facilities to improve
								service delivery.
							</li>
							<li>
								Revamp the Early Childhood Development Program and prioritize
								reduction of malnutrition and stunting among children.
							</li>
						</ol>
					</div>
					<p className="font-semibold">Education</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Discontinue the automatic student promotion practice and
								reinforce a merit-based system to prioritize quality in
								education.
							</li>
							<li>
								Increase the number of TVET schools and devise a strategy to
								make them more accessible and affordable.
							</li>
							<li>
								Assess the quality of education in existing higher learning
								institutions and review the current accreditation and licensing
								requirements for the new ones.
							</li>
						</ol>
					</div>
					<p className="font-semibold">Private sector</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Develop a concrete plan to facilitate strengthening of private
								sector skills.
							</li>
							<li>
								Review mining sector strategies to improve diversification and
								value addition.
							</li>
							<li>
								Support access to private equity funds as alternative to loans.
							</li>
							<li>
								Foster collective investment schemes especially in high
								value-added industries and service sectors.
							</li>
						</ol>
					</div>
				</div>
				<Link
					to="/hgs/umwiherero/overview"
					className="flex flex-row items-center pb-12 mt-4 text-xs font-semibold hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default UmwihereroImprovement;
