import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image027 from "../assets/images/hgs-content-image027.jpeg";
import image047 from "../assets/images/Signing-of-Imihigo.jpg";
import { Link } from "react-router-dom";

export class WhatAre5 extends Component {
	render() {
		return (
			<div className="px-4 md:p-40 relative">
				<div className="hidden md:flex">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
						subTitle={
							<>
								How did they emerge? <HiOutlineArrowNarrowRight />
							</>
						}
						to="/hgs/how-did-they-emerge"
					/>
				</div>
				<div className="flex flex-col md:flex-row justify-center lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/umushyikirano/overview"
							image={image027}
							title="Umushyikirano"
							subtitle="Governance and Justice"
							text={
								<p>
									The National Dialogue Council known as Umushyikirano is one of
									Rwanda’s Home Grown Initiatives. The word Umushyikirano
									translates to a meeting where participants are able to
									exchange ideas, share experiences and question each other.
								</p>
							}
						/>
					</div>
					<div>
						<HGSAnimatedContainer
							to="/hgs/imihigo/overview"
							image={image047}
							title="Imihigo"
							subtitle="Economy"
							text={
								<p>
									Imihigo was a pre-colonial cultural practice in Rwanda in which an 
									individual would set targets or goals to be achieved within a 
									specific period of time, most often, leaders and chiefs.
								</p>
							}
						/>
					</div>
				</div>
				<div className="flex md:hidden">
					<Link
						to="/hgs/how-did-they-emerge"
						className="py-12 flex flex-row items-center justify-end w-full font-semibold hover:text-secondary text-primary"
					>
						How did they emerge? &nbsp; <HiOutlineArrowNarrowRight />
					</Link>
				</div>
			</div>
		);
	}
}

export default WhatAre5;
