import React, { Component, Fragment } from "react";
import ComponentCarousel from "../components/ComponentCarousel";
import How from "../components/How";
import How2 from "../components/How2";
import How3 from "../components/How3";
import How4 from "../components/How4";
import PageBottomNav from "../components/PageBottomNav";
import { hgsMenus } from "../utils/menus";

export class HGSHow extends Component {
	render() {
		const components = [<How />, <How2 />, <How3 />, <How4 />];
		return (
			<Fragment>
				<div className="hidden md:flex flex-col">
					<div>
						<ComponentCarousel components={components} />
						<PageBottomNav menus={hgsMenus} color="secondary" />
					</div>
				</div>
				<div className="flex flex-col md:hidden bg-white text-justify">
					<div>
						<How />
						<How2 />
						<How3 />
						<How4 />
					</div>
				</div>
			</Fragment>
		);
	}
}

export default HGSHow;
