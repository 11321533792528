import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import gacacaOverviewImage from "../assets/images/Gacaca1.jpeg";

export class GacacaOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Overview</p>
				<div className="flex flex-row items-start my-4">
					<div className="-mt-4 text-5xl text-secondary">&ldquo;</div>
					<div>
						<p className="text-xl italic font-semibold">
							One day during a memorial service, I was approached by a survivor.
							He was very emotional. ‘Why are you asking us to forgive?’ he
							asked me. ‘Haven’t we suffered enough? We weren’t the cause of
							this problem. Why must we provide the solution?’ These were very
							challenging questions. So I paused for a long time. Then I told
							him: ‘I’m very sorry. You are correct. I am asking too much of
							you. But I don’t know what to ask the perpetrators. ‘Sorry’ won’t
							bring back any lives. Only forgiveness can heal this nation. The
							burden rests with the survivors because they are the only ones
							with something to give.
							<strong className="text-secondary">&rdquo;</strong>
						</p>
					</div>
				</div>
				<p className="">- President Paul Kagame in “Humans of New York”</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/RzM87JwEj9M"
					/>
				</div>
				<div className="">
					<p className="my-4">
						After the 1994 Genocide against the Tutsi, rendering justice was a
						huge challenge. Since 1959, those responsible for oppressing and
						killing Tutsi had benefitted not only from blanket amnesties but
						also from rewards. The architects of the genocide then engineered
						mass participation in the killings to blur the lines of individual
						responsibility. By the year 2000, over 130,000 genocide suspects
						were detained in precarious conditions. Between 1996 and 1999 only
						300-350 cases had been tried by classical courts, it was estimated
						that it would have taken 100 years to try all of the genocide cases
						in this way.
					</p>
					<p className="">
						Contemporary Gacaca draws inspiration from the traditional model, by
						replicating a local community-based justice system with the aim of
						restoring the social fabric of society. The courts are credited with
						laying the foundation for peace, reconciliation and unity in Rwanda.
						The Gacaca courts officially finished their work in June 2012.
					</p>
					<p className="my-4">
						The word Gacaca refers to the small clearing where a community would
						traditionally meet to discuss issues of concern. People of integrity
						(elders and leaders) in the village known as inyangamugayo, would
						facilitate a discussion that any member of the community could take
						part in. Once everyone had spoken, the inyangamugayo would reach a
						decision about how the problem would be solved. In this way, Gacaca
						acted very much as a traditional court. If the decision was accepted
						by all members of the community, the meeting would finish by sharing
						a drink as a sign of reconciliation.
					</p>
				</div>
				<div className="my-4">
					<img src={gacacaOverviewImage} alt="" />
				</div>
				<div className="">
					<p className="my-4">
						The idea of entrusting genocide trials to community courts inspired
						by Gacaca, a traditional dispute resolution mechanism, received a
						range of strong reactions. Some felt that such crimes could not be
						tried by regular citizens. Others saw in it a manoeuvre to put blame
						on the entire Hutu population, while others feared it would lead to
						rampant impunity for genocide perpetrators just as had been the case
						in the past. Outside Rwanda, jurists worried about the absence of
						trained magistrates and defense attorneys.
					</p>
					<p className="">
						Contemporary Gacaca was officially launched on 18 June 2002 by
						President Paul Kagame. The main objective of Gacaca was to reconcile
						Rwandans through a process of participatory justice. Members of each
						community were to play all key roles, as prosecutors, judges and
						witnesses. Gacaca courts relied on the categorisation of offenses
						and reduced sentences for those who confessed voluntarily and asked
						for forgiveness. In total, 1,958,634 genocide-related cases were
						tried through Gacaca.
					</p>
					<p className="my-4">
						The Gacaca courts officially finished their work ten years later on
						18 June 2012. They are credited with laying the foundation for
						peace, reconciliation and unity in Rwanda.
					</p>
				</div>
				<Link
					to="/hgs/gacaca/how-did-it-work"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					How did the Gacaca courts work? &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default GacacaOverview;
