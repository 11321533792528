import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Girinka3 from "../assets/images/Girinka3.jpg"

export class GirinkaImpact extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Impact of Girinka</p>
				<div className="my-4 ">
					<img src={Girinka3} className="w-full" alt="" />
				</div>
				<div className="my-6">
					<p className="my-4">
						The Girinka programme has led to a number of significant changes in
						the lives of the poorest Rwandans. The impact of Girinka can be
						divided into five categories:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li>Agricultural production</li>
						<li>Food security</li>
						<li>Livestock ownership</li>
						<li>Health outcomes</li>
						<li>Social cohesion</li>
					</ul>
					<p className="my-4 font-semibold">Agricultural production</p>
					<p className="my-4">
						Girinka has contributed to an increase in agricultural production
						in Rwanda. For example, milk production has risen due to an increase
						in the number of cows in the country and because beneficiaries have
						received crossbreeds with better productive capacity than local cattle
						species. Between 2010 and 2019 milk production increased by 119%, from
						372,619 litres in 2010 to 816,000 litres in 2019. The construction of
						milk collection centres has also increased and by 2019, there were
						more than 126 centres operational nationwide.
					</p>
					<p className="my-4">
						Most beneficiaries produce enough milk to sell some at market, providing
						additional cash income. The manure produced by the cows increases
						the productivity of beneficiaries’ crops, which enhances their food
						production and income. Girinka has also allowed beneficiaries to
						diversify crop production, leading to greater food security.
					</p>
					<p className="my-4 font-semibold">Food security</p>
					<p className="my-4">
						Food insecurity decreased in Rwanda from 28% in 2006 to 19% in 2018,
						according to Comprehensive Food Security and Vulnerability Analyses.
						A number of studies establish a direct link between a decrease in
						food insecurity and households receiving a Girinka cow, especially in
						reduction in stunting in children.{" "}
					</p>
					<p className="my-4 font-semibold">Health outcomes</p>
					<p className="my-4">
						The Girinka programme has played some part in reducing the level
						of malnutrition across the population, as well as among children
						under five. According to the Demographic Health Survey of 2020,
						the percentage of stunted children fell from 51% in 2005, to
						33% in 2020; in the same period the percentage of wasted children
						fell from 5% to 1% percent and the percentage of underweight
						children fell from 18% to 6.
					</p>
					<p className="my-4 font-semibold">Social cohesion</p>
					<p className="my-4">
						Girinka has played an important role in post-genocide reconstruction
						in Rwanda. During the colonial period, the cow was used to divide
						Rwandans along ethnic lines and cattle became a symbol of elitism
						and a commodity reserved only for a specific portion of the
						country’s people.
					</p>
					<p className="my-4">
						This distorted meaning was promoted by successive post-colonial
						governments as part of divisive and ethnic-based policies. According
						to a report by UNICEF, 90% of the country’s cattle were killed
						during the 1994 Genocide against the Tutsi, associated as they were
						with one group. It is against this historical background that
						Girinka was introduced.
					</p>
					<p className="my-4">
						Girinka has changed what it means to own cattle in Rwanda. While the
						symbolism of prosperity is still attached to the cow, by giving
						cattle to the poorest in society, the programme has helped to end
						the divisive perception surrounding owning cattle. The ‘pass on’
						component of Girinka, whereby a recipient gifts the first born calf
						to a neighbour, has helped to rebuild social relationships destroyed
						during the 1994 Genocide against the Tutsi. This is because the
						giving of a cow to someone or Gutanga Inka, which translates as
						‘sealing a bond of friendship’, remains a cultural practice which is
						owned, understood and valued by Rwandans.
					</p>
					<p className="my-4">
						Girinka also plays a significant role in reintegrating sensitive
						groups such as former inmates convicted of genocide into society. A
						particularly high proportion of this group have reported that
						receiving a cow from Girinka helped them escape from poverty and
						raised their social status in the community.{" "}
					</p>
				</div>
				<Link
					to="/hgs/girinka/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; How Girinka works
				</Link>
			</div>
		);
	}
}

export default GirinkaImpact;
