import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics021.png";
import info2 from "../assets/images/cp-infographics/cp-infographics022.png";
import info3 from "../assets/images/cp-infographics/cp-infographics023.png";
import info4 from "../assets/images/cp-infographics/cp-infographics008.png";
import infographics035 from "../assets/images/cp-infographics/cp-infographics035.png";

export class Strategies2 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col my-4">
					<p className="my-4">
						A strong focus on Home Grown Initiatives (HGI) rooted in Rwanda’s 
						culture has made possible the harnessing of citizen participation 
						in local development activities, contributing to significant 
						improvements in access to services and human development indicators. 
						Since the publication of the first United Nations Human Development 
						Report in 1990, Rwandans have seen their living standards improve 
						more rapidly than almost any other country in the world.
					</p>
				</div>
				<div className="flex flex-row flex-wrap gap-8 my-4">
					<div className="" style={{ maxHeight: "12rem" }}>
						<img src={info1} alt="" style={{ maxHeight: "inherit" }} />
					</div>
					<div className="" style={{ maxHeight: "12rem" }}>
						<img src={info2} alt="" style={{ maxHeight: "inherit" }} />
					</div>
					<div className="" style={{ maxHeight: "12rem" }}>
						<img src={info3} alt="" style={{ maxHeight: "inherit" }} />
					</div>					
				</div>
				<div className="" style={{ maxHeight: "8rem" }}>
					<img src={info4} alt="" style={{ maxHeight: "inherit" }} />
				</div>
				<div>
					<img src={infographics035} alt="" />
				</div>
				<div>
					<p className="my-4">Rwanda has seen the following improvements:</p>
					<ul className="overview-list ml-8">
						<li>
							A decline in poverty from 59% in 2001 to 38% in 2017, which has
							lifted over a million people out of poverty
						</li>
						<li>
							A decline in child mortality rate from 183 deaths of children
							under 5 per 1000 live births in 2000 to 35 per 1000 in 2018
						</li>
						<li>An increase in the primary school enrollment rate to 98%</li>
						<li>
							A decline in the Gini index, a measure of inequality, from 0.52 in
							2006 to 0.42 in 2017.
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default Strategies2;
