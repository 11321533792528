import React, { Component } from "react";
import CPLeftImage from "./CPLeftImage";
import PageName from "./PageName";
import WhatBg from "../assets/images/What-Background.jpg";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

export class WhatMakes extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full text-justify md:h-screen">
				<div className="sm:hidden">
					<PageName
						title="What are HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid md:grid-cols-2">
					<div className="relative h-full">
						<div className="hidden md:flex">
							<CPLeftImage image={WhatBg} />
						</div>
						<div className="flex flex-col mt-20 border-b-8 md:hidden border-secondary">
							<div className="flex flex-row items-center px-4 py-2 text-xs uppercase">
								<div>
									<Link to="/" className="font-semibold text-secondary">
										Home
									</Link>
								</div>
								&nbsp;
								<MdKeyboardArrowRight className="text-xl" />
								&nbsp;
								<div className="font-semibold">
									What makes Rwanda's HGI work?
								</div>
							</div>
							<img src={WhatBg} alt="" />
						</div>
						<div className="top-0 flex flex-col w-full px-4 md:absolute md:h-screen md:bg-primary md:bg-opacity-75 lg:px-24 md:px-8 md:justify-center">
							<p className="my-4 font-semibold text-left lg:text-2xl md:text-2xl">
								What makes Rwanda's Homegrown Initiatives work?
							</p>
							<p className="my-4 text-sm md:font-bold">
								The effectiveness of HGI in the Rwandan context hinges on a number of factors.
								The first factor is cultural acceptance, which is a built-in strength of HGI.
								Their home grown nature enables actors to intimately understand the social
								setting in which activities are undertaken and to provide a common framework
								for mutual understanding, between both citizens and administrators. This also
								promotes genuine ownership.
							</p>
						</div>
					</div>
					<div className="flex flex-col h-full px-4 overflow-x-auto text-sm md:pt-20 xl:px-20 md:px-8 lg:pr-24 md:pr-16">
						<p className="font-semibold text-left md:my-4 lg:text-2xl md:text-2xl">
							Political will
						</p>
						<p className="">
							The potency of HGI is also linked to the larger developmental context
							in which they operate. In this regard, the political intentions set
							by the Urugwiro Village Discussions were crucial. These discussions
							had been conceived to find lasting solutions to the deep-rooted problems
							facing the country and devise the transformation of Rwanda. Meetings
							were held every Saturday from May 1998 to March 1999, bringing together
							164 participants from all sections of society. Two dominant orientations
							emerged from the discussions: strengthening national unity as the ultimate
							goal to which all policies must converge, and the idea that citizens must
							be put at the centre of politics and of development strategies. Subsequent
							development strategies reaffirmed the political will expressed during the
							Urugwiro discussions.
						</p>
						<p className="my-4">
							HGI were thus critical vehicles for placing citizens at the centre of
							the development and transformation of the country. Since the Urugwiro
							discussions, HGI have adapted to the changing developmental context,
							often becoming more technical for the sake of efficiency and transparency.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatMakes;
