import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics025.png";
import info2 from "../assets/images/cp-infographics/cp-infographics024.png";
import info3 from "../assets/images/cp-infographics/cp-infographics011.png";
import image002 from "../assets/images/cp-content-image002.jpeg";

export class Economy extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div
					className="w-full my-4 bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${image002})`,
						height: "30rem",
					}}
				></div>
				<div className="">
					<p className="my-4">
						The country’s post-genocide development phase began in earnest with
						President Kagame’s launch of the Vision 2020 strategy in July 2000.
						This document was a product of the Urugwiro Village Discussions; it
						was initially drafted by experts and was then submitted for
						widespread public comment. Vision 2020 became the strategic
						framework for the country’s general development trajectory, and
						other medium-term strategies were then considered as implementation
						stages.
					</p>
					<p className="my-4">
						Vision 2020 sought to make Rwanda a middle-income country by 2020
						and to transform its economy, then based on subsistence agriculture,
						to a knowledge-based economy; it thus set a number of socioeconomic
						targets to be attained by 2020. Then, after 2000, three economic
						strategy documents were adopted and implemented: The Poverty
						Reduction Strategic Paper (PSRP) was adopted in 2002, the Economic
						Development and Poverty Reduction Strategy 1 (EDPRS 1) was adopted
						in 2008, and the Economic Development and Poverty Reduction Strategy
						2 (EDPRS 2) was adopted from 2013 to 2018. In 2007, the Millennium
						Development Goals (MDGs) were formally added to this policy
						framework. Vision 2020 further sought to make the economy more
						private-sector-led and make the private sector the catalyst for
						economic transformation. The two iterations of the EDPRS framed the
						private sector as an engine for economic growth and placed strategic
						emphasis on ensuring it constituted the “dominant share of
						investment”.
					</p>
					<p className="my-4">
						In 2012 some targets in Vision 2020 were increased because of
						progress that was more rapid than expected.
					</p>
					<p className="my-4">
						A second generation of transformation strategies and policies began
						in 2017, most notably <span className="font-bold">Vision 2050, </span>
						which aspires to make Rwanda an upper middle income country by 2035 and a
						high income country by 2050.
					</p>
					<p className="my-4">
						This aspiration will be carried out through a series of
						seven-year National Strategies for Transformation, underpinned by
						detailed sectoral strategies that are aimed toward achievement of
						the Sustainable Development Goals. The current
						<span className="font-bold"> National Strategy for Transformation 1
							(NST1)</span> is a bridge between Vision 2020 and Vision 2050.
						It “is expected to lay the foundations for decades of sustained
						growth and transformation that will accelerate the move towards
						achieving high standards of living for all Rwandans”. NST1 is
						expected to pick up where the Economic Development and Poverty
						Reduction Strategy (EDPRS II) ended, and continue with the acceleration
						of transformation and economic growth, led by the private sector. The NST1
						has been the implementation instrument for the final 3 years of Vision 2020
						and will play the same role for the first four years of Vision 2050.
					</p>
					<div className="flex flex-row flex-wrap gap-4">
						<div
							className="pr-4 info-border"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info2} alt="" className="h-full" />
						</div>
						<div
							className="pr-4 info-border"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info1} alt="" className="h-full" />
						</div>
						<div
							className="pr-4 info-border"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info3} alt="" className="h-full" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Economy;
