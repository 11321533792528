import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import logo from "../assets/images/ThisOne.png";

export class NotFound extends Component {
	render() {
		return (
			<div className="flex flex-col h-screen">
				<div className="flex flex-col h-full items-center justify-center text-center">
					<div className="">
						<div className="" style={{ width: "20rem" }}>
							<img src={logo} alt="" style={{ width: "100%" }} />
						</div>
					</div>
					<div className="">
						<p className="text-4xl font-bold text-secondary">
							Oops! The page was not found.
						</p>
					</div>
					<div className="">
						<Link
							to="/"
							className="flex flex-row items-center text-xl hover:text-secondary"
						>
							<HiOutlineArrowNarrowLeft /> &nbsp; Go Home
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default NotFound;
