import React, { Component } from "react";
import { HiX, HiPlus, HiMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

export class SocialMediaLinks2 extends Component {
	state = {
		show: false,
	};

	toggleShow = (e) => {
		this.setState(() => ({
			show: e,
		}));
	};

	render() {
		const isShow = this.state.show;
		return (
			<div className="">
				{!isShow ? (
					<div className="social-link-buttons" style={{ zIndex: "100" }}>
						<div className="bg-grey-dark flex items-center bg-opacity-75">
							<HiPlus
								className="text-xl mx-2 text-white"
								onClick={() => this.toggleShow(true)}
							/>
						</div>
					</div>
				) : (
					<div
						className="social-links h-screen w-full bg-primary bg-opacity-95 bottom-0 left-0"
						style={{ zIndex: "100" }}
					>
						<div className="btn-group">
							<div className="flex flex-row justify-end items-center my-4">
								<span className="font-bold mx-3">Subscribe to updates</span>
								<Link
									to="#"
									className="btn-bottom bg-white rounded-b-full flex items-center"
								>
									<HiMail className="text-xl mx-2 text-black" />
								</Link>
							</div>
							{/* <div className="flex flex-row justify-end items-center my-4">
                                <span className="font-bold mx-3">Live chat</span>
                                <Link to="#" className="btn-bottom bg-white rounded-b-full flex items-center">
                                    <HiChatAlt className="text-xl mx-2 text-black" />
                                </Link>
                            </div> */}
							<div className="flex flex-row justify-end items-center my-4">
								<span className="font-bold mx-3">Instagram</span>
								<a
									href="https://www.instagram.com/rwandapedia/"
									target="_blank"
									rel="noopener noreferrer"
									className="p-3 font-semibold bg-white rounded-full"
								>
									<FaInstagram className="" />
								</a>
							</div>
							<div className="flex flex-row justify-end items-center my-4">
								<span className="font-bold mx-3">Facebook</span>
								<a
									href="https://www.facebook.com/rwandapedia"
									target="_blank"
									rel="noopener noreferrer"
									className="p-3 font-semibold bg-white rounded-full"
								>
									<FaFacebook className="" />
								</a>
							</div>
							<div className="flex flex-row justify-end items-center my-4">
								<span className="font-bold mx-3">Twitter</span>
								<a
									href="https://twitter.com/Cooperrwandapediaation_RW"
									target="_blank"
									rel="noopener noreferrer"
									className="p-3 font-semibold bg-white rounded-full"
								>
									<FaTwitter className="" />
								</a>
							</div>
							<div className="flex flex-row justify-end items-center my-4">
								<a
									href="https://cooperation.rw/"
									target="_blank"
									rel="noopener noreferrer"
									className="p-3 font-semibold bg-white rounded-full"
								>
									<p className="text-xs">RCI</p>
								</a>
							</div>
							<div className="btn-bottom bg-grey rounded-b-full flex items-center float-right">
								<HiX
									className="text-xl mx-2 text-black"
									onClick={() => this.toggleShow(false)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default SocialMediaLinks2;
