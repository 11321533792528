import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
// import homeImage from "../assets/images/image59.jpeg";
import homeIntro from "../assets/images/homeIntro.jpg";
import { Link } from "react-router-dom";

export class HomeIntro extends Component {
	render() {
		return (
			<section className="home-section h-screen w-full bg-primary text-white">
				<div
					className="home-background bg-cover bg-no-repeat bg-center"
					style={{ backgroundImage: `url(${homeIntro})` }}
				></div>
				<div className="home-intro-gradient absolute w-full h-full z-10"></div>
				<div className="sm:w-full sm:px-4 home-left flex flex-col md:px-8 pb-8 h-full">
					<div className="flex flex-row h-full items-center">
						<div className="home-intro-content pr-20">
							<div className="home-section-logo flex lg:w-64 md:w-48 py-8">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="home-title sm:text-3xl md:text-4xl lg:text-5xl font-bold">
								<p className="">Welcome to</p>
								<p className="-mt-4">Rwandapedia</p>
							</div>
							<div className="home-intro-paragraph sm:text-sm xl:text-base py-4 pr-12">
								<p className="">
									Welcome to Rwandapedia, an open, digital platform created to let students, 
									researchers, policymakers, journalists and public servants learn about 
									Rwanda and its transformation journey. It’s the place to learn about 
									Rwanda’s innovative Homegrown Initiatives (HGI) approach: the creation 
									of development programs in the social, economic and governance sectors, 
									to fit the country’s needs and context, based on culturally owned practices.
								</p>
								<p className="my-4">
									Rwandapedia offers its users access to a free encyclopedic repository of 
									documents, images and videos covering Rwanda’s Homegrown Initiatives across 
									the social, economic and governance sectors, as well as the country’s key 
									cultural and historical events.  
								</p>
							</div>
							<div className="flex flex-row w-full items-start">
								<div className="flex flex-row ">
									<Link
										to="/country/introduction"
										className="home-btn sm:btn lg:px-8 xl:px-16 md:px-3 lg:py-6 md:py-2 mr-4 text-white hover:bg-secondary font-bold border-2 border-white hover:border-secondary"
									>
										Country Profile
									</Link>
									<Link
										to="/hgs/what-are-hgs"
										className="home-btn sm:btn lg:px-8 xl:px-16 md:px-3 lg:py-6 md:py-2 text-white hover:bg-secondary font-bold border-2 border-white hover:border-secondary"
									>
										What are Rwanda's HGI?
									</Link>
								</div>
								<div className="">
									<NavigationScroll to="home-2" />
								</div>
							</div>
						</div>
						<div className="home-scroll-controls flex flex-col h-full w-auto justify-center items-center">
							<NavigationDots active={1} />
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default HomeIntro;
