import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import image032 from "../assets/images/hgs-content-image032.jpeg";

export class IngandoImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Impact of Ingando</p>
				<div className="">
					<div className="my-6">
						<div className=" my-4">
							<img src={image032} alt="" />
						</div>
						<p className="my-4">
							Ingando has contributed significantly to national unity and
							reconciliation in Rwanda, and this was especially true for
							sessions conducted during the early years of the programme
							(between 1996 and 1999) when most participants were returning
							combatants or Rwandans afraid or unsure of their new government.
							Special attention was paid to social justice and helping
							participants understand government strategies to improve social
							welfare. This approach was key to ensure that progress made in
							reconciliation was sustainable.
						</p>
						<p>
							When the programme was established, it faced significant
							challenges including a lack of trust between participants and
							facilitators, as well as low quality facilities. These issues were
							slowly overcome as more resources were dedicated to the programme.
							With time the objectives of the programme were better understood
							and it became properly integrated into Rwandan society.
						</p>
					</div>
				</div>
				<Link
					to="/hgs/ingando/overview"
					className="mt-4 pb-12 flex flex-row items-center justify-end md:justify-start font-semibold text-xs hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default IngandoImpact;
