import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import Gacaca31 from "../assets/images/Gacaca31.JPG";
import Gacaca32 from "../assets/images/Gacaca32.jpg";

export class GacacaAchievements extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Achievements</p>
				<div className="">
					<p className="my-4 font-semibold">Statistics</p>
					<p>Cases tried by Gacaca are presented in the following tables.</p>
					<div className="w-full overflow-x-auto">
						<table className="w-full text-xs text-left border border-collapse border-gray-400 hgs-table">
							<tr className="uppercase bg-hgsBgLight">
								<th>Category</th>
								<th>Number of cases</th>
								<th colSpan="4" className="text-center">
									Guilty
								</th>
								<th>Acquitted</th>
								<th>%</th>
							</tr>
							<tr className="uppercase border border-gray-400 bg-hgsBgLight">
								<th></th>
								<th></th>
								<th>Total</th>
								<th>%</th>
								<th>Confessions</th>
								<th>%</th>
								<th></th>
								<th></th>
							</tr>
							<tr>
								<td>ONE</td>
								<td>60,552</td>
								<td>53,426</td>
								<td>88.3%</td>
								<td>22,137</td>
								<td>41.4%</td>
								<td>7,126</td>
								<td>11.7%</td>
							</tr>
							<tr>
								<td>TWO</td>
								<td>577,528</td>
								<td>361,590</td>
								<td>62.6%</td>
								<td>108,821</td>
								<td>30%</td>
								<td>215,938</td>
								<td>37.4%</td>
							</tr>
							<tr>
								<td>THREE</td>
								<td>1,320,554</td>
								<td>1,266,632</td>
								<td>96%</td>
								<td>94,054</td>
								<td>7.4%</td>
								<td>54,002</td>
								<td>4%</td>
							</tr>
							<tr className="font-semibold border border-gray-400 text-secondary">
								<td>TOTAL</td>
								<td>1,958,634</td>
								<td>1,681,948</td>
								<td>86%</td>
								<td>225,012</td>
								<td>13.3%</td>
								<td>277,066</td>
								<td>14%</td>
							</tr>
						</table>
					</div>
					<div className="w-full overflow-x-auto">
						<table className="w-full text-xs text-left border border-b-4 border-collapse hgs-table border-secondary">
							<tr className="uppercase border border-gray-400 bg-hgsBgLight">
								<th>Category</th>
								<th>Number of cases</th>
								<th colSpan="4" className="text-center">
									Guilty
								</th>
								<th>Acquitted</th>
								<th>%</th>
							</tr>
							<tr className="uppercase border border-gray-400 bg-hgsBgLight">
								<th></th>
								<th></th>
								<th>Total</th>
								<th>%</th>
								<th>Confessions</th>
								<th>%</th>
								<th></th>
								<th></th>
							</tr>
							<tr>
								<td>ONE</td>
								<td>19,177</td>
								<td>16,688</td>
								<td>87%</td>
								<td>6,731</td>
								<td>35%</td>
								<td>2,489</td>
								<td>13%</td>
							</tr>
							<tr>
								<td>TWO</td>
								<td>134,394</td>
								<td>93,607</td>
								<td>80.6%</td>
								<td>26,019</td>
								<td>19%</td>
								<td>40,787</td>
								<td>30%</td>
							</tr>
							<tr>
								<td>THREE</td>
								<td>25,170</td>
								<td>22,607</td>
								<td>90%</td>
								<td>562</td>
								<td>2.4%</td>
								<td>2,563</td>
								<td>10%</td>
							</tr>
							<tr className="font-semibold border border-gray-400 text-secondary">
								<td>TOTAL</td>
								<td>178,741</td>
								<td>132,902</td>
								<td>74%</td>
								<td>33,312</td>
								<td>25.8%</td>
								<td>45,839</td>
								<td>26%</td>
							</tr>
						</table>
						<p className="font-semibold text-right text-secondary">
							Source: Administrative Report on Gacaca from the National Service of
							Gacaca Courts.
						</p>
					</div>
					<p className="my-4">
						The trials judged by the courts of appeals number 178,741,
						equivalent to 9% of the 1,958,634 cases judged by Gacaca.
					</p>
					<p className="text-xl font-semibold">Social impact</p>
					<p className="my-4">
						Gacaca courts officially finished their work on 18 June 2012 and by
						that time a total of 1,958,634 genocide related cases had been tried
						throughout the country.
					</p>
					<div className="my-4">
						<img src={Gacaca32} alt="" />
					</div>
					<p>
						The Centre for Conflict Management at the National University of
						Rwanda carried out a study to determine the extent to which Rwandans
						believe that Gacaca had attained its five objectives. The following
						are the results for each of the five objectives, the average
						percentage of which was 88%:
					</p>
					<div className="p-8 my-4 bg-hgsBgLight">
						<ol className="flex flex-col gap-4 ml-4 list-decimal hgs-ordered-list">
							<li>
								Finding out and disclosing the truth about the genocide: 84%
							</li>
							<li>Speeding up the genocide trials: 87%</li>
							<li>Ending the culture of impunity: 87%</li>
							<li>Strengthening unity and reconciliation: 87%</li>
							<li>
								Demonstrating the capacity of Rwandans to resolve their own
								problems: 95%
							</li>
						</ol>
					</div>
					<p className="my-4">
						Whilst many had expected the processing of cases through the courts
						to take far longer, Gacaca defied these expectations and played a
						key role in delivering timely justice for many victims of the
						genocide.
					</p>
					<p className="my-4">
						Perceptions of the nature of relations among citizens, including the
						level of social cohesion presented later, were strongly influenced
						by the Gacaca tribunals that had just finished their work.
					</p>
					<p className="my-4">
						Three years after the official end of the Gacaca phase, there was a
						general feeling that a milestone had been reached that decreased the
						weight of the memory of the genocide.
					</p>
					<p className="my-4">
						The Gacaca process had “broken the ice” in Rwandan communities,
						making possible a serious discussion of what happened during the
						genocide. For survivors, the most tangible benefit of Gacaca was
						that many among them were finally able to find the remains of the
						bodies of their loved ones and bury them with dignity, and to mourn.
						Gacaca offered public recognition of the pain of the most isolated
						survivors. The process of confession and asking to be pardoned by
						the victims, even if imperfect, created the basis on which more
						peaceful social interaction finally became possible; according to
						one survivor of the genocide:
					</p>
					<div className="px-24 py-8 text-center bg-hgsBgLight">
						<p className="my-4 italic ">
							“Before Gacaca, there was hardly any confidence. In fact, there
							wasn’t any at all. Repatriated refugees did not want to tell the
							truth about how people had been killed, those who killed them and
							where the bodies were. Since Gacaca, I feel that people grew closer
							together as the truth gradually came out. Even if some hid the
							truth, those who were in prison told the truth. It was the prisoners
							who told the truth about how people had been killed. That’s how we
							made progress: thanks to the prisoners who confessed after they
							understood that they should tell the truth. We learned, understood
							and finally accepted.”
						</p>
					</div>
					<div className="my-4">
						<img src={Gacaca31} alt="" />
					</div>
					<p className="my-4">
						The World Values survey carried out at the midpoint and at the end
						of the Gacaca process suggested a reinforcement of social cohesion
						primarily through the responses to the question “Can you trust most
						people?” In the 2007 survey, Rwandans expressed one of the lowest
						levels of social confidence in the world with only 5 percent of
						positive responses. In 2012, Rwanda considerably improved its score,
						with a level of trust in others of 17 percent, one of the highest in
						sub-Saharan Africa.
					</p>
				</div>
				<Link
					to="/hgs/gacaca/how-did-it-work"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; How did the Gacaca courts work?
				</Link>
			</div>
		);
	}
}

export default GacacaAchievements;
