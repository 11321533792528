import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Itorero2 from "../assets/images/Itorero2.jpg";
import trained from "../assets/images/hgs-infographics/Itorero_trained.png";

export class ItoreroHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">
					Itorero ry’Igihugu – Contemporary Itorero
				</p>
				<div className="">
					<div className="my-4">
						<img src={Itorero2} alt="" />
					</div>
					<p className="my-4">
						The reintroduction of Itorero in 2009 aimed to develop a civic
						education programme based on patriotic values that strengthen Rwandan
						citizens’ contribution to the development of their country and the
						well-being of their society.
					</p>
					<p className="my-4">
						A National Itorero Commission (NIC), Itorero ry’Igihugu in
						Kinyarwanda, was created to drive the programme. It is an
						independent commission with responsibility for planning,
						implementation, coordination, monitoring and evaluation of Itorero
						programmes at all levels of society.
					</p>
					<p className="my-4 text-xl font-semibold">
						The vision of the National Itorero Commission
					</p>
					<p className="my-4">
						The vision of NIC is to help to build a society with a shared
						mindset and values that promote unity and patriotism, as well as
						awareness of the goals of the country and how they can be achieved.
					</p>
					<p className="my-4">The main objectives of NIC are:</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>
							To train Rwandans:
							<ul className="flex flex-col gap-4 ml-8 hgs-list">
								<li>to better understand their shared values;</li>
								<li>
									to be good and active citizens who are patriotic and who
									actively contribute to national development;
								</li>
								<li>
									to be Intore who are worthy Rwandans at all levels of society
									and to be catalysts for positive change;
								</li>
								<li>
									to acquire the culture of volunteerism through national
									service.
								</li>
							</ul>
						</li>
						<li>
							To promote respect for positive cultural values as a basis for
							coexistence and national development
						</li>
						<li>
							To collaborate with other public and private
							institutions and non-governmental organisations with similar
							objectives
						</li>
						<li>
							To prepare and monitor volunteerism through national service
						</li>
					</ul>
					<p className="my-4 text-xl font-semibold">
						The values of Itorero (Indagagaciro)
					</p>
					<p className="my-4">
						At the heart of the Itorero programme lies a set of values, which
						defines an ethical framework. Contempory Itorero promotes the
						following values:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>Unity</li>
						<li>Patriotism</li>
						<li>Selflessness</li>
						<li>Integrity</li>
						<li>Responsibility</li>
						<li>Volunteerism</li>
						<li>Humility</li>
					</ul>
					<p className="my-4 text-xl font-semibold">
						Itorero training sessions
					</p>
					<p className="my-4">
						The main activity of Itorero is a series of training sessions that
						include lessons on Rwanda’s history and culture along with physical
						activities; these sessions aim to educate Rwandans on positive
						cultural values which had been lost during colonisation. During
						Itorero sessions, trainings are adapted and tailored to the needs of
						particular participants. For example, teachers and health workers
						cover activities related to their profession while local leaders are
						trained in public service delivery and good governance.
					</p>
					<p className="my-4">
						During Itorero training sessions, presentations and discussions have
						the following aims:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>
							To endow participants with the capacity to analyse their own
							problems and to find solutions
						</li>
						<li>
							To promote the Kinyarwanda language, as well as the Rwandan
							spirit and Rwandan cultural values, in order to build better
							social relations and achieve a good life
						</li>
						<li>
							To mentor participants to develop a spirit of cooperation and to
							embrace innovation and a culture of performance
						</li>
						<li>
							To inform participants about the country’s most significant
							policies and programmes
						</li>
						<li>
							To sensitise participants about the importance of a range of
							valuable practices including physical fitness, cleanliness at
							home, environmental protection, a culture of debate, family
							conflict resolution mechanisms, compliance with the law, and the
							need to combat corruption and aggressive behaviour
						</li>
						<li>
							To sensitise participants about the importance of building and
							promoting a culture of peace that is based on mutual respect,
							trust, humility, democratic values, human rights, and the fight
							against discriminatory behavior and genocide ideology
						</li>
						<li>
							To sensitise participants on the importance of efficiency in service
							delivery, professional stamina, and the ability to deliver good and
							efficient work.
						</li>
					</ul>
					<p className="my-4 text-xl font-semibold">Participants</p>
					<p className="my-4">
						Itorero is designed for all Rwandans. Different curricula have been
						developed to suit the programme's varied participants.{" "}
					</p>
					<p className="my-4">
						Children of seven years and above take part in Itorero in their
						Imidugudu (Villages) to help them grow up to become responsible
						citizens. Others keen to participate are given the opportunity to do
						so according to their professional backgrounds.
					</p>
					<p className="my-4">
						Young Rwandans from the Diaspora on holidays in Rwanda can join Urugerero
						and a number of young Rwandans have organized Itorero in cities
						including London and Brussels.
					</p>
					<p className="my-4">
						Non-nationals who wish to participate and provide service to the
						country can also do so. University graduates and retired people who
						participated in Urugerero before and wish to do so again are also
						given the opportunity to join Itorero.
					</p>
					<p className="my-4 text-xl font-semibold">
						National Service (Urugerero)
					</p>
					<p className="my-4">
						National Service (Urugerero) is designed for those between the ages
						of 18-35 who have completed secondary education with the aim of
						developing a sense of fraternity, national identity and
						participation in national programmes among the Rwandan youth. Whilst
						the curriculum content of Itorero ry’Igihugu is particular to Rwanda
						as it is based on principles and values unique to the Rwandan
						culture; Urugerero (National Service), has much in common with what
						takes place in other countries.
					</p>
					<p className="my-4">
						Under Urugerero, thousands of youths have built housing units for
						the vulnerable, set up kitchen gardens for disadvantaged households
						to help meet beneficiaries’ nutritional needs, fixed roads and
						bridges especially in rural neighbourhoods, and played a part in
						mobilisation for vital socioeconomic causes. Districts have started
						to integrate Urugerero into their mainstream development and
						ecosystem services.
					</p>
					<p className="my-4">
						Urugerero is still developing and reaching out to potential partners
						which may include government organs, businesses and non-governmental
						organisations, in order expand the scope of the activities available
						to youth.
					</p>
					<p className="my-4 text-xl font-semibold">
						The main achievements of Itorero
					</p>
					<p className="my-4">
						From 2007 to 2017, over 1,700,000 people from both public and
						private institutions were trained under Itorero from all walks of
						life, as shown in the figure below.
					</p>
					<div>
						<p className="font-bold text-primary">Categories of Intore trained from 2007 to 2017</p>
						<div className="border-b-4 border-secondary">
							<img src={trained} alt="" style={{ width: "100%" }} />
						</div>
						<p className="font-semibold text-right text-secondary">
							NUDC Amazina y'imitwe y'Intore 2017.
						</p>
					</div>

					<p className="my-4">
						Itorero complements other government programmes and policies,
						contributing to strengthening intangible elements that make these
						programmes work such as a sentiment of ownership and a sense of
						civic responsibility and accountability.
					</p>
				</div>
				<Link
					to="/hgs/ingando/overview"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Ingando &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/itorero/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Overview
				</Link>
			</div>
		);
	}
}

export default ItoreroHowItWorks;
