import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import image034 from "../assets/images/hgs-content-image034.jpeg";
import image036 from "../assets/images/hgs-content-image036.jpeg";
import image047 from "../assets/images/hgs-content-image047.jpeg";
import image020 from "../assets/images/hgs-content-image020.jpeg";

export class UmwihereroResolutions extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Improvement over time</p>
				<div className="">
					<p className="my-6 text-2xl font-semibold">
						Resolutions of the 17th national leadership retreat: Umwiherero 2020
					</p>
					<p className="font-semibold">Governance and justice</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Implement the Auditor General’s recommendations to improve
								management of public funds.
							</li>
							<li>
								Investigate and prosecute cases of corruption and institute a
								recovery mechanism for embezzled public funds.
							</li>
							<li>
								Streamline Irembo services by gradually phasing out current
								unnecessary requirements in Government institutions.
							</li>
							<li>
								Reinforce Irembo services especially in the local administrative
								entities.
							</li>
						</ol>
					</div>
					<div
						className="my-8 bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image034})`,
							height: "25rem",
						}}
					></div>
					<p className="font-semibold">Health</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Expedite transfer of the mandate for medical training from the
								Ministry of Education to the Ministry of Health.
							</li>
							<li>
								Fast-track review of the Human Resources for Health (HRH)
								strategy to include continuous learning assessment (after
								training and during practice) and enforcement of ethics.
							</li>
							<li>
								Automate processes including medical records and billing to
								improve patient experience and service delivery in health
								facilities.
							</li>
							<li>
								Regulate dual practice in public health facilities to improve
								service delivery.
							</li>
							<li>
								Revamp the Early Childhood Development programmeand prioritize
								reduction of malnutrition and stunting among children.
							</li>
						</ol>
					</div>
					<div
						className="my-8 bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image036})`,
							height: "25rem",
						}}
					></div>
					<p className="font-semibold">Education</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Discontinue the automatic student promotion practice and
								reinforce a merit-based system to prioritize quality in
								education.
							</li>
							<li>
								Increase the number of TVET schools and devise a strategy to
								make them more accessible and affordable.
							</li>
							<li>
								To assess the quality of education in existing higher learning
								institutions and review the current accreditation and licensing
								requirements for the new ones.
							</li>
						</ol>
					</div>
					<div
						className="my-8 bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image047})`,
							height: "25rem",
						}}
					></div>
					<p className="font-semibold">Private sector</p>
					<div className="my-4">
						<ol className="flex flex-col gap-4 mx-4 hgs-ordered-list">
							<li>
								Develop a concrete plan to facilitate strengthening of private
								sector skills.
							</li>
							<li>
								Review mining sector strategies to improve diversification and
								value addition.
							</li>
							<li>
								Support access to private equity funds as alternative to loans.
							</li>
							<li>
								Foster collective investment schemes especially in high
								value-added industries and service sectors.
							</li>
						</ol>
					</div>
					<div
						className="my-8 bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image020})`,
							height: "25rem",
						}}
					></div>
				</div>
				<Link
					to="/hgs/umwiherero/improvement"
					className="flex flex-row items-center pb-12 mt-4 text-xs font-semibold hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Improvement over time
				</Link>
			</div>
		);
	}
}

export default UmwihereroResolutions;
