import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";

export class ImihigoOverview extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Overview</p>
				{/* <div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://www.youtube.com/watch?v=n6J6iiNDtws&ab_channel=TheGovernmentofRwanda"
					/>
				</div> */}
				<div className="my-6">
					<p className="my-4">
						Imihigo is the plural of the Kinyarwanda word Umuhigo, and is
						derived from the root, Higa, and the verb Guhiga, which means to vow
						to deliver, as well as Guhiganwa, which means to compete among one
						another. Imihigo was a pre-colonial cultural practice in Rwanda in
						which an individual would set targets or goals to be achieved within
						a specific period of time, most often, leaders and chiefs. The
						person would have to complete these objectives by following guiding
						principles, and was expected to be determined to overcome any
						possible challenges that arise. In the event that leaders and chiefs
						failed to achieve their stated objectives, they would face shame and
						embarrassment from the community. However, opinions vary on what
						constituted a traditional Imihigo. Some have recalled it as having a
						basis in war, whereby warriors would throw a spear into the ground
						while publicly proclaiming the feats they would accomplish in battle.
					</p>
					<p className="my-4">
						In 2000, a decentralisation programme shifted the responsibilities
						of all levels of government, requiring a new approach to monitoring
						and evaluation. Local levels of government were now responsible for
						implementing development programmes which meant that the central
						government – as well as the people of Rwanda – needed a way to
						ensure accountability. In 2006, the Imihigo system (referred to in
						English as performance contracts) was reinstated by Rwanda’s
						President, Paul Kagame, as a result of concerns about the speed and
						quality of execution of government programmes and priorities.{" "}
					</p>
					<p className="my-4">
						Today, Imihigo is used across government as a system of performance
						contracts and to ensure accountability. All levels of government,
						from the local district level to ministries and embassies, are
						required to develop their Imihigo and have them evaluated. Members
						of the civil service also sign Imihigo with their managers or head
						of institution.
					</p>
					<p className="my-4">
						Whilst Imihigo is now widely used across government, it first began
						at the district level. When developing its Imihigo, each local
						government administrative unit determines its own objectives (with
						measurable indicators) taking into account national priorities as
						highlighted in the national and international policy and strategy
						documents such as the Sustainable Development Goals (SDGs), Vision 
						2050, National Strategy for Transformation 1 (NST1),
						District Development Strategies (DDSs) and Sector Strategic Plans
						(SSPs).
					</p>
					<p className="my-4">
						The Imihigo, are presented to the public for the purpose of
						accountability and transparency, during both planning and reporting
						phases. The mayors and province governors also sign the Imihigo or
						performance contracts with Rwanda’s President, which commit them to
						achieving certain agreed objectives.
					</p>
					<p className="my-4">
						Since its introduction, Imihigo has been credited with:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-ordered-list">
						<li>Improving accountability,</li>
						<li>
							Increasing the pace of citizen centered development activities and
							programs,
						</li>
						<li>
							Ensuring the full participation and ownership of citizens because
							priorities are developed at the grassroots level.
						</li>
					</ul>
				</div>
				<Link
					to="/hgs/imihigo/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					How the Imihigo performance contracts work &nbsp;
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default ImihigoOverview;
