import React, { Component } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import ReactPlayer from 'react-player/lazy';
import { Link } from 'react-router-dom';

export class UbudeheOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Overview</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/yOxuSBkQxyQ"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						Ubudehe refers to the long-standing Rwandan cultural practice of
						collective action and mutual support to solve problems within the
						community. The focus of traditional Ubudehe was mostly on cultivation.
					</p>
					<p className="my-4">
						Today, Ubudehe is a Government of Rwanda poverty reduction initiative
						which provides communities with the skills and support necessary to
						engage in problem-solving and decision-making for their development.
						The programme helps citizens to use local institutions to achieve
						goals set by the community.
					</p>
					<p className="my-4">
						Ubudehe was reintroduced into Rwandan life in 2001, as a way of
						strengthening democratic processes and good governance through greater
						community involvement in decision-making. Ubudehe creates
						opportunities for people at all levels of society, especially the
						village level, to interact with one another, share ideas, create
						institutions and make decisions for their collective development.
					</p>
					<p className="my-4">
						Ubudehe is one of Rwanda’s best known Home Grown Initiatives (HGI)
						because of its participatory development approach to poverty reduction.
						In 2008, the programme won the United Nations Public Service Award for
						excellence in service delivery. Today, Ubudehe is one of the
						country’s core development programmes.
					</p>
				</div>
				<Link
					to="/hgs/ubudehe/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					How does Ubudehe work? &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UbudeheOverview
