export const cpMenus = [
	{ id: 1, route: "/country/introduction", name: "Overview" },
	{ id: 2, route: "/country/overview", name: "Quick Facts" },
	{ id: 3, route: "/country/history", name: "History" },
	{ id: 4, route: "/country/today", name: "Post-Genocide Rwanda" },
	{ id: 5, route: "/country/institutionalisation", name: "Institutionalisation of good governance" },
	{ id: 6, route: "/country/gender", name: "Gender equality and women empowerment" },
	{ id: 7, route: "/country/economy", name: "Economy" },
	{ id: 8, route: "/country/strategies", name: "Social development" },
];

export const hgsMenus = [
	{ id: 1, route: "/hgs/what-are-hgs", name: "What are Rwanda's HGI?" },
	{ id: 2, route: "/hgs/how-did-they-emerge", name: "How did they emerge?" },
	{ id: 3, route: "/hgs/what-makes-them-work", name: "What makes them work?" },
];

export const abunziSubmenus = [
	{ id: 1, route: "/hgs/abunzi/overview", name: "Overview" },
	{ id: 3, route: "/hgs/abunzi/how-it-works", name: "How does it work?" },
	{ id: 4, route: "/hgs/abunzi/performance", name: "Performance and evaluation" },
	//{ id: 5, route: "/hgs/abunzi/challenges", name: "Challenges" },
	{ id: 6, route: "/hgs/abunzi/practices", name: "Beneficial practices" },
];
export const gacacaSubmenus = [
	{ id: 1, route: "/hgs/gacaca/overview", name: "Overview" },
	{ id: 2, route: "/hgs/gacaca/how-did-it-work", name: "How did the Gacaca courts work?" },
	//	{ id: 3, route: "/hgs/gacaca/jurisdiction", name: "The jurisdictions of Gacaca" },
	//	{ id: 4, route: "/hgs/gacaca/functioning", name: "Functioning of Gacaca" },
	//{ id: 5, route: "/hgs/gacaca/challenges", name: "Challenges" },

	{ id: 6, route: "/hgs/gacaca/achievements", name: "Achievements" },
];
export const itoreroSubmenus = [
	{ id: 1, route: "/hgs/itorero/overview", name: "Overview" },
	{ id: 2, route: "/hgs/itorero/how-it-works", name: "Itorero ry'Igihugu - Contemporary Itorero" },
	// { id: 3, route: "/hgs/itorero/outcomes", name: "Outcomes" },
	// { id: 4, route: "/hgs/itorero/participants", name: "Participants" },
]
export const ingandoSubmenus = [
	{ id: 1, route: "/hgs/ingando/overview", name: "Overview" },
	//	{ id: 2, route: "/hgs/ingando/objectives", name: "Objectives" },
	{ id: 3, route: "/hgs/ingando/impact", name: "Impact" },
];
export const umushyikiranoSubmenus = [
	{ id: 1, route: "/hgs/umushyikirano/overview", name: "Overview" },
	{ id: 2, route: "/hgs/umushyikirano/how-it-works", name: "How does Umushyikirano work?" },
	{ id: 3, route: "/hgs/umushyikirano/impact", name: "Impact of Umushyikirano" },

	//	{ id: 4, route: "/hgs/umushyikirano/national-umushyikirano-council", name: "National Umushyikirano Council Implementation Report" },
];
export const umwihereroSubmenus = [
	{ id: 1, route: "/hgs/umwiherero/overview", name: "Overview" },
	{ id: 2, route: "/hgs/umwiherero/improvement", name: "Improvement over time" },
	// { id: 3, route: "/hgs/umwiherero/resolutions", name: "Resolutions implementation process" },
];
export const umugandaSubmenus = [
	{ id: 1, route: "/hgs/umuganda/overview", name: "Overview" },
	{ id: 2, route: "/hgs/umuganda/how-it-works", name: "How does Umuganda work?" },
	//{ id: 3, route: "/hgs/umuganda/challenges", name: "Challenges" },
	{ id: 4, route: "/hgs/umuganda/impact", name: "Impact" },
];
export const ubudeheSubmenus = [
	{ id: 1, route: "/hgs/ubudehe/overview", name: "Overview" },
	{ id: 2, route: "/hgs/ubudehe/how-it-works", name: "How does Ubudehe work?" },
	{ id: 3, route: "/hgs/ubudehe/poverty-level-categories", name: "Poverty level categories" },
	{ id: 4, route: "/hgs/ubudehe/organization", name: "Organization and coordination" },
	//{ id: 5, route: "/hgs/ubudehe/challenges", name: "Challenges" },
	{ id: 6, route: "/hgs/ubudehe/impact", name: "Impact" },
];
export const imihigoSubmenus = [
	{ id: 1, route: "/hgs/imihigo/overview", name: "Overview" },
	{ id: 2, route: "/hgs/imihigo/how-it-works", name: "How the Imihigo performance contracts work" },
	//{ id: 3, route: "/hgs/imihigo/challenges", name: "Challenges" },
];
export const girinkaSubmenus = [
	{ id: 1, route: "/hgs/girinka/overview", name: "Overview" },
	{ id: 2, route: "/hgs/girinka/how-it-works", name: "How does Girinka works?" },
	{ id: 3, route: "/hgs/girinka/impact", name: "Impact of Girinka" },
	//{ id: 4, route: "/hgs/girinka/challenges", name: "Challenges" },
];
