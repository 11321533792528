import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import PageName from "../components/PageName";
import infographics038 from "../assets/images/cp-infographics/cp-infographics038.png";
import Good1 from "../assets/images/Good-Governance1.jpg"

export class CPInstitutionalisation extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Country Profile"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Gender equality and women empowerment &nbsp;{" "}
							<HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/gender"
				/>
				<p className="text-3xl font-semibold">
					Institutionalisation of good governance
				</p>
				<div className="my-4 ">
					<img src={Good1} className="w-full" alt="" />
				</div>
				<div>
					<div>
						<p className="my-4">
							Just after the genocide and civil war, the new leadership turned
							to the administrative institutions inherited from the previous
							regime and that then functioned for several more years. The
							creation of the new institutions that began in 1998, sought to do
							away with the past. In the executive branch, the taxing and
							fee-collecting departments were too dependent on the Ministry of
							Finance. Decision makers sought to introduce more checks and
							balances within the executive branch by making its economic
							governance institutions stronger and more autonomous.
						</p>
						<p className="my-4">
							The first economic governance institution to be created was the
							<span className="font-bold"> National Tender Board</span> in November
							1997 to counter the numerous abuses in awarding public tenders.
							After functioning for several years, the centralisation of the process
							of awarding important public contracts in this single institution
							created a bottleneck and caused many delays in the administration’s
							operations. In February 2008 the <span className="font-bold">Rwanda
								Public Procurement Authority (RPPA)</span> replaced the National
							Tender Board. The RPPA decentralised the process for awarding
							public contracts and created an independent entity for appealing its
							decisions. The RPPA was adapted according to the Paris Declaration
							on aid effectiveness and the Accra Agenda for Action. This permits
							donors to support the Rwandan system for awarding public contracts,
							which in turn facilitates the granting of aid in the form of direct
							budget support.
						</p>
						<p className="my-4">
							The <span className="font-bold">Rwanda Revenue Authority</span> was
							created in November 1997 to reinforce the collection of taxes and fees;
							it also had an anti-corruption unit. In June 1998, the
							<span className="font-bold"> Bureau of the Auditor General</span> was
							created to audit ministries and companies owned by the state. In April
							2011, the <span className="font-bold">Parliamentary Public Accounts Committee
							</span> was established partly in order to remedy the lack of follow up on
							the auditor general’s reports to parliament. This committee held public
							hearings with political and administrative heads of government institutions
							and exerted pressure on the executive and judiciary to take more vigorous
							action in cases of mismanagement of public funds.
						</p>
						<p className="my-4">
							In 2004, the <span className="font-bold"> Office of the Ombudsman </span>
							was created to combat corruption and injustice. This office collects
							annual declarations of patrimony of senior government officials and
							monitors the application of the law covering the code of conduct of the
							heads of public institutions. It serves as recourse for citizens against
							acts carried out by the administration that are considered as unjust.
							The Office of the Ombudsman also has a specialized anticorruption unit.
							{" "}
						</p>
						<p className="my-4">
							In May 2001, the <span className="font-bold"> National Examinations
								Council</span> was created for the management of the exam system in
							public and private primary and secondary schools that follow the
							national programme. The increased fairness in the conduct of exams
							had widespread social repercussions because of the arbitrariness
							that had existed in this domain during the Habyarimana regime.
						</p>
						<p className="my-4">
							In January 2009, a <span className="font-bold">Gender Observatory </span>
							was established and charged with promoting the processing of disaggregated
							gender data.
						</p>
						<p className="my-4">
							These institutions were established to ensure equity in the
							provision of public services, and proved to be efficient; however,
							they are not exempt from a certain level of influence-peddling.
						</p>
						<p className="my-4">
							In addition to these institutions an important law, the
							<span className="font-bold"> Organic Law on State Finances and
								Property</span> was adopted in September 2006. This determines the
							powers, roles, and responsibilities of participants in the system
							for managing public finances and expenditures by more clearly separating
							the prerogatives of the executive branch from those of the legislative
							branch. In 2007, the government began a public expenditure and financial
							assessment. This exercise examined the process of authorising and
							justifying expenditures, collecting revenue, awarding contracts, and
							managing government property. As a result of this review, the government
							initiated a reform of its public financial management system and
							introduced many adjustments to the major institutions responsible for
							managing public affairs. Among the most critical improvements was the
							development of an <span className="font-bold"> Integrated Financial
								Information System (IFMIS)</span> which automated planning, budgeting,
							accounting and financial reporting processes.
						</p>
						<p className="my-4 text-xl font-semibold">
							Efficiency of governance institutions
						</p>
						<p className="my-4">
							The governance institutions that were created proved to be
							efficient. The Control of Corruption Index, which has the oldest
							data for Rwanda on corruption, shows the country’s progress on the
							matter (see Table 1). In 1996 Rwanda was at the very bottom of
							the scale in sub-Saharan Africa, along with Burundi, the
							Democratic Republic of the Congo, Gambia, and Sierra Leone, but
							then rose from a percentile rank of 26.88 in that year to a
							percentile rank of 70.67 in 2018. The Corruption Perceptions Index
							by Transparency International, the data for which on Rwanda began
							in 2006, confirms this evolution, and Rwanda rose from being
							ranked 121st in the world in 2006, to being ranked 48th in 2017,
							representing the 4th best performance in Africa that year.
						</p>
					</div>

					<div>
						<p className="font-semibold">
							Control of Corruption Index for Rwanda (percentile of rank 0–100)
						</p>
						<div className="mt-4 border-b-4 border-fifth">
							<img src={infographics038} alt="" style={{ height: "42rem" }} />
						</div>
						<p className="font-semibold text-right text-fifth">
							World Bank, Worldwide Governance Indicators (2020)
						</p>
					</div>
				</div>
				<div>
					<Link
						to="/country/gender"
						className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-fifth text-primary"
					>
						Gender equality and women empowerment &nbsp;{" "}
						<HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/country/today"
						className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Post-Genocide Rwanda
					</Link>
				</div>
			</div>
		);
	}
}

export default CPInstitutionalisation;
