import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Kagame from "../assets/images/1.Overview.jpg";
import CPbgImage from "../assets/images/homeCP.jpg";
import PageName from "../components/PageName";

export class CPIntroduction extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Country Profile"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Quick facts &nbsp; <HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/overview"
				/>
				<p className="text-3xl font-semibold">Overview</p>
				<div
					className="my-8 bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${CPbgImage})`,
						height: "30rem",
					}}
				></div>
				<div className="">
					<p className="my-4">
						Rwanda’s landscapes have earned it the name of the Land of a
						Thousand Hills.
					</p>
					<p className="my-4">
						Modest in size with 26,338 square kilometres, the country is densely
						populated with 12.3 million inhabitants. Seventy percent of Rwanda’s
						population is under 30 years old. Although Rwanda is located near
						the equator, the country enjoys a climate with moderate temperatures
						ranging between 24 <span>&#8451;</span> and 27 <span>&#8451;</span> , and its altitude varies between 1,000
						and 4,507 meters above sea level.
					</p>
					<p className="my-4">
						Rwanda is located on the borders of East and Central Africa. It is
						part of both the East African Community and the Economic Community
						of Central African States. It is a landlocked country surrounded by
						Uganda, Tanzania, Democratic Republic of the Congo (DRC), and
						Burundi. It shares Lake Kivu with DRC.
					</p>
					<p className="my-4">
						Almost all Rwandans speak the national language, Kinyarwanda, and
						the country has three other official languages, English, French and
						Swahili.
					</p>
					<p className="my-4">
						Over the past two decades, the country has experienced political and
						social stability supported by strong economic growth. Rwanda is
						recognized for the quality of its governance and the important role
						played by women in the life of the nation.
					</p>
				</div>
				<div className="flex flex-col w-full my-8 bg-gray-100 lg:flex-row">
					<div className="flex-1 p-4 md:p-8 lg:p-16 lg:px-16">
						<p className="my-4 font-semibold xl:text-xl">
							<span className="italic font-bold">"  </span>
							After 1994, everything was a priority and our people were
							completely broken. But we made the fundamental choices that guide
							us to this day.
						</p>
						<div className="my-4 font-semibold text-left  xl:text-xl">
							<p>One: We chose to stay together.</p>
							<p className="my-4">Two: We chose to be accountable to ourselves.</p>
							<p>Three: We chose to think big. <span className="italic font-bold">  "</span></p>
						</div>
						<p className="my-8 font-semibold text-fifth xl:text-lg">
							H.E Paul Kagame - President of Rwanda
						</p>
					</div>
					<div className="flex-1">
						<img src={Kagame} alt="" className="" />
					</div>
				</div>
				<Link
					to="/country/overview"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-fifth text-primary"
				>
					Quick facts &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default CPIntroduction;
