import React, { Component } from 'react'
import { Fragment } from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
//import UbudeheChallenges from '../components/UbudeheChallenges';
import UbudeheHowItWorks from '../components/UbudeheHowItWorks';
import UbudeheImpact from '../components/UbudeheImpact';
import UbudeheOrganization from '../components/UbudeheOrganization';
import UbudeheOverview from '../components/UbudeheOverview';
import UbudehePovertyLevels from '../components/UbudehePovertyLevels';
import { ubudeheSubmenus } from '../utils/menus';

export class Ubudehe extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Ubudehe"
							hgsSubTitle="social welfare"
							hgsIntro={
								<p>
									Ubudehe refers to the long-standing Rwandan practice and
									culture of collective action and mutual support to solve
									problems within a community. The focus of traditional Ubudehe
									was mostly on cultivation.
								</p>
							}
							submenus={ubudeheSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Umuganda &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/umuganda/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={UbudeheOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={UbudeheOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={UbudeheHowItWorks}
							/>
							<Route
								exact
								path={`${match.path}/poverty-level-categories`}
								component={UbudehePovertyLevels}
							/>
							<Route
								exact
								path={`${match.path}/organization`}
								component={UbudeheOrganization}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={UbudeheChallenges}
							/> */}
							<Route
								exact
								path={`${match.path}/impact`}
								component={UbudeheImpact}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Ubudehe
