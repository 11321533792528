import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import abunziOverviewImage from "../assets/images/hgs-content-image043.jpeg";
import image044 from "../assets/images/hgs-content-image044.jpeg";

export class AbunziOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Overview</p>
				<div className="flex flex-row items-start my-4">
					<div className="-mt-4 text-5xl text-secondary">&ldquo;</div>
					<div>
						<p className="text-2xl italic font-semibold">
							Unlike ordinary courts whose decisions specify the winner and the
							loser who, in most cases, keep living in an antagonistic
							relationship, Abunzi strive to help litigants to resume former
							normal relationships or at least mitigate the magnitude of their
							dispute
							<strong className="text-secondary">&rdquo;</strong>
						</p>
					</div>
				</div>
				<p className="">
					A participant in a group discussion in Kicukiro District
				</p>
				<div className="w-full my-8">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/sHy-zvRLJhM"
					/>
				</div>
				<div className="">
					<p className="">
						The system of community mediators, also known as Abunzi, combines
						traditional and modern methods of conflict resolution. It is also one
						of Rwanda’s Home Grown Initiatives (HGI), although similar mechanisms
						exist elsewhere.
					</p>
					<p className="my-4">
						The word <span className="italic">Abunzi</span> can be translated as
						“those who reconcile” or “those who bring together”, from the verb kunga.
						Traditionally in Rwanda, <span className="italic">Abunzi</span>  were
						people known within their communities for personal integrity, and
						were thus asked to intervene in the event of conflict. Each conflicting
						party would choose a person who was considered trustworthy, who was known
						as a problem-solver, and who was unlikely to alienate either party.
						The purpose of this system was to settle disputes and to reconcile the
						conflicting parties and restore harmony within the affected community.
					</p>
					<p className="">
						The reintroduction of the <span className="italic">Abunzi</span> system
						in 2004 was motivated partly by the desire to reduce the backlog of court
						cases, and partly to decentralise justice and make it more affordable and
						accessible to citizens seeking to resolve conflicts without the cost of
						going to court. Today, <span className="italic">Abunzi</span> is fully
						integrated into Rwanda’s justice system.
					</p>
					<p className="my-4">
						This conflict resolution mechanism rooted in Rwandan culture, came to be
						perceived as more accessible and less threatening, and therefore more human.
						Those who referred their cases to <span className="italic">Abunzi </span>
						were more comfortable with this system because the <span className="italic">
							Abunzi</span> came from within their own community and thus
						had a better understanding of the issues and process at hand. As the
						<span className="italic">Abunzi </span> system gained more recognition
						as a successful method to resolve conflict and deliver justice, the
						importance of providing more structure and formality to their work
						increased. Consequently, the <span className="italic">Abunzi </span>
						started receiving training on resolving domestic conflicts, as well as
						logistical support from both governmental and non-governmental
						organisations, to improve the quality of their mediation services.
					</p>
					<p className="my-4">
						There are now over 30,000 <span className="italic">Abunzi</span> operating
						across Rwanda.
					</p>
				</div>
				<div className="">
					<img src={abunziOverviewImage} alt="" />
				</div>
				<p className="my-4">
					According to the Ministry of Justice 2019 annual report, during the fiscal year
					ending June 2019, Abunzi received 48,989 civil cases, of which 47,898 (97.8%) were
					completed (at sector and cell level) and just 1,091 (2.2%) were ongoing at the end
					of the year. The same report also describes the support given to Abunzi mediators
					during the 2018-2019 fiscal year: a total of 15,183 Abunzi and their 56,799
					dependents were provided with 100% community health insurance; a total of 13,030
					Abunzi were provided with monthly communication airtime to facilitate communication
					with fellow Abunzi and staff from the Ministry of Justice; and a total of 8,100
					Abunzi were provided with bicycles to facilitate their daily work, bringing the total
					number of bicycles provided to 13,100.
				</p>
				<p className="text-xl font-semibold">
					Conflict resolution through community participation
				</p>
				<div className="">
					<p className="my-8">
						The adoption of alternative dispute resolution mechanisms in Rwanda
						emerged from the recognition of a growing crisis in the judiciary in
						which it had become almost impossible to resolve disputes
						efficiently and in a cost-effective manner. The Government of Rwanda
						concluded that modern judicial mechanisms of dispute resolution were
						failing to deliver, and so the decision was taken to examine
						traditional mediation and conciliation approaches as alternatives.
						Doing so would not only help alleviate the pressure on conventional
						courts, but would also align with the policy objective of a more
						decentralised justice system.
					</p>
					<div
						className="my-8"
					>
						<img src={image044} alt="" />
					</div>
					<p className="my-4 text-xl font-semibold">
						Establishment and purpose of the Abunzi
					</p>
					<p className="my-4">
						In 2004, the Government of Rwanda established the traditional
						process of Abunzi as an alternative dispute resolution mechanism.
						Established at the cell and sector levels, Abunzi primarily address
						family disputes, such as those relating to land or inheritance. By
						institutionalising Abunzi, low-level legal issues could be solved at
						a local level without the need for them to be heard in conventional
						courts.
					</p>
					<p className="my-4">
						As the Abunzi system gained recognition as a successful method to
						resolve conflict and deliver justice, the importance of providing
						more structure and formality to the their work.
					</p>
				</div>
				<Link
					to="/hgs/abunzi/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					How does it work? &nbsp;{" "}
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default AbunziOverview;
