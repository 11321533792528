import React, { Component } from "react";
import Social1 from "../assets/images/Social-Development1.jpg";

export class Strategies extends Component {
	render() {
		return (
			<div className="flex flex-col ">
				<div
					className="w-full my-4 bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${Social1})`,
						height: "30rem",
					}}
				></div>
				<div className="">
					<p>
						The vision for the transformation of Rwanda requires development
						activities to be citizen-centred. Rwanda has put a strong focus on
						service delivery to citizens, especially in health, sanitation and
						education; the country also has constitutionally mandated social
						protection. Rwanda’s social protection sector comprises four
						pillars:
					</p>
					<div className="my-4">
						<ul className="ml-8 overview-list">
							<li>
								Pillar 1: Social security schemes designed to achieve
								consumption smoothing and ensure a minimum standard of living
								throughout the life cycle
							</li>
							<li>
								Pillar 2: Short-term social assistance in the form of temporary
								or incidental cash or in-kind assistance that addresses
								short-term or temporary risks or deprivation
							</li>
							<li>
								Pillar 3: Social care services providing protection,
								psychosocial support, referrals to complementary services, and
								promotion of social inclusion for the most vulnerable children,
								youth, women, the elderly, and people with disabilities
							</li>
							<li>
								Pillar 4: Livelihood and employment support to enable poor and
								vulnerable beneficiaries to graduate sustainably from extreme
								poverty
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default Strategies;
