import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
//import UmugandaChallenges from '../components/UmugandaChallenges';
import UmugandaHowItWorks from '../components/UmugandaHowItWorks';
import UmugandaImpact from '../components/UmugandaImpact';
import UmugandaOverview from '../components/UmugandaOverview';
import { umugandaSubmenus } from '../utils/menus';

export class Umuganda extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Umuganda"
							hgsSubTitle="Economy"
							hgsIntro={
								<p>
									The word Umuganda can be translated as ‘coming together in
									common purpose to achieve an outcome’. In traditional Rwandan
									culture, members of the community would call upon their
									family, friends and neighbors to help them complete a
									difficult task.
								</p>
							}
							submenus={umugandaSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="What are HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Umwiherero &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/umwiherero/overview"
							/>
						</div>
						<Switch>
							<Route
								exact
								path={`${match.path}`}
								component={UmugandaOverview}
							/>
							<Route
								exact
								path={`${match.path}/overview`}
								component={UmugandaOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={UmugandaHowItWorks}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={UmugandaChallenges}
							/> */}
							<Route
								exact
								path={`${match.path}/impact`}
								component={UmugandaImpact}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Umuganda
