import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

export class AbunziPractices extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Beneficial practices</p>
				<div className="">
					<p className="my-8">
						The RGB survey also asked participants to highlight positive
						attributes of the Abunzi system; these are as follows:
					</p>
					<ul className="flex flex-col gap-4 ml-8 hgs-list">
						<li>
							<span className="font-semibold">Pre-hearing counselling:</span>
							<p className="">
								Before cases are heard, Abunzi call on both plaintiff and
								defendant to emphasise the importance of social cohesion and
								conflict-resolution through community mediation. In some
								instances, both parties opt to withdraw the case at this point,
								and come instead to a mutual agreement. In other cases,
								litigants are more inclined to accept, rather than appeal, the
								mediation decision as a result of the counselling.
							</p>
						</li>
						<li>
							<span className="font-semibold">
								Reduced social distance between parties and mediators:
							</span>
							<p className="">
								Since Abunzi are members of the same community from which
								disputants come, the latter feel less intimidated and more
								comfortable expressing themselves during those sessions, whether
								these sessions are public or on camera.
							</p>
						</li>
						<li>
							<span className="font-semibold">
								Integrity over legal literacy:
							</span>
							<p className="">
								In the RGB study, the majority of participants stated that the
								fact that integrity determines the selection of Abunzi,
								confers trust and confidence in the committees and fosters an
								environment in which justice prevails.
							</p>
						</li>
						<li>
							<span className="font-semibold">
								Parties' freedom to choose Abunzi:
							</span>
							<p className="">
								Participants highlighted that the freedom to choose mediators
								helps to ensure equal treatment during mediation and reduces the
								likelihood of corruption.
							</p>
						</li>
						<li>
							<span className="font-semibold">Win-win approach:</span>
							<p className="">
								During mediation, Abunzi Committees avoid referring to either
								party as the "winner" or the "loser" as these words can create
								resentment and foster conflict, and the goal is to find a
								lasting solution through reconciliation.
							</p>
						</li>
					</ul>
				</div>
				<Link
					to="/hgs/abunzi/performance"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Performance and evaluation
				</Link>
			</div>
		);
	}
}

export default AbunziPractices;
