import React, { Component, Fragment } from "react";
import ComponentCarousel from "../components/ComponentCarousel";
import PageBottomNav from "../components/PageBottomNav";
import WhatMakes from "../components/WhatMakes";
import WhatMakes2 from "../components/WhatMakes2";
import WhatMakes3 from "../components/WhatMakes3";
import WhatMakes4 from "../components/WhatMakes4";
import { hgsMenus } from "../utils/menus";

export class HGSWhatMakes extends Component {
	render() {
		const components = [
			<WhatMakes />,
			<WhatMakes2 />,
			<WhatMakes3 />,
			<WhatMakes4 />,
		];
		return (
			<Fragment>
				<div className="hidden md:flex flex-col">
					<div>
						<ComponentCarousel components={components} />
						<PageBottomNav menus={hgsMenus} color="secondary" />
					</div>
				</div>
				<div className="flex flex-col md:hidden bg-white text-justify">
					<div>
						<WhatMakes />
						<WhatMakes2 />
						<WhatMakes3 />
						<WhatMakes4 />
					</div>
				</div>
			</Fragment>
		);
	}
}

export default HGSWhatMakes;
